import { gql } from '@apollo/client';

export const GET_BILLS_WITH_UPDATES = gql`
  query ($inOsBillIds: [Int!], $actionsUpdatedSince: String, $versionsUpdatedSince: String, $votesUpdatedSince: String, $statusUpdatedSince: String) {
    billsWithUpdatedActions: bills(input: { inOsBillIds: $inOsBillIds, actionsUpdatedSince: $actionsUpdatedSince }) {
      osBillId
      state
      number
      title
    },
    billsWithUpdatedVersions: bills(input: { inOsBillIds: $inOsBillIds, versionsUpdatedSince: $versionsUpdatedSince }) {
      osBillId
      state
      number
      title
    },
    billsWithUpdatedVotes: bills(input: { inOsBillIds: $inOsBillIds, votesUpdatedSince: $votesUpdatedSince }) {
      osBillId
      state
      number
      title
    },
    billsWithUpdatedStatus: bills(input: { inOsBillIds: $inOsBillIds, statusUpdatedSince: $statusUpdatedSince }) {
      osBillId
      state
      number
      title
    }
  }
`;
