import { useQuery } from '@apollo/client';
import {FC, useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import BreadCrumbs from 'src/components/BreadCrumbs/BreadCrumbs';
import PageTitles from 'src/types/PageTitles.enum';
import { mapResponseToBill } from 'src/utils/mapResponseToBill.util';
import { updateTabTitle } from 'src/utils/updateTabTitle';
import { EPRC_BILLS_DETAILS_QUERY } from './BillDetails.graphql';
import BillDetailsTab from './BillDetailsTab/BillDetailsTab';
import BillHeader from './BillHeader/BillHeader';
import { GetBillDetailsVariable, RawBillDetailsData } from './types/types';
import {BillData} from "../../types/BillData.interface";

const BillDetails: FC = () => {
  updateTabTitle(PageTitles.BILL_DETAILS);
  const params = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [bills, setBills] = useState<BillData[]>([])

  const currentBillId = parseInt(params.id ?? '');

  const { loading, error } = useQuery<RawBillDetailsData, GetBillDetailsVariable>(EPRC_BILLS_DETAILS_QUERY, {
    variables: { inOsBillIds: [currentBillId] },
    onCompleted(data) {
      setBills(data.bills.map(b => mapResponseToBill(b)))
    }
  });

  if (loading) return (<>Loading bill.</>)
  if (error) return (<p>Failed to load bill.</p>)
  if (!bills.length) return (<>No data loaded.</>)

  return (
    <>
      {bills
        .slice(0, 1)
        .map((bill, index) => (
          <div key={index}>
            <BreadCrumbs billId={currentBillId.toString()} />
            <BillHeader currentBillDetails={bill} />
            <BillDetailsTab currentBillDetails={bill} />
          </div>
        ))}
    </>
  )
};
export default BillDetails;
