import { FC } from 'react';
import styles from './AccordionDetails.module.scss';
import VoteDetailCard from './VoteDetailCard';
import BillDetailsWrapper from './BillDetailsWrapper';
import { RollCallInterface } from '../../../../../types/RollCall.interface';

interface VoteDetailsProps {
  votes: RollCallInterface[];
}

const VoteDetails: FC<VoteDetailsProps> = ({ votes }) => {
  const votesList = [...votes]
    .sort((a, b) => {
      if (!a.date && !b.date) return 0
      if (!a.date) return 1;
      if (!b.date) return -1;
      return b.date.localeCompare(a.date)
    })
    .map((voteData: RollCallInterface, index: number) => (
      <div key={index}>
        <VoteDetailCard voteData={voteData} />
      </div>
    ));

  return (
    <BillDetailsWrapper billDetailName="Votes" listCount={votesList.length}>
      <div className={styles.cardContainer}>{votesList}</div>
    </BillDetailsWrapper>
  );
};

export default VoteDetails;
