enum PageTitles {
  HOME = 'Home',
  AEL = 'Ael Tracker',
  STYLE = 'Style Guide',
  ABOUT = 'About',
  FAQ = 'FAQs',
  CONTACT = 'Contact',
  BILL_DETAILS = 'Bill Details',
  MAPS_GRAPHS = 'Maps & Graphs',
  TRENDS_ANALYSIS = 'Trends & Anaylsis',
  MYTRACKER = 'myTracker',
  //ADMIN PAGES
  BILL_ADMIN = 'Bill Administration',
  FILTER_CONFIG = 'Filter Configuration',
  USER_ACCOUNT_MANAGEMENT = 'User Account Management'
}

export default PageTitles;
