import React, { DetailedHTMLProps, FC, HTMLAttributes, ReactElement } from 'react';
import HeadingStyle from './HeadingStyle.enum';

export interface HeadingProps extends DetailedHTMLProps<HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement> {
  headingStyle: HeadingStyle;
  className?: string;
}

const Heading: FC<HeadingProps> = ({ headingStyle, className, children, ...rest }) => {
  const getElement = (): ReactElement => {
    switch (headingStyle) {
      case HeadingStyle.PAGE_TITLE:
        return <h1 className={className}>{children}</h1>;
      case HeadingStyle.PAGE_SUBTITLE:
        return <h2 className={className}>{children}</h2>;
      case HeadingStyle.CONTAINER_TITLE:
        return <h3 className={className}>{children}</h3>;
      case HeadingStyle.CONTAINER_SUBTITLE:
        return <h4 className={className}>{children}</h4>;
      default:
        return <></>;
    }
  };

  return getElement();
};

export default Heading;
