export enum TrackerFrequency {
  NEVER = 'Never',
  DAILY = 'Daily',
  WEEKLY = 'Weekly',
  MONTHLY = 'Monthly',
}

export enum TimeFilterEnum {
  NEVER = '',
  DAILY = 'day',
  WEEKLY = 'week',
  MONTHLY = 'month',
}

export const TrackerFrequencies = Object.keys(TrackerFrequency).map(
  (s: string) => TrackerFrequency[s as keyof typeof TrackerFrequency],
);
