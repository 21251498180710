export enum PolicyEnum {
  ECONOMIC = 'ECONOMIC',
  ELECTRICITY = 'ELECTRICITY',
  EMISSIONS = 'EMISSIONS',
  ENERGY = 'ENERGY',
  FINANCING = 'FINANCING',
  INFRASTRUCTURE = 'INFRASTRUCTURE',
  GAS = 'GAS',
  REGULATORY = 'REGULATORY',
  TRANSPORTATION = 'TRANSPORTATION',
  OTHER = 'OTHER',
}
