import { FC, useContext, useEffect, useState } from 'react';
import { GlobalContext } from 'src/hooks/useGlobalContext';
import isUserAdmin from './util/isAdmin';
import { AdminTracker } from './AdminTracker';
import { UserTracker } from './UserTracker';
import { UserData } from '../../types/UserData.interface';
import { useNavigate } from 'react-router-dom';
import { TimeFilterEnum } from 'src/types/TrackerFrequency.enum';

interface MyTrackerProps {
  loggedOutBillTracked: any;
}

const MyTracker: FC<MyTrackerProps> = ({ loggedOutBillTracked }) => {
  const navigate = useNavigate();
  const { userData, recentBillChangesSection, notificationPreferencesSection } = useContext(GlobalContext);
  const [userDataLocal, setUserDataLocal] = useState<UserData>(userData);
  const isAdmin = isUserAdmin(userData.role);

  useEffect(() => {
    if (recentBillChangesSection && userData?.id) {
      navigate(`/mytracker/${TimeFilterEnum[userData.trackerFrequency as keyof typeof TimeFilterEnum]}`);
      navigate(0);
    }
  }, [recentBillChangesSection, userData?.id, userData.trackerFrequency, navigate]);

  useEffect(() => {
    if (notificationPreferencesSection && userData?.id) {
      navigate('/mytracker/#notification-preferences');
    }
  }, [notificationPreferencesSection, userData?.id, navigate]);

  useEffect(() => {
    if (userData) setUserDataLocal(userData);
  }, [userData]);

  const getTrackerPage = () => {
    if (isAdmin) return <AdminTracker userData={userDataLocal} loggedOutBillTracked={loggedOutBillTracked} />;
    return <UserTracker loggedOutBillTracked={loggedOutBillTracked} userData={userDataLocal} />;
  };
  return getTrackerPage();
};

export default MyTracker;
