import {gql} from '@apollo/client';

export const NATIONAL_TOTALS_QUERY = gql`
  query {
    byState: billsDistribution(input: {groupBy: "state"}) {
      group
      count
    },
    byStatus: billsDistribution(input: {groupBy: "osStatus"}) {
      group
      count
    },
    byPolicy: billsDistribution(input: {groupBy: "policyCategory"}) {
      group
      count
    }
  }
`;
