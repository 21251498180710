import { FC, useState } from 'react';
import styles from './StackedBarChart.module.scss';
import {BarItem} from './BarItem/BarItem';
import BarSegment from './BarSegment/BarSegment';
import Heading from '../Heading/Heading';
import HeadingStyle from '../Heading/HeadingStyle.enum';
import Box from "@mui/material/Box";
import {Grid} from "@mui/material";

interface StackedBarChartItem {
  id: string;
  label: string;
  color: string;
  value: number;
}

interface StackedBarChartProps {
  title: string;
  description: string;
  items: StackedBarChartItem[];
  isCenter?: boolean;
  className?: string;
}

const getPercentage = (value: number, total: number) => total ? Math.round((value / total) * 1000) / 10 : 0;

export const StackedBarChart: FC<StackedBarChartProps> = ({
  title,
  description,
  items,
  isCenter,
  className
}) => {
  const total = items.reduce((c, i) => c + i.value, 0);
  const headingAlign = isCenter ? `${styles.centerHeading}` : '';
  const [highlighted, setHighlighted] = useState('');

  const stackedBar = items.map((item, index) => {
    const isHighlighted = highlighted === index.toString();
    return (
      <BarSegment
        key={index}
        id={index.toString()}
        percentage={getPercentage(item.value, total)}
        color={item.color}
        isHighlighted={isHighlighted}
        onHighlight={setHighlighted}
      />
    );
  });

  const itemList = items.map((item, index) => {
    const isHighlighted = highlighted === index.toString();
    return (
      <BarItem
        key={index}
        id={index.toString()}
        label={item.label}
        color={item.color}
        value={item.value}
        percentage={getPercentage(item.value, total)}
        isHighlighted={isHighlighted}
        onHighlight={setHighlighted}
      />
    );
  });

  return (
    <section className={`${styles.stackedBarSection} ${className}`}>
      <div className={headingAlign}>
        <Heading headingStyle={HeadingStyle.CONTAINER_TITLE} className={styles.heading}>
          {title}
        </Heading>
        <p className={styles.description}>{description}</p>
      </div>
      <Box sx={{ display: 'flex', mb: '1rem' }}>{stackedBar}</Box>
      <Grid container>{itemList.map(i => <Grid item xs={12} md={6} xl={6} sx={{ mb: '1rem' }}>{i}</Grid>)}</Grid>
    </section>
  );
};
