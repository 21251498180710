import {gql} from '@apollo/client';

export const POLICY_TOTALS_QUERY = gql`
  query {
    ECONOMIC: billsDistribution(input: {groupBy: "state", policyCategory: "ECONOMIC"}) {
      group
      count
    },
    ELECTRICITY: billsDistribution(input: {groupBy: "state", policyCategory: "ELECTRICITY"}) {
      group
      count
    },
    EMISSIONS: billsDistribution(input: {groupBy: "state", policyCategory: "EMISSIONS"}) {
      group
      count
    },
    ENERGY: billsDistribution(input: {groupBy: "state", policyCategory: "ENERGY"}) {
      group
      count
    },
    FINANCING: billsDistribution(input: {groupBy: "state", policyCategory: "FINANCING"}) {
      group
      count
    },
    INFRASTRUCTURE: billsDistribution(input: {groupBy: "state", policyCategory: "INFRASTRUCTURE"}) {
      group
      count
    },
    GAS: billsDistribution(input: {groupBy: "state", policyCategory: "GAS"}) {
      group
      count
    },
    OTHER: billsDistribution(input: {groupBy: "state", policyCategory: "OTHER"}) {
      group
      count
    },
    REGULATORY: billsDistribution(input: {groupBy: "state", policyCategory: "REGULATORY"}) {
      group
      count
    },
    TRANSPORTATION: billsDistribution(input: {groupBy: "state", policyCategory: "TRANSPORTATION"}) {
      group
      count
    }
  }
`;
