import React, {FC} from 'react';
import {BillSponsors} from 'src/types/BillSponsors.interface';
import styles from '../BillCard.module.scss';
import {TopFiveSponsorsList} from "../../TopFiveSponsorsList/TopFiveSponsorsList";

interface SponsorProps {
  sponsorsObject: BillSponsors;
}

const Sponsors: FC<SponsorProps> = ({ sponsorsObject }) => {

  return (
    <div className={styles.billSponsors}>
      <span className={styles.sponsorsTitle}>Primary Sponsors</span>
      <div className={styles.sponsors}>
        <TopFiveSponsorsList sponsorsObject={sponsorsObject}/>
      </div>
    </div>
  );
};

export default Sponsors;