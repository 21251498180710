import { Button, Typography } from '@mui/material';
import {UpdateAccountPublic} from "../../../../services/AuthService";
import {toast} from "react-toastify";
import Box from "@mui/material/Box";
import buttonTheme from "../../../../theme/buttonTheme";
import {ThemeProvider} from "@mui/material/styles";

interface DeactivatedUserData {
  id: string
  active: boolean
  firstName: string
  lastName: string
  email: string
  role: string
}

interface DeactivateUserModalProps {
  user: DeactivatedUserData
  onCancel: () => void
  onComplete: () => void
}

export const DeactivateUserPrompt = ({
  user,
  onCancel,
  onComplete,
}: DeactivateUserModalProps) => {

  const handleDeactivateUser = async () => {
    await UpdateAccountPublic({ ...user, active: false });
    toast.success('Update successful', {
      position: toast.POSITION.TOP_CENTER,
    });
    onComplete()
  }

  return (
    <Box>
      <Box>
        <Typography component="h2" variant="h5">
          Deactivate {user.firstName} {user.lastName}&apos;s Account
        </Typography>
      </Box>
      <Box sx={{ my: 3 }}>
        <Typography>
          Are you sure you want to deactivate this account?
        </Typography>
        <Typography>
          The user will lose administrative access and privileges.
        </Typography>
      </Box>
      <ThemeProvider theme={buttonTheme}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button variant="outlined" onClick={() => {handleDeactivateUser()}} >
            Yes, deactivate
          </Button>
          <Button variant="contained" onClick={() => {onCancel()}}>
            No, do not deactivate
          </Button>
        </Box>
      </ThemeProvider>
    </Box>
  );
};
