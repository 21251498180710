import {StateEnum} from "../types/State.enum";

export const stateText = {
  [StateEnum.AZ]: 'Arizona',
  [StateEnum.AL]: 'Alabama',
  [StateEnum.AK]: 'Alaska',
  [StateEnum.AR]: 'Arkansas',
  [StateEnum.CA]: 'California',
  [StateEnum.CO]: 'Colorado',
  [StateEnum.CT]: 'Connecticut',
  [StateEnum.DE]: 'Delaware',
  [StateEnum.FL]: 'Florida',
  [StateEnum.GA]: 'Georgia',
  [StateEnum.HI]: 'Hawaii',
  [StateEnum.ID]: 'Idaho',
  [StateEnum.IL]: 'Illinois',
  [StateEnum.IN]: 'Indiana',
  [StateEnum.IA]: 'Iowa',
  [StateEnum.KS]: 'Kansas',
  [StateEnum.KY]: 'Kentucky',
  [StateEnum.LA]: 'Louisiana',
  [StateEnum.ME]: 'Maine',
  [StateEnum.MD]: 'Maryland',
  [StateEnum.MA]: 'Massachusetts',
  [StateEnum.MI]: 'Michigan',
  [StateEnum.MN]: 'Minnesota',
  [StateEnum.MS]: 'Mississippi',
  [StateEnum.MO]: 'Missouri',
  [StateEnum.MT]: 'Montana',
  [StateEnum.NE]: 'Nebraska',
  [StateEnum.NV]: 'Nevada',
  [StateEnum.NH]: 'New Hampshire',
  [StateEnum.NJ]: 'New Jersey',
  [StateEnum.NM]: 'New Mexico',
  [StateEnum.NY]: 'New York',
  [StateEnum.NC]: 'North Carolina',
  [StateEnum.ND]: 'North Dakota',
  [StateEnum.OH]: 'Ohio',
  [StateEnum.OK]: 'Oklahoma',
  [StateEnum.OR]: 'Oregon',
  [StateEnum.PA]: 'Pennsylvania',
  [StateEnum.RI]: 'Rhode Island',
  [StateEnum.SC]: 'South Carolina',
  [StateEnum.SD]: 'South Dakota',
  [StateEnum.TN]: 'Tennessee',
  [StateEnum.TX]: 'Texas',
  [StateEnum.UT]: 'Utah',
  [StateEnum.VT]: 'Vermont',
  [StateEnum.VA]: 'Virginia',
  [StateEnum.WA]: 'Washington',
  [StateEnum.WV]: 'West Virginia',
  [StateEnum.WI]: 'Wisconsin',
  [StateEnum.WY]: 'Wyoming',
  [StateEnum.DC]: 'District of Columbia',
  [StateEnum.US]: 'US Congress',
}

export const getStateEnumfromString = (stateString: string) => {
  return Object.entries(stateText)
    .filter(([key, val]) => val === stateString)
    .map(([key, val]) => StateEnum[key as keyof typeof StateEnum])
    .reduce((c, v) => v);
}
