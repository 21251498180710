import { FC } from 'react';
import Button from '@mui/material/Button';
import { colors } from 'src/theme/colors';

export interface FilterAdminButtonProps {
  onClick: () => void;
  disabled?: boolean;
  text: string;
  className?: string;
}

const FilterAdminButton: FC<FilterAdminButtonProps> = ({ text, disabled, onClick, className }) => {
  return (
    <Button
      className={className}
      variant="contained"
      onClick={onClick}
      disableElevation
      disableFocusRipple
      disabled={disabled ? disabled : false}
      sx={{
        fontFamily: 'Lato',
        background: colors.medGray1,
        color: colors.black,
        '&:hover': {
          color: colors.white,
          background: 'none',
          backgroundColor: colors.darkGray1,
          transition: 'none',
        },
        '&:focus-visible': {
          color: colors.white,
          background: 'none',
          backgroundColor: colors.darkGray1,
        },
      }}
    >
      {text}
    </Button>
  );
};

export default FilterAdminButton;
