import React, { FC, useEffect, useState } from 'react';
import {Box, CircularProgress, Typography} from '@mui/material';
import { PendingBillInterface } from '../../../types/PendingBill.interface';
import { StateEnum } from '../../../types/State.enum';
import { SortDirectionEnum } from '../../../types/SortDirection.enum';
import {useLazyQuery, useMutation} from '@apollo/client';
import {
  ApprovedBillActions,
  ApprovedBillsQueryInterface,
  ApprovedBillsVariableInterface,
  UpdateBillVariableInterface
} from './ApprovedBills.interface';
import {UPDATE_BILL, APROVED_BILLS_QUERY} from './ApprovedBills.graphql';
import GeneralModal from '../../../components/GeneralModal/GeneralModal';
import {BillForm, FormBillInterface} from '../BillForm/BillForm';
import {stateText} from "../../../const/stateText";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import buttonTheme from "../../../theme/buttonTheme";
import {ThemeProvider} from "@mui/material/styles";
import {toast} from "react-toastify";
import {BillInterface} from "../../../types/Bill.interface";
import {RemoveForm} from "../RemoveForm/RemoveForm";
import {AdminTable} from "../../../components/AdminTable/AdminTable";

interface TableColumns {
  id: keyof BillInterface;
  label: string;
}

export const columns: TableColumns[] = [
  {id: 'yearFiled', label: 'Year filed'},
  {id: 'autoTimeOsUpdate',label: 'Last modified'},
  {id: 'osBillId', label: 'LegiScan ID'},
  {id: 'osState', label: 'State'},
  {id: 'number', label: 'Bill Number'},
  {id: 'osSession',label: 'Session'},
  {id: 'title',label: 'Title'},
  {id: 'summary',label: 'Summary'},
];

const toastOptions = { position: toast.POSITION.TOP_CENTER };

export const ApprovedBills: FC = () => {
  // Query states
  const [state, setState] = useState<StateEnum>();
  const [keywords, setKeywords] = useState<string>('');

  // Pagination states
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [sortColumn, setSortColumn] = useState<string>('autoTimeOsUpdate');
  const [sortDirection, setSortDirection] = useState<SortDirectionEnum>(SortDirectionEnum.DESC);
  const [billAction, setBillAction] = useState<ApprovedBillActions>();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedBill, setSelectedBill] = useState<FormBillInterface>();

  const onBillAction = (bill: BillInterface, action: ApprovedBillActions) => {
    setSelectedBill({
      id: bill.billId,
      title: bill.title,
      summary: bill.summary,
      yearFiled: bill.yearFiled,
      externalId: bill.osBillId,
      state: bill.osState,
      number: bill.number,
      chamber: bill.osChamber,
      session: bill.osSession,
      sessionId: bill.osSessionId,
      status: bill.osStatus,
      policyCategory: bill.policyCategory,
      policySubcategory: bill.subcategory,
      spotPolicies: bill.spotPolicies.map(v => v.policyId),
      companionBills: [],
    });
    setBillAction(action);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setSelectedBill(undefined)
    setModalOpen(false);
    refetchBills();
  };

  const handleModalComplete = () => {
    setSelectedBill(undefined)
    setModalOpen(false);
    refetchBills();
  }

  const handleBillApprove = (data: FormBillInterface) => {
    updateBill({
      variables: {
        input: {
          id: data.id,
          title: data.title,
          description: data.summary,
          yearFiled: data.yearFiled,
          osBillId: data.externalId,
          state: data.state,
          number: data.number,
          chamber: data.chamber,
          session: data.session,
          status: data.status,
          policyCategory: data.policyCategory,
          policySubcategory: data.policySubcategory,
          spotPolicies: data.spotPolicies,
          companionBills: data.companionBills,
        }
      }
    })
  }

  const [getBills, { data: bills, loading, error, refetch: refetchBills }] = useLazyQuery<
    ApprovedBillsQueryInterface,
    ApprovedBillsVariableInterface
  >(APROVED_BILLS_QUERY);

  const [updateBill] = useMutation<PendingBillInterface, UpdateBillVariableInterface>(
    UPDATE_BILL,
    {
      onCompleted() {
        toast.success(`Bill updated.`, toastOptions);
        setSelectedBill(undefined)
        setModalOpen(false);
        refetchBills()
      },
      onError() {
        toast.error('Failed to approve bill. Try again.', toastOptions);
      }
    },
  );

  // Invoke on initial render, and when after state, keywords, and statuses change.
  useEffect(() => {
    getBills({
      variables: {
        bills: {
          osState: state || undefined,
          likeKeywords: keywords,
          pageNumber,
          pageSize,
          sortColumn,
          sortDirection
        },
      },
    });
  }, [getBills, state, keywords, pageNumber, pageSize, sortColumn, sortDirection]);

  return (
    <div>
      <section>
        <form>
          <Box sx={{ mb: '1rem', display: 'flex', gap: '1rem' }}>
            <FormControl>
              <InputLabel
                shrink
                id="pending-bills-label">Select a state</InputLabel>
              <Select
                displayEmpty
                labelId="pending-bills-label"
                id="pending-bills-select"
                value={state ? state : ''}
                label="Select a state"
                onChange={(e) => {
                  setState(e.target.value as StateEnum ?? undefined);
                  setPageNumber(0);
                }}
              >
                <MenuItem value=""><span>All States</span></MenuItem>
                {Object.entries(stateText).map(([value, label]) => (
                  <MenuItem key={value} value={value}>{label}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              id="pending-bills-keyword"
              label="Search by Keyword"
              onChange={(e) => {
                setKeywords(e.target.value);
                setPageNumber(0);
              }}/>
          </Box>
        </form>
        <Box>
          {loading && (
            <Box sx={{ textAlign: 'center', mt: 2 }}>
              <CircularProgress />
            </Box>
          )}
          {error && <Typography align={"center"}>Failed to load bills.</Typography>}
          {bills && bills.billsCount === 0 && <Typography align={"center"}>No bills matched your search.</Typography>}
          {bills && bills.billsCount !== 0 && (
            <>
              <Typography>
                You are reviewing {Math.min(pageSize, bills.billsCount)} out of {bills.billsCount} bills
              </Typography>
              <AdminTable
                title={'Approved bills table'}
                columns={columns}
                data={bills.bills}
                count={bills.billsCount}
                pageNumber={pageNumber}
                pageSize={pageSize}
                sortColumn={sortColumn}
                sortDirection={sortDirection}
                onPageNumberChange={setPageNumber}
                onPageSizeChange={setPageSize}
                onSortChange={(c, d) => {
                  setSortColumn(c);
                  setSortDirection(d);
                  setPageNumber(0);
                }}
                actions={(bill) => (
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                    <ThemeProvider theme={buttonTheme}>
                      <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        disableFocusRipple
                        onClick={() => {
                          onBillAction(bill, ApprovedBillActions.UPDATE);
                        }}
                      >Update</Button>
                      <Button
                        variant="outlined"
                        color="error"
                        disableElevation
                        disableFocusRipple
                        onClick={() => {
                          onBillAction(bill, ApprovedBillActions.REMOVE);
                        }}
                      >Remove</Button>
                    </ThemeProvider>
                  </Box>
                )}
              />
            </>
          )}
        </Box>
      </section>
      {selectedBill && billAction && (
        <GeneralModal onClose={handleModalClose} modalOpen={modalOpen}>
          {billAction === ApprovedBillActions.UPDATE && (
            <BillForm
              formTitle={"Update Approved Bill"}
              onSubmit={handleBillApprove}
              onCancel={handleModalClose}
              bill={selectedBill} />
          )}
          {billAction === ApprovedBillActions.REMOVE && (
            <RemoveForm
              onComplete={handleModalComplete}
              onCancel={handleModalClose}
              billId={selectedBill.id} />
          )}
        </GeneralModal>
      )}
    </div>
  );
};
