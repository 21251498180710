import {useParams} from "react-router-dom";
import {FC} from "react";
import Heading from "../../components/Heading/Heading";
import HeadingStyle from "../../components/Heading/HeadingStyle.enum";
import {useQuery} from "@apollo/client";
import contentfulClient from "../../common/apolloClient";
import {POLICY_QUERY} from "./PolicyDetail.graphql";
import {PolicyDetailsQueryData, PolicyDetailsQueryVariables} from "./PolicyDetail.interface";
import {PolicyEnum} from "../../types/Policy.enum";
import renderRichText from "../../utils/renderRichText.util";
import Box from "@mui/material/Box";

export const PolicyDetail: FC = () => {
  const { policy } = useParams();

  const { data, loading, error } = useQuery<PolicyDetailsQueryData, PolicyDetailsQueryVariables>(POLICY_QUERY, {
    client: contentfulClient,
    variables: {
      // @todo Ensure this is actually a policy enum.
      key: policy?.toUpperCase() as PolicyEnum
    }
  });

  if (loading) return <Box sx={{ pt: 5 }}>Loading policy information.</Box>
  if (error) return <Box sx={{ pt: 5 }}>Failed to load policy information.</Box>
  if (!data?.aelPolicyDescriptionPageCollection?.items.length) return <Box sx={{ pt: 5 }}>No policy information loaded.</Box>

  return (
    <Box sx={{ pt: 5 }}>
      {data?.aelPolicyDescriptionPageCollection?.items.map(({ name, description }) => (
        <>
          <Heading headingStyle={HeadingStyle.PAGE_TITLE}>{name}</Heading>
          <div>{renderRichText(description.json)}</div>
        </>
      ))}
    </Box>
  );
}
