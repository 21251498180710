import { gql } from '@apollo/client';

// The fields returned after remove are the same fields fetched by the approved bills tab.
export const REMOVE_BILL = gql`
  mutation ($id: ID!) {
    removeBill(id: $id) {
      billId
      yearFiled
      osCreatedAt
      autoTimeOsUpdate
      osBillId
      osState
      number
      osSession
      title
      summary
      osChamber
      policyCategory
      subcategory
      spotPolicies {
        policyId
      }
    }
  }
`;
