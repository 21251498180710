import { FC } from 'react';
import { SponsorDetailsInterface } from 'src/types/SponsorDetails.interface';
import BillDetailsWrapper from './BillDetailsWrapper';
import styles from './AccordionDetails.module.scss';

interface Props {
  primary: SponsorDetailsInterface[];
  other: SponsorDetailsInterface[];
}

const SponsorDetails: FC<Props> = ({ primary = [], other = [] }) => {
  const count = primary.length + other.length;

  return (
    <BillDetailsWrapper billDetailName="Sponsors" listCount={count}>
      {Boolean(count) && (
        <ul className={styles.detailContent}>
          {primary.map((sponsor: SponsorDetailsInterface) => (
            <li key={'primary-' + sponsor.name}>
              {sponsor.name} - {formatParty(sponsor.party)}
            </li>
          ))}
          {other.map((sponsor: SponsorDetailsInterface) => (
            <li key={'additional-' + sponsor.name}>
              {sponsor.name} - {formatParty(sponsor.party)}
            </li>
          ))}
        </ul>
      )}
    </BillDetailsWrapper>
  );
};

export default SponsorDetails;

function formatParty(s: string): string {
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
}
