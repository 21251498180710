import PageTitles from 'src/types/PageTitles.enum';

const breadcrumbs = [
  { pageTitle: PageTitles.HOME, path: '/', order: 0 },
  { pageTitle: PageTitles.ABOUT, path: '/about', order: 1 },
  { pageTitle: PageTitles.FAQ, path: '/faqs', order: 1 },
  { pageTitle: PageTitles.CONTACT, path: '/contact', order: 1 },
  { pageTitle: PageTitles.BILL_DETAILS, path: '/bill-details', order: 1 },
  { pageTitle: PageTitles.MAPS_GRAPHS, path: '/maps-graphs', order: 1 },
  { pageTitle: PageTitles.TRENDS_ANALYSIS, path: '/trends-analysis', order: 1 },
  { pageTitle: PageTitles.MYTRACKER, path: '/mytracker', order: 1 },
];

export default breadcrumbs;
