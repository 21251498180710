import { FC, useContext, useState } from 'react';
import styles from './AdminLoginModal.module.scss';
import { Login } from 'src/services/AuthService';
import AdminLoginForm from './AdminLoginForm/AdminLoginForm';
import useScreenSize from 'src/hooks/useScreenSize';
import Breakpoint from 'src/types/BreakPoint.enum';
import { GlobalContext } from 'src/hooks/useGlobalContext';
import GeneralModal from '../GeneralModal/GeneralModal';
import { validateEmail } from 'src/utils/validateEmail';
import { toast } from 'react-toastify';
import { PasswordRequestForm } from 'src/pages/Landing/AuthorizationModal/PasswordRequestForm/PasswordRequestForm';
import NewAccountForm from 'src/pages/Landing/AuthorizationModal/newAccountForm/NewAccountForm';

interface AdminLoginModalProps {
  setModalOpen: React.Dispatch<boolean>;
  modalOpen: boolean;
}
const AdminLoginModal: FC<AdminLoginModalProps> = ({ modalOpen, setModalOpen }) => {
  const { screenSizeIsAtLeast } = useScreenSize();
  const { setAuthenticated, setUserData } = useContext(GlobalContext);
  const [showPwRequestForm, setShowPwRequestForm] = useState(false);
  const [renderCreateAcct, setRenderCreateAcct] = useState(false);
  const [adminLoginForm, setAdminLoginForm] = useState({
    email: '',
    password: '',
  });
  const [formError, setFormError] = useState({
    emailError: { error: false, message: '' },
    passwordError: { error: false, message: '' },
  });
  const handleClose = () => {
    setModalOpen(false);
    setAdminLoginForm({ email: '', password: '' });
    setRenderCreateAcct(false);
    setShowPwRequestForm(false);
  };

  const handleChange = (e: any) => {
    const { id, value } = e.target;
    if (formError) {
      setFormError({
        emailError: { error: false, message: '' },
        passwordError: { error: false, message: '' },
      });
    }
    setAdminLoginForm({ ...adminLoginForm, [id]: value });
  };

  const handleLogin = async () => {
    const isEmailValid = validateEmail(adminLoginForm.email);
    if (!isEmailValid) {
      return setFormError({ ...formError, emailError: { error: true, message: 'Email not vaild' } });
    }
    try {
      await Login(adminLoginForm, setAuthenticated, setUserData, handleClose);
      toast.success('You have successfully logged in.', {
        position: toast.POSITION.TOP_CENTER,
        onClose: () => window.scrollTo({ top: 0, behavior: 'smooth' }),
      });
    } catch (e) {
      setFormError({
        emailError: { error: true, message: 'Incorrect username or password. Please try again.' },
        passwordError: { error: true, message: 'Incorrect username or password. Please try again.' },
      });
    }
  };

  const style = {
    width:
      renderCreateAcct || showPwRequestForm
        ? screenSizeIsAtLeast[Breakpoint.BASE]
          ? 'auto'
          : '85%'
        : screenSizeIsAtLeast[Breakpoint.BASE]
        ? '450px'
        : '85%',
    padding: screenSizeIsAtLeast[Breakpoint.BASE] ? '30px' : '22px',
  };

  return (
    <GeneralModal modalOpen={modalOpen} onClose={handleClose} styling={style}>
      <div className={styles.modalContent}>
        {!renderCreateAcct && !showPwRequestForm ? (
          <AdminLoginForm
            handleChange={handleChange}
            handleLogin={handleLogin}
            loginForm={adminLoginForm}
            formError={formError}
            setShowPwRequestForm={setShowPwRequestForm}
          />
        ) : !renderCreateAcct && showPwRequestForm ? (
          <PasswordRequestForm setRenderCreateAcct={setRenderCreateAcct} />
        ) : (
          <NewAccountForm handleClose={handleClose} />
        )}
      </div>
    </GeneralModal>
  );
};

export default AdminLoginModal;
