import React, { FC, useEffect } from 'react';
import GeneralLinkBody from 'src/components/GeneralLinkBody/GeneralLinkBody';
import Heading from 'src/components/Heading/Heading';
import HeadingStyle from 'src/components/Heading/HeadingStyle.enum';
import HeaderImage from 'src/assets/svg/about-header-image.svg';
import greenGrid from 'src/assets/svg/color-grid-green.svg';
import caretDown from '../../assets/svg/caret-down-blue.svg';
import styles from './MyTrackerHeader.module.scss';
import { useNavigate, useLocation } from 'react-router-dom';
import { MYTRACKER_HEADING_TEXT_QUERY } from './MyTrackerHeading.graphql';
import { useQuery } from '@apollo/client/react/hooks/useQuery';
import contentfulClient from 'src/common/apolloClient';

interface MyTrackerHeaderProps {
  userBillsDataLength: any;
}

const MyTrackerHeader: FC<MyTrackerHeaderProps> = ({ userBillsDataLength }) => {
  const navigate = useNavigate();
  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    if (hash === '') {
      window.scrollTo(0, 0);
    } else {
      setTimeout(() => {
        const element = document.getElementById(hash.replace('#', ''));
        if (element) element.scrollIntoView({ behavior: 'smooth' });
      }, 100);
    }
  }, [pathname, hash, key]);

  const handleLogout = () => {
    navigate('/logout');
  };

  const billTotal = `(${userBillsDataLength || '0'} Bill${userBillsDataLength === 1 ? '' : 's'})`;
  const { data } = useQuery(MYTRACKER_HEADING_TEXT_QUERY, { client: contentfulClient });
  let description;

  if (data) {
    let response = data?.myTrackerHeadingContentCollection?.items[0];
    description = response.descriptionText;
  }

  return (
    <>
      <div className={styles.headingContainer}>
        <Heading className={styles.headingText} headingStyle={HeadingStyle.PAGE_TITLE}>
          myTracker<span className={styles.billTotal}> {billTotal} </span>
          <GeneralLinkBody className={styles.headingLink} href="#accountManage">
            Jump to Account & Preferences <img src={caretDown} alt="caret down" className={styles.icon} />
          </GeneralLinkBody>
        </Heading>
        <img src={HeaderImage} alt="header" />
      </div>
      <div className={styles.descriptionContainer}>
        <span>{description}</span>
        <div className={styles.buttonWrapper}>
          <button className={styles.logoutButton} onClick={handleLogout}>
            Logout
          </button>
          <img src={greenGrid} alt="grid" />
        </div>
      </div>
    </>
  );
};

export default MyTrackerHeader;
