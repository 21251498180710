import React, { useContext } from 'react';
import styles from './MainNav.module.scss';
import MenuItemsDesktop from './MenuItemsDesktop/MenuItemsDesktop';
import { navItems } from '../../const/navItems';
import { navItemsAuthenticated } from 'src/const/navItemsAuthenticated';
import useScreenSize from '../../hooks/useScreenSize';
import Breakpoint from '../../types/BreakPoint.enum';
import { GlobalContext } from 'src/hooks/useGlobalContext';

const MainNav = () => {
  const { screenSizeIsAtLeast } = useScreenSize();
  const { authenticated } = useContext(GlobalContext);

  const links = [...navItems];

  if (authenticated !== 'loading' && authenticated) links.push(...navItemsAuthenticated);

  return (
    <div className={styles.mainNavContainer}>
      <div className={styles.innerContent}>
        <div className={styles.navLinksContainer}>
          {screenSizeIsAtLeast[Breakpoint.LG] && (
            <nav className={styles.upperNavLink}>
              <ul className={styles.nav}>
                {links.map((navItem, index) => (
                  <MenuItemsDesktop key={index} item={navItem} />
                ))}
              </ul>
            </nav>
          )}
        </div>
      </div>
    </div>
  );
};

export default MainNav;
