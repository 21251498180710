import React, { FC } from 'react';
import styles from './AelBanner.module.scss';
import aelHeaderCurve from '../../assets/svg/ael-header-curve.svg';
import aelLogo from '../../assets/logos/ael-tracker-white-text-logo.png';
import { AEL_TEXT_QUERY } from './AelBanner.graphql';
import { useQuery } from '@apollo/client';
import contentfulClient from 'src/common/apolloClient';
import { Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const AelBanner: FC = () => {
  const { data, error } = useQuery(AEL_TEXT_QUERY, { client: contentfulClient });
  let aelText = '';

  if (error) {
    throw error;
  }
  if (data) {
    aelText = data?.aelTrackerBannerTextCollection?.items[0]?.text;
  }
  const topRoundedClip = (
    <svg className={styles.svgBanner} width="500" height="250" viewBox="0 0 500 80" preserveAspectRatio="none">
      <defs>
        <clipPath id="shape">
          <path d="M0,0 L0,40 Q250,80 500,40 L500,0 Z" />
        </clipPath>
      </defs>
      <rect x="0" y="0" width="500" height="80" fill="#FFF" clipPath="url(#shape)" />
    </svg>
  );
  return (
    <div className={styles.mainContainer}>
      <div className={styles.roundedContainer}>{topRoundedClip}</div>
      <header className={styles.header}>
        <div className={styles.innerContainer}>
          <div className={styles.logoContainer}>
            <Link component={RouterLink} to='/'>
              <img src={aelLogo} alt="Advanced Energy Legislation Tracker Logo" />
            </Link>
          </div>
          <h2>{aelText}</h2>
        </div>
      </header>
      <img src={aelHeaderCurve} className={styles.aelHeaderCurve} alt="" />
    </div>
  );
};

export default AelBanner;
