import React, { FC, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import styles from './BillAdministration.module.scss';
import { colors, gradients } from 'src/theme/colors.js';
import useScreenSize from 'src/hooks/useScreenSize';
import Breakpoint from 'src/types/BreakPoint.enum';
import Heading from 'src/components/Heading/Heading';
import HeadingStyle from 'src/components/Heading/HeadingStyle.enum';
import { updateTabTitle } from 'src/utils/updateTabTitle';
import PageTitles from 'src/types/PageTitles.enum';
import {PendingBills} from "./PendingBills/PendingBills";
import {ApprovedBills} from "./ApprovedBills/ApprovedBills";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  tabIndex: number;
}

const a11yProps = (index: number) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});

const useStyles = makeStyles((theme: any) => ({
  tab: {
    '&:hover,&:focus-visible': {
      textDecoration: 'underline',
      color: colors.white,
      background: gradients.medGray,
      fontWeight: '600',
    },

    '&.Mui-selected': {
      background: colors.lightGray7,
      color: colors.darkGray1,
      fontWeight: '600',
      '&:hover': {
        textDecoration: 'underline',
        color: colors.darkGray1,
      },
      '&:focus-visible': {
        background: gradients.lightGray,
      },
    },
  },
}));

const defaultTabStyles = {
  background: gradients.lightGray,
  color: colors.black,
  borderTopLeftRadius: '10px',
  borderTopRightRadius: '8px',
  marginRight: '8px',
  fontFamily: 'Lato',
  textTransform: 'none',
  '&:hover,&:focus-visible': {
    textDecortation: 'underline',
  },
}

const defaultTabIndicatorStyles = {
  background: colors.medGray1,
  marginLeft: '30px',
  height: '1px',
}

const TabPanel: FC<TabPanelProps> = ({ children, tabIndex, index, ...other }) => (
  <div
    role="tabpanel"
    className={styles.tabPanel}
    hidden={tabIndex !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}
  >
    {tabIndex === index && <Box sx={{ p: 3 }}>{children}</Box>}
  </div>
);

export const BillAdministration: FC = () => {
  updateTabTitle(PageTitles.BILL_ADMIN);
  const { screenSizeIsAtLeast } = useScreenSize();
  const classes = useStyles();
  const tabClasses = { root: classes.tab };
  const [tabIndex, setTabIndex] = useState(0);

  const tabStyles = {
    ...defaultTabStyles,
    minWidth: screenSizeIsAtLeast[Breakpoint.LG] ? '200px' : '150px',
    minHeight: screenSizeIsAtLeast[Breakpoint.LG] ? '60px' : '50px',
    fontSize: screenSizeIsAtLeast[Breakpoint.LG] ? '1.2rem' : '.9rem',
  };

  const tabIndicatorStyles = {
    ...defaultTabIndicatorStyles,
    width: screenSizeIsAtLeast[Breakpoint.LG] ? '140px' : '90px',
  }

  return (
    <>
      <Heading headingStyle={HeadingStyle.PAGE_TITLE}>New Bills</Heading>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderColor: 'divider' }}>
          <Tabs
            value={tabIndex}
            textColor="inherit"
            TabIndicatorProps={{ style: tabIndicatorStyles }}
            onChange={(e, n) => setTabIndex(n)}
            aria-label="Bill Administration Tabs"
          >
            <Tab label="New Bills" disableFocusRipple classes={tabClasses} {...a11yProps(0)} sx={tabStyles} />
            <Tab label="Approved Bills" disableFocusRipple classes={tabClasses} {...a11yProps(1)} sx={tabStyles} />
          </Tabs>
        </Box>
        <TabPanel tabIndex={tabIndex} index={0}>
          <PendingBills />
        </TabPanel>
        <TabPanel tabIndex={tabIndex} index={1}>
          <ApprovedBills />
        </TabPanel>
      </Box>
    </>
  );
};
