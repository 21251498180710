import BreadCrumbs from 'src/components/BreadCrumbs/BreadCrumbs';
import Heading from 'src/components/Heading/Heading';
import HeadingStyle from 'src/components/Heading/HeadingStyle.enum';
import styles from './Faqs.module.scss';
import FaqSection from 'src/pages/Faqs/FaqSection/FaqSection';
import { useQuery } from '@apollo/client';
import { FAQS_HEADING_QUERY } from './FaqsHeading.graphql';
import { updateTabTitle } from 'src/utils/updateTabTitle';
import PageTitles from 'src/types/PageTitles.enum';
import contentfulClient from 'src/common/apolloClient';

export default function Faqs(): React.ReactElement {
  updateTabTitle(PageTitles.FAQ);
  const { data } = useQuery(FAQS_HEADING_QUERY, { client: contentfulClient });
  let faqHeadingData;

  if (data) {
    faqHeadingData = data?.faqPageHeaderContentCollection?.items[0];
  }

  return (
    <>
      <BreadCrumbs />
      {data && (
        <div className={styles.faqHeading}>
          <Heading headingStyle={HeadingStyle.PAGE_TITLE}>{faqHeadingData.faqPageHeader}</Heading>
          <Heading headingStyle={HeadingStyle.PAGE_SUBTITLE}>{faqHeadingData.faqPageSubHeader}</Heading>
        </div>
      )}
      <div className={styles.faqContent}>
        <FaqSection />
      </div>
    </>
  );
}
