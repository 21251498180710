import { Button, Typography } from '@mui/material';
import {DeleteAccount} from "../../../../services/AuthService";
import {toast} from "react-toastify";
import {UserData} from "../../../../types/UserData.interface";
import buttonTheme from "../../../../theme/buttonTheme";
import {ThemeProvider} from "@mui/material/styles";
import Box from "@mui/material/Box";

interface DeactivateUserModalProps {
  user: UserData
  onCancel: () => void
  onComplete: () => void
}

export const DeleteUserPrompt = ({
  user,
  onCancel,
  onComplete,
}: DeactivateUserModalProps) => {

  const handleDeleteUser = async () => {
    await DeleteAccount(user.id);
    toast.success("Account deleted successfully", {
      position: toast.POSITION.TOP_CENTER
    })
    onComplete()
  }

  return (
    <Box>
      <Box>
        <Typography component="h2" variant="h5">
          Delete {user.firstName} {user.lastName}&apos;s Account
        </Typography>
      </Box>
      <Box sx={{ my: 3 }}>
        <Typography>
          Are you sure you want to delete this account?
        </Typography>
        <Typography>
          The user will lose administrative access and privileges.
        </Typography>
      </Box>
      <ThemeProvider theme={buttonTheme}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button variant="outlined" color='error' onClick={() => {handleDeleteUser()}} >
            Yes, delete
          </Button>
          <Button variant="contained" onClick={() => {onCancel()}}>
            No, do not delete
          </Button>
        </Box>
      </ThemeProvider>
    </Box>
  );
};
