import { FC } from 'react';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import styles from './ClearFieldButton.module.scss';

interface ClearFieldButtonProps {
  isVisible: boolean | false;
  id: string;
  handleClear?: (id: string) => void;
}

const ClearFieldButton: FC<ClearFieldButtonProps> = ({ isVisible, handleClear, id }) => {
  const classes = isVisible ? `${styles.clearFieldButton} ${styles.isVisible}` : `${styles.clearFieldButton}`;
  return (
    <button
      type="button"
      onClick={(event) => {
        event.preventDefault();
        if (handleClear) {
          handleClear(id);
        }
      }}
      className={classes}
    >
      Clear <HighlightOffIcon />
    </button>
  );
};

export default ClearFieldButton;
