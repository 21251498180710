export const organizationTypes = {
  'Select an Organization Type': null,
  'Clean Energy Industry' : 'CLEAN_ENERGY_INDUSTRY',
  'Utility': 'UTILITY',
  'Trade Association': 'TRADE_ASSOCIATION',
  'Education': 'EDUCATION',
  'Non-Profit': 'NON_PROFIT',
  'Federal Government': 'FEDERAL_GOVERNMENT',
  'State/Local Government': 'STATE_LOCAL_GOVERNMENT',
  'Regulatory Commission/Staff': 'REGULATORY_COMMISSION_STAFF',
  'Media': 'MEDIA',
  'Foundation': 'FOUNDATION',
  'Individual (not affiliated)': 'INDIVIDUAL_NOT_AFFILIATED',
  'Other': 'OTHER',
};
