import React, { FC } from 'react';
import { BillsUpdatedSinceData } from '../types/types';
import TimeOptions from '../../../../types/TimeOptions.enum';
import styles from '../BillChangesSection.module.scss';
import openBillDetailsInNewTab from '../../../../utils/openBillDetailsInNewTab.util';

interface SortedBillListProps {
  unsortedBillData: BillsUpdatedSinceData[];
  timeFilter: string;
}

const SortedBillList: FC<SortedBillListProps> = ({ unsortedBillData, timeFilter }) => {
  if (!unsortedBillData || timeFilter === TimeOptions.DEFAULT) return <></>;
  const sortedBillsByStateAndNumber = unsortedBillData.sort(
    (billA, billB) => billA.state.localeCompare(billB.state) || billA.number.localeCompare(billB.number),
  );
  if (sortedBillsByStateAndNumber.length > 0) {
    return (
      <>
        <ul className={styles.billContainer}>
          {sortedBillsByStateAndNumber.map((bill: BillsUpdatedSinceData, index: number) => (
            <li key={index}>
              <button
                className={styles.billLinks}
                onClick={() => {
                  openBillDetailsInNewTab(bill?.osBillId);
                }}
              >
                {bill.number} - {bill.state} - {bill.title}
              </button>
            </li>
          ))}
        </ul>
      </>
    );
  } else {
    return (
      <span className={styles.noDataText}>No recent bill changes have been found within the chosen timeframe</span>
    );
  }
};

export default SortedBillList;
