import React, { FC, useContext, useState } from 'react';
import styles from './BillCard.module.scss';
import { BillData } from "../../types/BillData.interface";
import { GlobalContext } from 'src/hooks/useGlobalContext';
import { useMutation } from '@apollo/client';
import closeIcon from 'src/assets/svg/close-icon.svg';
import { toast } from 'react-toastify';
import isUserAdmin from '../../pages/MyTracker/util/isAdmin';
import Sponsors from './components/Sponsors';
import MainContent from './components/MainContent';
import BillCardWrapper from './components/BillCardWrapper';
import BillIcons from './components/BillIcons';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import { REMOVE_CELA_BILL } from './AdminCard.graphql';

interface AdminBillCardProps {
  billData: BillData;
  refetchBills: () => void;
}

const AdminBillCard: FC<AdminBillCardProps> = ({ billData, refetchBills }) => {
  const { userData, setAuthModalOpen } = useContext(GlobalContext);
  const isAdmin = isUserAdmin(userData.role);
  const billCardDetails = billData;
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [removeCelaBill] = useMutation(REMOVE_CELA_BILL);

  const handleDelete = async () => {
    if (isAdmin) {
      await removeCelaBill({ variables: { billId: billCardDetails?.id } }).then(refetchBills);
      toast.success(
        `Removed ${billCardDetails?.state} ${
          billCardDetails?.designation
        } from CELA bills`,
        {
          position: toast.POSITION.TOP_CENTER,
        },
      );
      setModalOpen(false);
    } else {
      setAuthModalOpen(true);
    }
  };

  return (
    <>
      <ConfirmModal
        title="Remove bill from CELA list"
        actionButtonText="Remove"
        description="Are you sure you want to remove this bill from the shared CELA list?"
        handleDelete={handleDelete}
        modalOpen={modalOpen}
        onClose={() => setModalOpen(false)}
      />
      <section className={styles.card}>
        <BillCardWrapper billId={billCardDetails?.billId}>
          <MainContent billCardDetails={billCardDetails} maxTitleLength={135} />
          <Sponsors sponsorsObject={billCardDetails.sponsors} />
          <BillIcons billCardDetails={billCardDetails} />
        </BillCardWrapper>
        <button className={styles.billTrack} onClick={() => setModalOpen(true)}>
          <img src={closeIcon} alt="" className={styles.closeIcon} />
          <span className={styles.billTrackText}>Remove CELA Bill</span>
        </button>
      </section>
    </>
  );
};

export default AdminBillCard;
