import VetoedIcon from 'src/assets/icons/status-vetoed.svg'
import EnactedIcon from 'src/assets/icons/status-enacted.svg'
import FailedIcon from 'src/assets/icons/status-failed.svg'
import IntroducedIcon from 'src/assets/icons/status-introduced.svg'
import PassedOneChamberIcon from 'src/assets/icons/status-passed-1-chamber.svg'
import PassedTwoChambersIcon from 'src/assets/icons/status-passed-2-chambers.svg'
import {StatusEnum} from "../types/Status.enum";

export const statusIcon = {
  [StatusEnum.INTRODUCED]: IntroducedIcon,
  [StatusEnum.PASSED_ONE_CHAMBER]: PassedOneChamberIcon,
  [StatusEnum.PASSED_BOTH_CHAMBERS]: PassedTwoChambersIcon,
  [StatusEnum.ENACTED]: EnactedIcon,
  [StatusEnum.FAILED]: FailedIcon,
  [StatusEnum.VETOED]: VetoedIcon
}
