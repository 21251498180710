import {BillData} from "../types/BillData.interface";
import {BillInterface} from '../types/Bill.interface';
import {SponsorTypeEnum} from 'src/types/SponsorType.enum';
import {BillSponsorshipInterface} from 'src/types/BillSponsorship.interface';
import {SponsorDetailsInterface} from 'src/types/SponsorDetails.interface';

const lastNameThenFirstNameSort = (personA: SponsorDetailsInterface, personB: SponsorDetailsInterface) => {
  return personA.lastName.localeCompare(personB?.lastName) || personA.firstName.localeCompare(personB?.firstName)
}

const getLastUpdated = (date?: string): string => {
  return date ? date.slice(0, 10) : '';
};

export const mapResponseToBill = (eprcBill: BillInterface): BillData => {

  const primary: SponsorDetailsInterface[] = eprcBill.sponsorships
    .filter(({ type }) => type === SponsorTypeEnum.PRIMARY_SPONSOR)
    .map(({ person, order, type }: BillSponsorshipInterface) => {
      const { name, party, district, firstName, lastName } = person;
      return { order, name, party, district, firstName, lastName, office: '', type };
    })
    .sort(lastNameThenFirstNameSort);

  const other: SponsorDetailsInterface[] = eprcBill.sponsorships
    .filter(({ type }) => type !== SponsorTypeEnum.PRIMARY_SPONSOR)
    .map(({ person, order, type }: BillSponsorshipInterface) => {
      const { name, party, district, firstName, lastName } = person;
      return { order, name, party, district, firstName, lastName, office: '', type };
    })
    .sort(lastNameThenFirstNameSort);

  // Components should just be receiving the data. It shouldn't care if the data was overridden.
  // @todo All override patching should happen here and not in the components.
  return {
    billId: eprcBill.osBillId,
    id: eprcBill.billId,
    apiUrl: eprcBill.apiUrl,
    state: eprcBill.state,
    designation: eprcBill.number,
    introductionYear: eprcBill.yearFiled,
    description: eprcBill.summary,
    datePassedLower: eprcBill.osDatePassedLower,
    datePassedUpper: eprcBill.osDatePassedUpper,
    dateSigned: eprcBill.osDateSigned,
    lastUpdated: getLastUpdated(eprcBill.autoTimeOsUpdate),
    sponsors: { primary, other },
    title: eprcBill.title,
    chamber: eprcBill.osChamber,
    policyCategory: eprcBill.policyCategory,
    subcategory: eprcBill.subcategory,
    topic: eprcBill.topic,
    summary: eprcBill.summary,
    status: eprcBill.osStatus,
    createdAt: eprcBill.osCreatedAt,
    cela: eprcBill.cela,
    actions: eprcBill.actions ?? [],
    rollCalls: eprcBill.rollCalls ?? [],
    docs: eprcBill.docs ?? [],
  };
};
