import { useQuery } from '@apollo/client';
import React, { FC } from 'react';
import GeneralAccordion from 'src/components/GeneralAccordion/GeneralAccordion';
import BasicCard from 'src/components/BasicCard/BasicCard';
import BreadCrumbs from 'src/components/BreadCrumbs/BreadCrumbs';
import Heading from 'src/components/Heading/Heading';
import HeadingStyle from 'src/components/Heading/HeadingStyle.enum';
import renderRichText from 'src/utils/renderRichText.util';
import { TRENDS_ANALYSIS_ACCORDIONS_QUERY, TRENDS_ANALYSIS_INTRO_QUERY } from './TrendsAndAnalysis.graphql';
import styles from './TrendsandAnalysis.module.scss';
import contentfulClient from 'src/common/apolloClient';
import { updateTabTitle } from 'src/utils/updateTabTitle';
import PageTitles from 'src/types/PageTitles.enum';
import regulatoryIcon from 'src/assets/icons/regulatory.svg';
import naturalGasIcon from 'src/assets/icons/natural-gas.svg';
import infrastructureIcon from 'src/assets/icons/infrastructure.svg';
import otherEnergyIcon from 'src/assets/icons/other-energy.svg';
import emissionsIcon from 'src/assets/icons/emissions.svg';
import energyEfficiencyIcon from 'src/assets/icons/energy-efficiency.svg';
import economicDevelopmentIcon from 'src/assets/icons/economic-development.svg';
import financingIcon from 'src/assets/icons/financing.svg';
import transportationIcon from 'src/assets/icons/transportation.svg';
import electricityGenerationIcon from 'src/assets/icons/electricity-generation.svg';

const TrendsAndAnalysis: FC = () => {
  updateTabTitle(PageTitles.TRENDS_ANALYSIS);
  const { data: introData } = useQuery(TRENDS_ANALYSIS_INTRO_QUERY, { client: contentfulClient });
  const { data: accordionData } = useQuery(TRENDS_ANALYSIS_ACCORDIONS_QUERY, { client: contentfulClient });
  let pageHeader, introText, accordions;

  if (introData) {
    const response = introData?.trendsAndAnalysisContentCollection?.items[0];
    introText = response?.trendsAndAnalysisIntro?.json;
    pageHeader = response?.trendsAndAnalysisTitle;
  }

  if (accordionData) {
    const response = accordionData?.trendsAndAnalysisAccordionCollection?.items;
    accordions = [...response].sort((a: any, b: any) => a.order - b.order);
  }

  const getIconSrc = (icon: string) => {
    switch (icon) {
      case 'regulatory':
        return regulatoryIcon;
      case 'natural-gas':
        return naturalGasIcon;
      case 'infrastructure':
        return infrastructureIcon;
      case 'other-energy':
        return otherEnergyIcon;
      case 'emissions':
        return emissionsIcon;
      case 'energy-efficiency':
        return energyEfficiencyIcon;
      case 'economic-development':
        return economicDevelopmentIcon;
      case 'financing':
        return financingIcon;
      case 'transportation':
        return transportationIcon;
      case 'electricity-generation':
        return electricityGenerationIcon;
      default:
        return otherEnergyIcon;
    }
  };

  const policyCategoryAccordions = accordions?.map((accordion: any, index: number) => {
    return (
      <div key={index} className={styles.accordionWrapper}>
        <GeneralAccordion
          accordionTitle={
            <>
              {accordion.accordionIcon ? (
                <img src={getIconSrc(accordion.accordionIcon)} className={styles.icon} alt={accordion.accordionIcon} />
              ) : null}
              {accordion.accordionTitle}
            </>
          }
          accordionContent={
            <>{renderRichText(accordion?.accordionContent?.json, accordion?.accordionContent?.links)}</>
          }
          id={index.toString()}
        ></GeneralAccordion>
      </div>
    );
  });

  return (
    <>
      <section>
        <BreadCrumbs />
        <Heading headingStyle={HeadingStyle.PAGE_TITLE}>{pageHeader}</Heading>
        <Heading headingStyle={HeadingStyle.PAGE_SUBTITLE} className={styles.subtitle}>
          {renderRichText(introText)}
        </Heading>
      </section>
      <section>
        <BasicCard id={styles.card}>{policyCategoryAccordions}</BasicCard>
      </section>
    </>
  );
};

export default TrendsAndAnalysis;
