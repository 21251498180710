import styles from './Contact.module.scss';
import Heading from 'src/components/Heading/Heading';
import HeadingStyle from 'src/components/Heading/HeadingStyle.enum';
import { useQuery } from '@apollo/client';
import { CONTACT_TEXT_QUERY } from './Contact.graphql';
import { ChangeEvent, FormEvent, useState } from 'react';
import BreadCrumbs from 'src/components/BreadCrumbs/BreadCrumbs';
import { updateTabTitle } from 'src/utils/updateTabTitle';
import PageTitles from 'src/types/PageTitles.enum';
import contentfulClient from 'src/common/apolloClient';
import { ContactFormTemplate } from 'src/types/ContactForm';
import ContactForm from './ContactForm';
import { toast } from 'react-toastify';
import { validateEmail } from 'src/utils/validateEmail';
import { ContactHeaderQueryInterface } from './Contact.interface';
import { SubmitContactForm } from 'src/services/AuthService';

class InputError {
  error: boolean = false;
  message: string = '';
}

export class ContactFormError {
  firstName: InputError = new InputError();
  lastName: InputError = new InputError();
  email: InputError = new InputError();
  organization: InputError = new InputError();
  body: InputError = new InputError();
}

const Contact = () => {
  updateTabTitle(PageTitles.CONTACT);
  const [form, setForm] = useState<ContactFormTemplate>(new ContactFormTemplate());
  const [error, setError] = useState<ContactFormError>(new ContactFormError());

  const { data } = useQuery<ContactHeaderQueryInterface>(CONTACT_TEXT_QUERY, { client: contentfulClient });
  const intro = data?.contactFormTextCollection?.items ?? []

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    if (error) setError(new ContactFormError());
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const validEmail = validateEmail(form.email);
    if (!validEmail) {
      setError((formErrors) => ({ ...formErrors, email: { error: true, message: 'Email not valid' } }));
      return;
    }
    if (error.email.error) {
      toast.error('Email failed to send. Please try again', {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      try {
        await SubmitContactForm(form);
        toast.success(`Email Sent`, {
          position: toast.POSITION.TOP_CENTER,
        });
        setForm(new ContactFormTemplate());
      } catch (error) {
        toast.error('Email failed to send. Please try again', {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  };

  const handleCancel = () => {
    setForm(new ContactFormTemplate());
    setError(new ContactFormError());
  };

  const contactFormProps = {
    form,
    handleSubmit,
    handleChange,
    error,
    setForm,
    handleCancel,
  };

  return (
    <section>
      <BreadCrumbs />
        {intro.map(({ header, description }) => (
          <>
            <Heading headingStyle={HeadingStyle.PAGE_TITLE} className={styles.heading}>
              {header}
            </Heading>
            <Heading headingStyle={HeadingStyle.PAGE_SUBTITLE} className={styles.subHeader}>
              {description}
            </Heading>
          </>
        ))}
      <ContactForm {...contactFormProps} />
    </section>
  );
};

export default Contact;
