import styles from './FilterConfig.module.scss';
import FilterSearch from './FiltersTableSection/FilterSearch';
import AdminButton from '../../components/AdminButton/AdminButton';
import React, { FC } from 'react';

interface SearchRunAndCreateFieldsProps {
  count: number;
  pageSize: number;
  handleSearch: (n: string) => void;
  handleCreateFilter: () => void;
  handleRunFilters: () => void;
}

export const SearchRunAndCreateFields: FC<SearchRunAndCreateFieldsProps> = ({
  count,
  pageSize,
  handleSearch,
  handleCreateFilter,
  handleRunFilters,
}) => {
  return (
    <div className={styles.filtersTableTop}>
      <div className={styles.tableTools}>
        <FilterSearch handleSearch={(e) => handleSearch(e.target.value)} />
        <div className={styles.filterButtons}>
          <AdminButton text={'Run Filters'} onClick={handleRunFilters} />
          <AdminButton text={'Create Filter'} onClick={handleCreateFilter} />
        </div>
      </div>
      <div className={styles.tableInfo}>
        <span>
          You are viewing {pageSize} out of {count} Legiscan filters
        </span>
      </div>
    </div>
  );
};
