import React, { FC, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Heading from 'src/components/Heading/Heading';
import HeadingStyle from 'src/components/Heading/HeadingStyle.enum';
import Input from 'src/components/Input/Input';
import PrimaryButton from 'src/components/PrimaryButton/PrimaryButton';
import { ResetPassword } from 'src/services/AuthService';
import styles from './PasswordResetForm.module.scss';

interface PasswordResetFormProps {
  userForReset: { email: string; passwordResetToken: string };
  handleClose: () => void;
}

export const PasswordResetForm: FC<PasswordResetFormProps> = ({ userForReset, handleClose }) => {
  const navigate = useNavigate();
  const [form, setForm] = useState({
    newPassword: '',
    confirmNewPassword: '',
  });
  const [passwordError, setPasswordError] = useState({
    error: false,
    message: '',
  });
  const [isDisabled, setIsDisabled] = useState(true);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    if (passwordError) {
      setPasswordError({ error: false, message: '' });
    }
    setForm({ ...form, [id]: value });
  };

  const handleCaptchaChange = (value: any) => {
    return value ? setIsDisabled(false) : setIsDisabled(true);
  };

  const saveNewPassword = () => {
    const resetPassword = async () => {
      try {
        await ResetPassword({ ...userForReset, newPassword: form.newPassword });
        handleClose();
        navigate('/');
        toast.success("You've successfully reset your password. Log into myTracker to access your account.", {
          position: toast.POSITION.TOP_CENTER,
        });
      } catch (e) {
        toast.error('Update failed. Please try again later.', {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    };
    resetPassword();
  };

  const checkForm = () => {
    const isMatch = form.newPassword === form.confirmNewPassword;
    if (!isMatch) {
      return setPasswordError({ error: true, message: 'Passwords need to match' });
    }
    return saveNewPassword();
  };

  const handlePressEnter = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !isDisabled) {
      checkForm();
    }
  };

  return (
    <div className={styles.contentContainer}>
      <div className={styles.headingSection}>
        <h2>myTracker</h2>
        <p>Create a personalized list of bills to track to stay up-to-date on recent bill action and news.</p>
      </div>
      <form
        className={styles.form}
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <Heading headingStyle={HeadingStyle.PAGE_TITLE}>Reset password</Heading>
        <div className={styles.inputContainer}>
          <div className={styles.inputNew}>
            <Input
              error={passwordError.error}
              helperText={passwordError.message}
              handleChange={handleChange}
              label="Password*"
              type="password"
              value={form.newPassword}
              id="newPassword"
              placeholder={'Password'}
              fullWidth={true}
              onKeyDown={handlePressEnter}
            />
          </div>
          <div className={styles.inputConfirm}>
            <Input
              error={passwordError.error}
              helperText={passwordError.message}
              handleChange={handleChange}
              label="Confirm Password*"
              type="password"
              value={form.confirmNewPassword}
              id="confirmNewPassword"
              placeholder={'Confirm Password'}
              fullWidth={true}
              onKeyDown={handlePressEnter}
            />
          </div>
        </div>
        <ReCAPTCHA sitekey={`${process.env.REACT_APP_SITE_KEY}`} onChange={handleCaptchaChange} />
        <div className={styles.buttonContainer}>
          <PrimaryButton disabled={isDisabled} onClick={checkForm} text="Save Password" />
        </div>
      </form>
    </div>
  );
};
