import { FC, useContext, useEffect, useState } from 'react';
import './Landing.scss';
import MyTrackerSection from './MyTrackerSection/MyTrackerSection';
import MainSection from './MainSection/MainSection';
import AuthorizationModal from './AuthorizationModal/AuthorizationModal';
import BreadCrumbs from 'src/components/BreadCrumbs/BreadCrumbs';
import { updateTabTitle } from 'src/utils/updateTabTitle';
import PageTitles from 'src/types/PageTitles.enum';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ActivateAccountPublic,
  GetUserByActivationToken,
  GetUserByByPasswordResetToken,
  UnsubscribeFromAllEmails,
} from 'src/services/AuthService';
import { toast } from 'react-toastify';
import { GlobalContext } from 'src/hooks/useGlobalContext';
import Box from "@mui/material/Box";

const Landing: FC = () => {
  updateTabTitle(PageTitles.HOME);
  const { activationId, uuid, resetToken }: any = useParams();
  const { setAuthModalOpen } = useContext(GlobalContext);
  const [activatedUser, setActivatedUser] = useState({ active: false });
  const [userForReset, setUserForReset] = useState({ email: '', passwordResetToken: resetToken });
  const [showPwResetForm, setShowPwResetForm] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (uuid) {
      UnsubscribeFromAllEmails(uuid);
      navigate('/');
      toast.success('You have been unsubscribed from all future myTracker notifications', {
        position: toast.POSITION.TOP_CENTER,
      });
    }
    // eslint-disable-next-line
  }, [uuid]);

  useEffect(() => {
    GetUserByActivationToken(activationId, setActivatedUser);
  }, [activationId]);

  useEffect(() => {
    if (resetToken) {
      GetUserByByPasswordResetToken(resetToken, userForReset, setUserForReset);
    } else {
      setShowPwResetForm(false);
    }
    // eslint-disable-next-line
  }, [resetToken]);

  useEffect(() => {
    if (activatedUser.active) {
      const activateAccount = async () => {
        try {
          await ActivateAccountPublic(activatedUser);
          toast.success('Account successfully activated. Please log in to access myTracker.', {
            position: toast.POSITION.TOP_CENTER,
          });
          navigate('/');
        } catch (e) {
          toast.error('Account activation failed', {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      };
      activateAccount();
    }
    if (userForReset.email.length) {
      setAuthModalOpen(true);
      setShowPwResetForm(true);
    }
    // eslint-disable-next-line
  }, [activatedUser, userForReset, setAuthModalOpen]);

  return (
    <>
      <BreadCrumbs />
        <Box sx={{ mb: '1.5rem '}}>
          <MyTrackerSection />
        </Box>
        <MainSection />
      <AuthorizationModal showPwResetForm={showPwResetForm} userForReset={userForReset} />
    </>
  );
};

export default Landing;
