import React, { FC, useState } from 'react';
import PrimaryButton from 'src/components/PrimaryButton/PrimaryButton';
import Input from 'src/components/Input/Input';
import styles from './NewAccountForm.module.scss';
import aelLogo from '../../../../assets/logos/ael-tracker-colored-text-logo.png';
import { CreateAccountPublic } from 'src/services/AuthService';
import GeneralSelect from 'src/components/GeneralSelect/GeneralSelect';
import { validateEmail } from 'src/utils/validateEmail';
import { organizationTypes } from 'src/const/organizationTypes';
import { OrganizationTypeMap } from 'src/common/interfaces/organizationTypeMap';
import ReCAPTCHA from 'react-google-recaptcha';

interface NewAccountFormProps {
  handleClose: () => void;
}

const newAcctFormTemplate = {
  firstName: '',
  lastName: '',
  email: '',
  organization: '',
  organizationType: 'Select an Organization Type',
  newPassword: '',
  confirmNewPassword: '',
};

const formErrorTemplate = {
  firstNameError: { error: false, message: '' },
  lastNameError: { error: false, message: '' },
  organizationTypeError: { error: false, message: '' },
  emailError: { error: false, message: '' },
  passwordError: { error: false, message: '' },
};

const NewAccountForm: FC<NewAccountFormProps> = ({ handleClose }) => {
  const [newAcctForm, setNewAcctForm] = useState(newAcctFormTemplate);
  const [formError, setFormError] = useState(formErrorTemplate);
  const [isDisabled, setIsDisabled] = useState(true);

  const orgTypes: OrganizationTypeMap = { ...organizationTypes };

  const checkFormFields = () => {
    let hasMissingFields = false;
    let fieldError = { ...formError };
    const requiredFields = Object.keys(newAcctForm);
    requiredFields.splice(3, 1);
    requiredFields.forEach((field) => {
      let isFilled = newAcctForm[field as keyof typeof newAcctForm].length > 0;
      if (field === 'organizationType') {
        isFilled = newAcctForm[field as keyof typeof newAcctForm] !== 'Select an Organization Type';
      }
      if (!isFilled) {
        let errorKey = field === 'newPassword' || field === 'confirmNewPassword' ? 'passwordError' : `${field}Error`;
        fieldError = { ...fieldError, [errorKey]: { error: true, message: 'This is a required field' } };
        hasMissingFields = true;
      }
    });
    setFormError(fieldError);
    return hasMissingFields;
  };

  const checkForm = () => {
    const isEmailValid = validateEmail(newAcctForm.email);
    const isMatch = newAcctForm.newPassword === newAcctForm.confirmNewPassword;
    if (checkFormFields()) {
      return;
    }
    if (!isEmailValid) {
      return setFormError({ ...formError, emailError: { error: true, message: 'Email not vaild' } });
    }
    if (!isMatch) {
      return setFormError({ ...formError, passwordError: { error: true, message: 'Passwords need to match' } });
    }
    if (isDisabled) {
      return;
    }
    return handleRegisterAccount();
  };

  const handleChange = (e: any) => {
    const { id, value } = e.target;
    if (formError) {
      setFormError(formErrorTemplate);
    }
    setNewAcctForm({ ...newAcctForm, [id]: value });
  };

  const handleChangeSelect = (e: any) => {
    if (formError) {
      setFormError(formErrorTemplate);
    }
    const { name, value } = e.target;
    setNewAcctForm({ ...newAcctForm, [name]: value });
  };
  const handleCaptchaChange = (value: any) => {
    return value ? setIsDisabled(false) : setIsDisabled(true);
  };

  const handleRegisterAccount = () => {
    const body = { ...newAcctForm, organizationType: orgTypes[newAcctForm.organizationType] };
    CreateAccountPublic(body);
    handleClose();
    setNewAcctForm(newAcctFormTemplate);
  };

  return (
    <div className={styles.modalContent}>
      <div className={styles.headingSection}>
        <h2 className={styles.registerTitle}>Register for a new account</h2>
        <img src={aelLogo} className={styles.aelTitle} alt="Advanced Energy Legislation Tracker Logo" />
      </div>
      <div className={styles.formSection}>
        <form>
          <div className={styles.inputsContainer}>
            <div className={styles.input}>
              <Input
                error={formError.firstNameError.error}
                helperText={formError.firstNameError.message}
                handleChange={handleChange}
                label="First Name*"
                value={newAcctForm.firstName}
                id="firstName"
                placeholder={'First Name'}
              />
            </div>
            <div className={styles.input}>
              <Input
                error={formError.lastNameError.error}
                helperText={formError.lastNameError.message}
                handleChange={handleChange}
                label="Last Name*"
                value={newAcctForm.lastName}
                id="lastName"
                placeholder={'Last Name'}
              />
            </div>
          </div>
          <div className={styles.inputsContainer}>
            <div className={styles.input}>
              <Input
                handleChange={handleChange}
                label="Organization"
                value={newAcctForm.organization}
                id="organization"
                placeholder={'Organization'}
              />
            </div>
            <div className={styles.input}>
              <GeneralSelect
                helperText={formError.organizationTypeError.message}
                error={formError.organizationTypeError.error}
                labelText="Organization Type*"
                id="organizationType"
                handleChange={handleChangeSelect}
                value={newAcctForm.organizationType}
                placeholder="Select Organization Type"
                data={Object.keys(organizationTypes)}
              />
            </div>
          </div>
          <div className={styles.inputsContainerEmail}>
            <div className={styles.input}>
              <Input
                error={formError.emailError.error}
                helperText={formError.emailError.message}
                handleChange={handleChange}
                label="Email Address*"
                value={newAcctForm.email}
                id="email"
                placeholder={'Email Address'}
              />
            </div>
          </div>
          <div className={styles.inputsContainer}>
            <div className={styles.input}>
              <Input
                error={formError.passwordError.error}
                helperText={formError.passwordError.message}
                handleChange={handleChange}
                label="Password*"
                type="password"
                value={newAcctForm.newPassword}
                id="newPassword"
                placeholder={'Password'}
              />
            </div>
            <div className={styles.input}>
              <Input
                error={formError.passwordError.error}
                helperText={formError.passwordError.message}
                handleChange={handleChange}
                label="Confirm Password*"
                type="password"
                value={newAcctForm.confirmNewPassword}
                id="confirmNewPassword"
                placeholder={'Confirm Password'}
              />
            </div>
          </div>
          <ReCAPTCHA sitekey={`${process.env.REACT_APP_SITE_KEY}`} onChange={handleCaptchaChange} />
          <div className={styles.buttonLinkContainer}>
            <PrimaryButton disabled={isDisabled} onClick={checkForm} text="Create a new account" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewAccountForm;
