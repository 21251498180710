import React, { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import BasicCard from 'src/components/BasicCard/BasicCard';
import GeneralSelect from '../../../components/GeneralSelect/GeneralSelect';
import TimeOptions from '../../../types/TimeOptions.enum';
import { Dayjs } from 'dayjs';
import styles from './BillChangesSection.module.scss';
import getFilterDate from './util/getFilterDate';
import useTimeFilterFromUrl from '../../../hooks/useTimeFilterFromUrl';
import { BillData } from "../../../types/BillData.interface";
import {BillsUpdatedSinceData, BillsWithUpdatesData, BillsWithUpdatesInput} from './types/types';
import { useLazyQuery } from '@apollo/client';
import {GET_BILLS_WITH_UPDATES} from './BillChanges.graphql';
import SortedBillList from './SortedBillList/SortedBillList';
import {uniqBy} from "lodash";

interface BillChangesSectionProps {
  billsData: BillData[];
  sectionRef: React.RefObject<HTMLDivElement>;
}

const BillChangesSection: FC<BillChangesSectionProps> = ({ billsData, sectionRef }) => {
  const [timeFilter, setTimeFilter] = useState<string>(useTimeFilterFromUrl(false));
  const osBillIds = billsData.map((bill) => bill.billId);
  const billDataExists = useMemo(() => osBillIds.length > 0, [osBillIds]);
  const [filteredData, setFilteredData] = useState<BillsUpdatedSinceData[]>([]);

  const [getBillsWithUpdates] = useLazyQuery<BillsWithUpdatesData,BillsWithUpdatesInput>(GET_BILLS_WITH_UPDATES, {
    onCompleted(data) {
      const combined = uniqBy([
        ...data.billsWithUpdatedVersions,
        ...data.billsWithUpdatedStatus,
        ...data.billsWithUpdatedVotes,
        ...data.billsWithUpdatedActions
      ], e => e.osBillId)

      setFilteredData(combined);
    }
  });

  const handleSort = (e: ChangeEvent<HTMLInputElement>): void => {setTimeFilter(e.target.value)};
  const filterDate: Dayjs = useMemo(() => getFilterDate(timeFilter), [timeFilter]);
  const timeOptions = (Object.keys(TimeOptions) as (keyof typeof TimeOptions)[])
    .map((key) =>  TimeOptions[key])
    .filter((value) => value !== TimeOptions.DEFAULT);

  useEffect(() => {
    getBillsWithUpdates({
      variables: {
        inOsBillIds: osBillIds,
        actionsUpdatedSince: filterDate.format('YYYY-MM-DD'),
        versionsUpdatedSince: filterDate.format('YYYY-MM-DD'),
        votesUpdatedSince: filterDate.format('YYYY-MM-DD'),
        statusUpdatedSince: filterDate.format('YYYY-MM-DD'),
      }
    })
    // eslint-disable-next-line
  }, [filterDate, billDataExists]);

  return (
    <BasicCard className={styles.billChangesCard}>
      <CardTitle />
      <div className={styles.selectContainer} ref={sectionRef}>
        <GeneralSelect
          labelText={'Filter Bills by Time Last Updated'}
          hideLabel={true}
          data={timeOptions}
          id={'findBillsByTimeUpdated'}
          handleChange={handleSort}
          value={timeFilter}
        />
      </div>
      <SortedBillList unsortedBillData={filteredData} timeFilter={timeFilter} />
    </BasicCard>
  );
};

export function CardTitle() {
  return (
    <div className={styles.cardTitleContainer}>
      <h4 className={styles.cardTitleText}>Bill Changes</h4>
      <h4 className={styles.cardSubtitleText}> - Select a time frame to view recent bill changes.</h4>
      <div className={styles.basicLine}></div>
    </div>
  );
}

export default BillChangesSection;
