import {BillInterface} from "../../../types/Bill.interface";
import {BillsInput} from "../../../types/BillsInput.interface";
import {UpdateBillInputInterface} from "../../../types/UpdateBillInput.interface";

export interface ApprovedBillsQueryInterface {
  bills: BillInterface[]
  billsCount: number
}

export interface ApprovedBillsVariableInterface {
  bills: BillsInput
}

export interface UpdateBillVariableInterface {
  input: UpdateBillInputInterface;
}

export enum ApprovedBillActions {
  UPDATE = 'UPDATE',
  REMOVE = 'REMOVE',
}
