import { FC } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import styles from './GeneralSelect.module.scss';
import { FormHelperText, InputLabel } from '@mui/material';
import { colors } from 'src/theme/colors';
import chevronDown from '../../assets/svg/chevron-down.svg';

export interface GeneralSelectOption {
  value: string;
  label: string;
}

export interface GeneralSelectProps {
  data: string[] | GeneralSelectOption[];
  error?: boolean;
  helperText?: string;
  id: string;
  name?: string;
  labelText?: string;
  placeholder?: string;
  handleChange: (e: any) => void;
  value: string;
  isRequired?: boolean | false;
  disabled?: boolean;
  hideLabel?: boolean;
  padRight?: boolean;
}

const GeneralSelect: FC<GeneralSelectProps> = ({
  data,
  error,
  helperText,
  id,
  name,
  labelText,
  handleChange,
  value,
  isRequired,
  placeholder,
  disabled,
  hideLabel,
  padRight,
}) => {
  const mappedData = data.map((item, index) => {
    return (
      <MenuItem
        key={index}
        sx={{
          fontFamily: 'Lato',
          fontSize: '1rem',
        }}
        value={typeof item === 'string' ? item : item.value}
      >
        {typeof item === 'string' ? item : item.label}
      </MenuItem>
    );
  });

  return (
    <>
      <InputLabel
        id={`${id}-label`}
        htmlFor={`${id}-input-id`}
        sx={{
          fontFamily: 'Lato',
          marginBottom: '6px',
          color: '#242424',
          fontSize: '.9rem',
          display: hideLabel ? 'none' : 'block',
        }}
      >
        {labelText}
      </InputLabel>
      <FormControl style={{ width: '100%' }}>
        <Select
          error={error}
          id={id}
          labelId={`${id}-label`}
          label={labelText ? '' : placeholder}
          placeholder={placeholder}
          inputProps={{ id: `${id}-input-id` }}
          name={name ? name : id}
          value={value}
          onChange={(e) => handleChange(e)}
          disabled={disabled}
          classes={{
            iconOpen: styles.iconOpen,
          }}
          IconComponent={({ className }) => {
            className = className.replace('MuiSelect-iconOpen', '');
            return (
              <div className={`${className} ${styles.iconContainer}`}>
                <img src={chevronDown} alt="chevron down" />
              </div>
            );
          }}
          sx={{
            fontFamily: 'Lato',
            borderRadius: '3px',
            color: '#333333',
            fontSize: '1rem',
            backgroundColor: colors.white,
            paddingRight: padRight ? 2 : 'inherit',
          }}
          required={isRequired}
        >
          {mappedData}
        </Select>
        <FormHelperText
          sx={{ fontFamily: 'Lato', fontSize: 13, marginLeft: '5px', marginTop: '5px', color: '#d32f2f' }}
        >
          {helperText}
        </FormHelperText>
      </FormControl>
    </>
  );
};

export default GeneralSelect;
