import React, {Dispatch, FC} from 'react';
import {geoCentroid} from 'd3-geo';
import {ComposableMap, Geographies, Geography, Marker} from 'react-simple-maps';
import styles from './GradientMap.module.scss';
import {colors} from 'src/theme/colors';
import ReactTooltip from 'react-tooltip';
import Gradient from 'javascript-color-gradient';
import stateData from '../../const/stateData.json';
import {StateEnum} from "../../types/State.enum";
import {StateData} from "../../types/StateGeoData.interface";

const geoUrl = 'https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json';

const stateByGeoId = Object.fromEntries(Object.entries(stateData as StateData[]).map(([k, v]) => [v.geoId, v.id]))
const nameByGeoId = Object.fromEntries(Object.entries(stateData as StateData[]).map(([k, v]) => [v.geoId, v.name]))

const offsets = {
  VT: [50, -8],
  NH: [34, 2],
  MA: [30, -1],
  RI: [28, 2],
  CT: [35, 10],
  NJ: [34, 1],
  DE: [33, 0],
  MD: [47, 10],
  DC: [49, 21],
};

const getFillColor = (value: number, highestValue: number) => {
  return (value === 0 || value === undefined)
      ? colors.lightGray8
      : new Gradient()
          .setColorGradient('#c4e1b0', '3c691b')
          .setMidpoint(highestValue)
          .getColor(value);
};

interface GradientChartItem {
  state: StateEnum;
  value: number;
}

interface GradientChartProps {
  items: GradientChartItem[];
  highlightedState: StateEnum|undefined;
  onHighlight: Dispatch<StateEnum|undefined>;
  onTooltipChange: Dispatch<string>;
}

export const GradientMap: FC<GradientChartProps> = ({
  items,
  highlightedState,
  onHighlight,
  onTooltipChange
}) => {
  const highestValue = Math.max(...items.map(i => i.value));

  return (
    <ComposableMap
      className={styles.map}
      aria-label="Map of the United States"
      data-tip=""
      projection="geoAlbersUsa"
      onFocus={() => {ReactTooltip.hide()}}
    >
      <Geographies geography={geoUrl}>
        {({ geographies }) => (
          <>
            {geographies.map((geo, index) => {
              const currentState = items.find(i => i.state === stateByGeoId[geo.id]);
              const fillColor = getFillColor(currentState?.value ?? 0, highestValue)
              const tooltipContent = currentState
                  ? `${nameByGeoId[geo.id]}'s total: ${currentState.value}`
                  : '';
              const highlightedStyles = highlightedState === stateByGeoId[geo.id]
                  ? `${styles.state} ${styles.focus}`
                  : `${styles.state}`;

              return (
                <React.Fragment key={index}>
                  <Geography
                    tabIndex={-1}
                    onMouseEnter={() => {
                      onTooltipChange(tooltipContent);
                      onHighlight(undefined);
                    }}
                    onMouseLeave={() => {
                      onTooltipChange('');
                    }}
                    key={geo.rsmKey}
                    stroke={colors.white}
                    geography={geo}
                    fill={fillColor}
                    className={highlightedStyles}
                  />
                </React.Fragment>
              );
            })}
            {geographies.map((geo) => {
              const centroid = geoCentroid(geo);
              const currentState = items.find(i => i.state === stateByGeoId[geo.id]);
              const hasMarker = currentState && centroid[0] > -160 && centroid[0] < -67 && !Object.hasOwn(offsets, currentState.state)

              return (
                <g key={geo.rsmKey + '-name'}>
                  {hasMarker ? <Marker coordinates={centroid}></Marker> : null}
                </g>
              );
            })}
          </>
        )}
      </Geographies>
    </ComposableMap>
  );
};
