import { useParams } from 'react-router-dom';
import TimeOptions from '../types/TimeOptions.enum';

function useTimeFilterFromUrl(justGetKey: boolean): string {
  const params = useParams();
  const timeFilterParam = params.timeFilter?.toUpperCase();
  const timeOptionKey = (Object.keys(TimeOptions) as (keyof typeof TimeOptions)[]).find(
    (key) => key === timeFilterParam,
  );
  if (!timeOptionKey) return '';
  if (justGetKey) return timeOptionKey;
  return TimeOptions[timeOptionKey];
}

export default useTimeFilterFromUrl;
