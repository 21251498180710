import { gql } from '@apollo/client';

export const ADD_BILL_MYTRACKER = gql`
  mutation ($userId: Int!, $billId: Int!) {
    addTrackedBill(userId: $userId, billId: $billId) {
      id
      billId
      autoTime
    }
  }
`;

export const DELETE_BILL_MYTRACKER = gql`
  mutation ($userId: Int!, $billId: Int!) {
    deleteTrackedBill(userId: $userId, billId: $billId)
  }
`;
