import { gql } from '@apollo/client';

export const APROVED_BILLS_QUERY = gql`
  query ($bills: EprcBillsInput) {
    billsCount(input: $bills)
    bills(input: $bills) {
      billId
      osStatus
      yearFiled
      osBillId
      osState
      number
      osSession
      osSessionId
      osChamber
      title
      summary
      policyCategory
      subcategory
      autoTimeOsUpdate
      spotPolicies {
        policyId
      }
    }
  }
`;

export const UPDATE_BILL = gql`
  mutation ($input: UpdateBillInput!) {
    updateBill(input: $input) {
      billId
      yearFiled
      autoTimeOsUpdate
      osBillId
      osState
      number
      osSession
      title
      summary
      osChamber
      policyCategory
      subcategory
      spotPolicies {
        policyId
      }
    }
  }
`;
