/* eslint-disable no-restricted-globals */
import React, { useState, FC, useContext } from 'react';
import { GlobalContext } from 'src/hooks/useGlobalContext';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import { NavigationItems } from 'src/types/NavigationItems.interface.js';
import styles from './MenuItemsMobile.module.scss';
import chevronUp from 'src/assets/svg/chevron-up.svg';
import chevronDown from 'src/assets/svg/chevron-down.svg';
import { useNavigate } from 'react-router-dom';
import { colors } from 'src/theme/colors';
export interface MenuItemsProps {
  item: NavigationItems;
}

const MenuItemMobile: FC<MenuItemsProps> = ({ item }) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { userData } = useContext(GlobalContext);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };
  return !item.subItems ? (
    <div className={styles.navLinksContainer}>
      <ListItem
        button
        sx={{ backgroundColor: colors.blackTransparent, fontFamily: 'Lato' }}
        id={styles.listItem}
        onClick={
          item.to
            ? () => {
                navigate(item.to || '/');
              }
            : () => {
                location.href = item.href || '/';
              }
        }
      >
        <ListItemText
          primary={`${item.depth ? '- ' : ''}${item.title}`}
          className={(item.depth || 0) === 2 ? styles.hoverYellow : undefined}
          sx={{
            backgroundColor: (item.depth || 0) === 2 ? colors.yellow1 : undefined,
            borderRadius: (item.depth || 0) === 2 ? '5px' : undefined,
            margin: (item.depth || 0) === 2 ? '0px 20px' : 0,
          }}
          primaryTypographyProps={{
            fontFamily: 'Lato',
            color: (item.depth || 0) === 2 ? colors.black : undefined,
            fontWeight: (item.depth || 0) === 2 ? 'bold' : undefined,
            paddingLeft: item.depth ? '10px' : undefined,
            padding: (item.depth || 0) === 2 ? '5px 10px' : undefined,
            fontSize: (item.depth || 0) === 2 ? '.9rem' : undefined,
          }}
        />
      </ListItem>
    </div>
  ) : (
    <div className={styles.navLinksContainer}>
      <ListItem
        button
        onClick={handleClick}
        sx={{ backgroundColor: colors.blackTransparent, fontFamily: 'Lato' }}
        id={styles.listItem}
      >
        <ListItemText
          primary={`${item.depth ? '- ' : ''}${item.title}`}
          primaryTypographyProps={{ fontFamily: 'Lato', paddingLeft: item.depth ? '10px' : undefined }}
        />
        {open ? (
          <img src={chevronUp} className={styles.chevronIcon} alt="" />
        ) : (
          <img src={chevronDown} className={styles.chevronIcon} alt="" />
        )}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {item.subItems.map((child, key) => {
            if (!child.role || (child.role && child.role.indexOf(userData.role) > -1))
              return <MenuItemMobile key={key} item={child} />;
            return <></>;
          })}
        </List>
      </Collapse>
    </div>
  );
};

export default MenuItemMobile;
