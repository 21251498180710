export enum TextTypeNameEnum {
  INTRODUCED = 'Introduced',
  COMMITTEE_SUBSTITUTE = 'Committee Substitute',
  AMENDED = 'Amended',
  ENGROSSED = 'Engrossed',
  ENROLLED = 'Enrolled',
  CHAPTERED = 'Chaptered',
  FISCAL_NOTE = 'Fiscal Note',
  ANALYSIS = 'Analysis',
  DRAFT = 'Draft',
  CONFERENCE_SUBSTITUTE = 'Conference Substitute',
  PREFILED = 'Prefiled',
  VETO_MESSAGE = 'Veto Message',
  VETO_RESPONSE = 'Veto Response',
  SUBSTITUTE = 'Substitute',
}
