import { FC } from 'react';
import styles from './MapSkipLink.module.scss';

interface MapSkipLinkProps {
  id: string;
  to: string;
  text: string;
}

export const MapSkipLink: FC<MapSkipLinkProps> = ({ id, to, text }) => {
  return (
    <a className={styles.skipMapOption} href={to} id={id}>
      {text}
    </a>
  );
};
