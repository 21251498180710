import React, { FC } from 'react';
import styles from '../BillCard.module.scss';
import closeIcon from '../../../assets/svg/close-icon.svg';
import greenGrid from '../../../assets/svg/color-grid-green.svg';
import { BillData } from "../../../types/BillData.interface";

interface Props {
  isAdmin: boolean;
  isTracked: boolean;
  setModalOpen: React.Dispatch<boolean>;
  handleAddBill: (e: React.MouseEvent<HTMLButtonElement>) => void;
  isInMyTracker?: boolean;
  billData?: BillData;
}

export const AddOrRemoveBillButton: FC<Props> = ({
  isAdmin,
  isTracked,
  setModalOpen,
  handleAddBill,
  billData,
  isInMyTracker,
}) => {
  if (!billData) return <></>;
  return (
    <>
      {isAdmin ? (
        <>
          {billData.cela ? (
            <button className={styles.billTrack} onClick={() => setModalOpen(true)}>
              <img src={closeIcon} alt="" className={styles.closeIcon} />
              <span className={styles.billTrackText}>Remove CELA Bill</span>
            </button>
          ) : (
            <button className={styles.billTrack} onClick={(e) => handleAddBill(e)}>
              <img src={greenGrid} alt="" className={styles.greenGrid} />
              <span className={styles.billTrackText}>Add CELA Bill</span>
            </button>
          )}
        </>
      ) : (
        <>
          {isInMyTracker || isTracked ? (
            <button className={styles.billTrack} onClick={() => setModalOpen(true)}>
              <img src={closeIcon} alt="" className={styles.closeIcon} />
              <span className={styles.billTrackText}>Remove from myTracker</span>
            </button>
          ) : (
            <button className={styles.billTrack} onClick={(e) => handleAddBill(e)}>
              <img src={greenGrid} alt="" className={styles.greenGrid} />
              <span className={styles.billTrackText}>Track this Bill</span>
            </button>
          )}
        </>
      )}
    </>
  );
};
