import React, {FC, useEffect, useState} from 'react';
import {makeStyles} from '@mui/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import styles from './NationalDistributionTabs.module.scss';
import {colors, gradients, policyColors, statusColors} from 'src/theme/colors.js';
import useScreenSize from 'src/hooks/useScreenSize';
import Breakpoint from 'src/types/BreakPoint.enum';
import {StackedBarChart} from 'src/components/StackedBarChart/StackedBarChart';
import {desktopTab, largeDesktopTab, mobileTab} from 'src/theme/tabSizing';
import {useQuery} from '@apollo/client';
import MapSection from 'src/components/MapSection/MapSection';
import {NationalBillDistribution} from './NationalDistributionTabs.interface';
import {BillDistributionInterface} from "../../../types/BillDistribution.interface";
import {StateEnum} from "../../../types/State.enum";
import {PolicyEnum} from "../../../types/Policy.enum";
import {StatusEnum} from "../../../types/Status.enum";
import {NATIONAL_TOTALS_QUERY} from "./NationalDistributionTabs.graphql";
import {policyText} from "../../../const/policyText";
import {statusText} from "../../../const/statusText";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const useStyles = makeStyles((theme: any) => ({
  tab: {
    '&:hover,&:focus-visible': {
      textDecoration: 'underline',
      color: colors.white,
      background: gradients.medGray,
      fontWeight: '600',
    },

    '&.Mui-selected': {
      background: colors.lightGray7,
      color: colors.darkGray1,
      fontWeight: '600',
      '&:hover': {
        textDecoration: 'underline',
        color: colors.darkGray1,
      },
      '&:focus-visible': {
        background: gradients.lightGray,
      },
    },
  },
}));

function TabPanel(props: TabPanelProps) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      className={styles.tabPanel}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{p: 3}}>{children}</Box>}
    </div>
  );
}

const a11yProps = (index: number) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});

export const NationalDistributionTabs: FC = () => {
  const [value, setValue] = useState(0);
  const {screenSizeIsAtLeast} = useScreenSize();
  const classes = useStyles();
  const tabClasses = {root: classes.tab};
  const tabSize = screenSizeIsAtLeast[Breakpoint.BASE] ? desktopTab : mobileTab;
  const {data} = useQuery<NationalBillDistribution>(NATIONAL_TOTALS_QUERY);

  const [policyDistribution, setPolicyDistribution] = useState<BillDistributionInterface[]>([]);
  const [statusDistribution, setStatusDistribution] = useState<BillDistributionInterface[]>([]);
  const [stateDistribution, setStateDistribution] = useState<BillDistributionInterface[]>([]);

  useEffect(() => {
    setPolicyDistribution(data?.byPolicy ?? []);
    setStatusDistribution(data?.byStatus ?? []);
    setStateDistribution(data?.byState ?? []);
  }, [data]);

  const stateItems = stateDistribution.map(v => ({
    state: v.group as StateEnum,
    value: v.count
  }))

  const policyItems = policyDistribution.map(v => ({
    id: v.group,
    label: policyText[v.group as PolicyEnum],
    color: policyColors[v.group as PolicyEnum],
    value: v.count,
  })).sort((f, s) => f.label.localeCompare(s.label))

  const statusItemsLookup = Object.fromEntries(statusDistribution
    .map(v => [v.group, {
      id: v.group,
      label: statusText[v.group as StatusEnum],
      color: statusColors[v.group as StatusEnum],
      value: v.count,
    }]))

  const statusItems = [
    StatusEnum.INTRODUCED,
    StatusEnum.PASSED_ONE_CHAMBER,
    StatusEnum.PASSED_BOTH_CHAMBERS,
    StatusEnum.ENACTED,
    StatusEnum.FAILED,
    StatusEnum.VETOED,
  ]
    .filter(k => Object.hasOwn(statusItemsLookup, k))
    .map(s => statusItemsLookup[s]);

  const tabStyles = {
    background: gradients.lightGray,
    color: colors.black,
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '8px',
    minWidth: screenSizeIsAtLeast[Breakpoint.LG] ? largeDesktopTab.tabWidth : tabSize.tabWidth,
    marginRight: '8px',
    minHeight: screenSizeIsAtLeast[Breakpoint.LG] ? '60px' : '50px',
    fontFamily: 'Lato',
    textTransform: 'none',
    fontSize: screenSizeIsAtLeast[Breakpoint.LG] ? largeDesktopTab.fontSize : tabSize.fontSize,
    '&:hover,&:focus-visible': {
      textDecortation: 'underline',
    },
  };

  return (
    <>
      <Box sx={{width: '100%'}}>
        <Box sx={{borderColor: 'divider'}}>
          <Tabs
            value={value}
            textColor="inherit"
            TabIndicatorProps={{
              style: {
                background: colors.medGray1,
                width: screenSizeIsAtLeast[Breakpoint.LG]
                  ? largeDesktopTab.tabHighlightWidth
                  : tabSize.tabHighlightWidth,
                marginLeft: '30px',
                height: '1px',
              },
            }}
            onChange={(e, newValue: number) => {
              setValue(newValue)
            }}
            aria-label="National Distribution of Bills Tabs"
          >
            <Tab label="Across States" disableFocusRipple classes={tabClasses} {...a11yProps(0)}
                 sx={tabStyles}/>
            <Tab
              label="Across Policy Categories"
              disableFocusRipple
              classes={tabClasses}
              {...a11yProps(1)}
              sx={tabStyles}
            />
            <Tab label="Across Statuses" disableFocusRipple classes={tabClasses} {...a11yProps(2)}
                 sx={tabStyles}/>
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <div className={styles.policyCategoryMapSection}>
            <MapSection
              items={stateItems}
              className={styles.visualizationBordered}/>
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <StackedBarChart
            title={'Distributions of Bills Across Policy Categories'}
            description={'Select a policy category to see its bill count.'}
            isCenter={true}
            items={policyItems}/>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <StackedBarChart
            title={'Distributions of Bills Across Statuses'}
            description={'Select a bill status to see its bill count.'}
            isCenter={true}
            items={statusItems}/>
        </TabPanel>
      </Box>
    </>
  );
};

