import { FC } from 'react';
import styles from './AccordionDetails.module.scss';
import { StatusChanges } from '../../../types/types';
import BillDetailsWrapper from './BillDetailsWrapper';
import {statusText} from "../../../../../const/statusText";
import {StatusEnum} from "../../../../../types/Status.enum";

interface StatusDetailsProps {
  currentStatus: StatusEnum;
  statusChanges: StatusChanges;
  lastUpdated?: string;
}

const createListItem = (date: string | null, status: string) => (<li>{date} - {status}</li>);

const StatusDetails: FC<StatusDetailsProps> = ({ statusChanges, currentStatus, lastUpdated }) => {
  const { createdAt, datePassedLower, datePassedUpper, dateSigned } = statusChanges;
  const statusCount = Object.values(statusChanges).filter(item => item != null).length;

  return (
    <BillDetailsWrapper billDetailName="Changes" listCount={statusCount}>
      <ul className={styles.detailContent}>
        {(currentStatus === statusText.VETOED || currentStatus === statusText.FAILED) && lastUpdated && createListItem(lastUpdated, currentStatus)}
        {dateSigned && createListItem(dateSigned, statusText.ENACTED)}
        {datePassedUpper && createListItem(datePassedUpper, statusText.PASSED_BOTH_CHAMBERS)}
        {datePassedLower && createListItem(datePassedLower, statusText.PASSED_ONE_CHAMBER)}
        {createdAt && createListItem(createdAt, statusText.INTRODUCED)}
      </ul>
    </BillDetailsWrapper>
  );
}

export default StatusDetails;
