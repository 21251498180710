import { FC, useContext, useState } from 'react';
import styles from './SiteFooter.module.scss';
import SiteLogo from '../../assets/logos/cnee-text-logo.png';
import LegiScanLogo from '../../assets/logos/legiscan-logo.png';
import footerTransition from '../../assets/svg/footer-transition.svg';
import { useQuery } from '@apollo/client';
import { FOOTER_QUERY } from './SiteFooter.graphql';
import LearnMoreLinks from './LearnMoreLinks/LearnMoreLinks';
import SocialMediaLinks from './SocialMediaLinks/SocialMediaLinks';
import GeneralLinkBody from '../GeneralLinkBody/GeneralLinkBody';
import AdminLoginModal from '../AdminLoginModal/AdminLoginModal';
import contentfulClient from 'src/common/apolloClient';
import { GlobalContext } from 'src/hooks/useGlobalContext';

const SiteFooter: FC = () => {
  const { authenticated } = useContext(GlobalContext);
  const { data } = useQuery(FOOTER_QUERY, { client: contentfulClient });

  const [modalOpen, setModalOpen] = useState(false);
  let learnMoreLinks = [];
  let socialMediaLinks = [];
  let address, copyrightYear, copyrightText, copyrightLink;

  const checkLogin = () => {
    return authenticated ? window.scrollTo({ top: 0, behavior: 'smooth' }) : setModalOpen(true);
  };

  if (data) {
    let response = data?.footerTextCollection?.items[0];
    address = response.address;
    copyrightYear = response.copyrightYear;
    copyrightText = response.copyrightText;
    copyrightLink = response.copyrightLink;
    learnMoreLinks = response.learnMoreLinksCollection?.items;
    socialMediaLinks = response.socialMediaLinksCollection?.items;
  }

  return (
    <>
      <section className={styles.footerSection}>
        <img src={footerTransition} alt="" className={styles.footerTransition} />
        <section className={styles.footerContentSection}>
          <div className={styles.siteProviderInfo}>
            <img src={SiteLogo} alt="CNEE logo"></img>
            {data && (
              <div>
                <div>&copy;{copyrightYear}{' '} <a className={styles.copyrightLink} href={copyrightLink}>{copyrightText}</a></div>
                <div>{address}</div>
              </div>
            )}
            <div>
              <a href={'https://www.govwebworks.com/'} target={'_blank'} rel={'noreferrer'}>Developed by GovWebworks</a>
            </div>
            <div>
              <div>Powered by:</div>
              <a href="https://legiscan.com/"><img src={LegiScanLogo} alt="CNEE logo"></img></a>
            </div>
          </div>
          <section className={styles.learnMoreSection}>
            <span className={styles.listHeading}>Learn More</span>
            <LearnMoreLinks links={learnMoreLinks} />
            <GeneralLinkBody onClick={() => checkLogin()}>Admin Login</GeneralLinkBody>
          </section>
          <section className={styles.socialMediaSection}>
            <span className={styles.listHeading}>Stay in touch with us!</span>
            <SocialMediaLinks linkInfo={socialMediaLinks} />
          </section>
        </section>
      </section>
      <AdminLoginModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
    </>
  );
};

export default SiteFooter;
