import { gql } from '@apollo/client';

export const REMOVE_CELA_BILL = gql`
  mutation ($billId: Int!) {
    removeCelaBill(billId: $billId)
  }
`;

export const ADD_CELA_BILL = gql`
  mutation ($billId: Int!) {
    addCelaBill(billId: $billId)
  }
`;
