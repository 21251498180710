import { FC } from 'react';
import CreatePhraseForm from './CreatePhraseForm';
import GeneralModal from 'src/components/GeneralModal/GeneralModal';

interface CreatePhraseModalProps {
  onClose: () => void;
  refetchBillImportFilters: () => void;
  modalOpen: boolean;
}

const CreatePhraseModal: FC<CreatePhraseModalProps> = ({ onClose, modalOpen, refetchBillImportFilters }) => {
  return (
    <GeneralModal onClose={onClose} modalOpen={modalOpen}>
      <CreatePhraseForm handleClose={onClose} refetchBillImportFilters={refetchBillImportFilters} />
    </GeneralModal>
  );
};

export default CreatePhraseModal;
