import React, {ChangeEvent, FormEvent, useEffect, useState} from 'react';
import {Box, Button, Divider, FormControlLabel, FormGroup, Grid, InputLabel, Typography} from '@mui/material';
import Input from 'src/components/Input/Input';
import GeneralSwitch from 'src/components/GeneralSwitch/GeneralSwitch';
import {validateEmail} from "../../../../utils/validateEmail";
import {UserData} from "../../../../types/UserData.interface";
import {ThemeProvider} from "@mui/material/styles";
import buttonTheme from "../../../../theme/buttonTheme";
import Select from "@mui/material/Select";
import {UserRole} from "../../../../types/UserRole.enum";
import {roleText} from "../../../../const/roleText";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

interface EditableUser {
  id: string
  active: boolean
  firstName: string
  lastName: string
  email: string
  role: string
  newPassword?: string
  confirmNewPassword?: string
}

interface EditUserFormProps {
  user?: UserData
  onSubmit: (user: EditableUser) => void
  onDelete: (user: UserData) => void
}

interface EditUserFormError {
  firstName?: string
  lastName?: string
  email?: string
  password?: string
}

const labelStyles = {
  fontSize: '16px',
  color: '#4A4A4A',
  fontFamily: 'Lato',
  textAlign: 'right',
};

const validateForm = (form: EditableUser) => {
  const errors = []

  if (!validateEmail(form.email)) {
    errors.push({ email: 'Email not valid' })
  }

  if (form.newPassword !== form.confirmNewPassword) {
    errors.push({ password: 'Passwords need to match' })
  }

  if (!form.firstName) {
    errors.push({ firstName: 'First name is required' })
  }

  if (!form.lastName) {
    errors.push({ lastName: 'Last name is required' })
  }

  return errors
}

export const EditUserForm = ({
  user,
  onSubmit,
  onDelete,
}: EditUserFormProps) => {
  const [formErrors, setFormErrors] = useState<EditUserFormError>({});
  const [formState, setFormState] = useState<EditableUser>({
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    role: '',
    active: true,
    newPassword: '',
    confirmNewPassword: '',
  });

  useEffect(() => {
    setFormState({
      id: user?.id ?? '',
      firstName: user?.firstName ?? '',
      lastName: user?.lastName ?? '',
      email: user?.email ?? '',
      role: user?.role ?? UserRole.PUBLIC,
      active: user?.active ?? true,
      newPassword: '',
      confirmNewPassword: '',
    })
  }, [user]);

  const handleChange = (name: string, value: unknown): void => {
    setFormState({ ...formState, [name]: value});
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setFormErrors({})

    const errors = validateForm(formState)

    if (errors.length > 0) {
      setFormErrors(errors.reduce((c, v) => ({ ...c, ...v }), {}))
    } else {
      onSubmit(formState)
    }
  }

  return (
    <Box component="form" onSubmit={handleSubmit} autoComplete="off">
      <Typography component="h1" textAlign="center" sx={{ fontWeight: 300, fontSize: '32px', fontFamily: 'Lato' }}>
        {user ? 'Edit User Account' : 'Add New User Account'}
      </Typography>
      <Divider sx={{ my: 3, borderBottomColor: '#979797' }} />
      <Grid container spacing={3}>
        <Grid item container xs={12} spacing={3}>
          <Grid item xs={4} alignSelf="center">
            <InputLabel sx={{ ...labelStyles }} htmlFor="firstName">
              User First Name
            </InputLabel>
          </Grid>
          <Grid item xs={8}>
            <Input
              id="firstName"
              name="firstName"
              handleChange={(e) => handleChange(e.target.id, e.target.value)}
              type="text"
              value={formState.firstName}
              error={Boolean(formErrors.firstName)}
              helperText={formErrors.firstName}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item container xs={12} spacing={3}>
          <Grid item xs={4} alignSelf="center">
            <InputLabel sx={{ ...labelStyles }} htmlFor="lastName">
              User Last Name
            </InputLabel>
          </Grid>
          <Grid item xs={8}>
            <Input
              id="lastName"
              name="lastName"
              handleChange={(e) => handleChange(e.target.id, e.target.value)}
              type="text"
              value={formState.lastName}
              error={Boolean(formErrors.lastName)}
              helperText={formErrors.lastName}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item container xs={12} spacing={3}>
          <Grid item xs={4} alignSelf="center">
            <InputLabel sx={{ ...labelStyles }} htmlFor="email">
              User Email
            </InputLabel>
          </Grid>
          <Grid item xs={8}>
            <Input
              id="email"
              name="email"
              handleChange={(e) => handleChange(e.target.id, e.target.value)}
              type="email"
              value={formState.email}
              error={Boolean(formErrors.email)}
              helperText={formErrors.email}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item container xs={12} spacing={3}>
          <Grid item xs={4} alignSelf="center">
            <InputLabel sx={{ ...labelStyles }} htmlFor="role">
              User Role
            </InputLabel>
          </Grid>
          <Grid item xs={8}>
            <FormControl fullWidth>
              <Select
                id="role"
                name="role"
                value={formState.role ?? ''}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              >
                {Object.entries(roleText).map(([value, label]) => (
                  <MenuItem key={value} value={value}>{label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {!user && (
          <>
            <Grid item container xs={12} spacing={3}>
              <Grid item xs={4} alignSelf="center">
                <InputLabel sx={{ ...labelStyles }} htmlFor="newPassword">
                  Password
                </InputLabel>
              </Grid>
              <Grid item xs={8}>
                <Input
                  id="newPassword"
                  name="newPassword"
                  handleChange={(e) => handleChange(e.target.id, e.target.value)}
                  type="password"
                  value={formState.newPassword ?? ''}
                  error={Boolean(formErrors.password)}
                  helperText={formErrors.password}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid item container xs={12} spacing={3}>
              <Grid item xs={4} alignSelf="center">
                <InputLabel sx={{ ...labelStyles }} htmlFor="confirmNewPassword">
                  Confirm Password
                </InputLabel>
              </Grid>
              <Grid item xs={8}>
                <Input
                  id="confirmNewPassword"
                  name="confirmNewPassword"
                  handleChange={(e) => handleChange(e.target.id, e.target.value)}
                  type="password"
                  value={formState.confirmNewPassword ?? ''}
                  error={Boolean(formErrors.password)}
                  helperText={formErrors.password}
                  fullWidth
                />
              </Grid>
            </Grid>
          </>
        )}
        {user && (
          <Grid item container xs={12} spacing={3}>
            <Grid item xs={4} alignSelf="center">
              <Typography sx={{ ...labelStyles }}>Account Status</Typography>
            </Grid>
            <Grid item xs={4} alignSelf="center">
              <FormGroup>
                <FormControlLabel
                  sx={{ ml: 0 }}
                  control={
                    <GeneralSwitch
                      id="active"
                      name="active"
                      checked={formState.active}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e.target.id, e.target.checked)}
                      inputProps={{ 'aria-label': 'controlled ' }}
                    />
                  }
                  label={formState.active ? 'Active' : 'Inactive'}
                />
              </FormGroup>
            </Grid>
          </Grid>
        )}
        <ThemeProvider theme={buttonTheme}>
          <Grid item container xs={12} spacing={3} justifyContent={'space-between'}>
            <Grid item>
              {user && (
                <Button variant="outlined" color='error' onClick={() => {
                  onDelete(user)
                }}>
                  Delete Account
                </Button>
              )}
            </Grid>
            <Grid item>
              <Button variant="contained" type="submit">
                Save
              </Button>
            </Grid>
          </Grid>
        </ThemeProvider>
      </Grid>
    </Box>
  )
};
