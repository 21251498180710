import React, { FC } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { ImportFilter } from '../types/ImportFilter.interface';
import AdminButton from '../../../components/AdminButton/AdminButton';
import TablePagination from '@mui/material/TablePagination';
import { FilterTableHead } from './FilterTableHead';
import { useMutation } from '@apollo/client';
import { DELETE_IMPORT_FILTER } from './FiltersTable.graphql';
import { DeleteImportFilterVar, RawDeletedImportFilterData } from './FiltersTable.interface';
import { toast } from 'react-toastify';
import { SortDirectionEnum } from '../../../types/SortDirection.enum';
import {tableTheme} from "../../../theme/tableStyling";
import {ThemeProvider} from "@mui/material/styles";

interface FilterTableProps {
  filters: ImportFilter[];
  count: number;
  pageNumber: number;
  pageSize: number;
  sortColumn: keyof ImportFilter;
  refetchBillImportFilters: () => void;
  onPageNumberChange: (n: number) => void;
  onPageSizeChange: (n: number) => void;
  setSortColumn: (n: keyof ImportFilter) => void;
  setSortDirection: (n: SortDirectionEnum) => void;
}

const FiltersTable: FC<FilterTableProps> = ({
  filters,
  pageNumber,
  pageSize,
  sortColumn,
  refetchBillImportFilters,
  setSortColumn,
  setSortDirection,
  onPageNumberChange,
  onPageSizeChange,
  count,
}) => {
  const [deleteBillImportFilter] = useMutation<RawDeletedImportFilterData, DeleteImportFilterVar>(
    DELETE_IMPORT_FILTER,
    {
      onCompleted(res) {
        toast.success(`${res.deleteBillImportFilter}`, {
          position: toast.POSITION.TOP_CENTER,
        });
        refetchBillImportFilters();
      },
      onError(error) {
        toast.error('There was an error with your request', {
          position: toast.POSITION.TOP_CENTER,
        });
      },
    },
  );

  const handleDeleteBillImportFilter = (input: number) => {
    const deleteImportFilterVar: DeleteImportFilterVar = { id: input };
    deleteBillImportFilter({ variables: deleteImportFilterVar });
  };
  return (
    <ThemeProvider theme={tableTheme}>
      <TableContainer>
      <Table aria-label="legiscan filter table">
        <FilterTableHead
          currentSortColumn={sortColumn}
          setSortColumn={setSortColumn}
          setSortDirection={setSortDirection}
        />
        <TableBody>
          {filters?.map((row, index) => (
            <TableRow key={index}>
              <TableCell>{row.filterType}</TableCell>
              <TableCell>{row.phrase}</TableCell>
              <TableCell>{row.autoDateUpdate}</TableCell>
              <TableCell>{row.user}</TableCell>
              <TableCell scope="row" align="center">
                <AdminButton text={'Remove'} onClick={() => handleDeleteBillImportFilter(row.id)} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        SelectProps={{
          inputProps: {
            'aria-label': 'rows per page',
          },
          native: true,
        }}
        component="div"
        count={count}
        rowsPerPage={pageSize}
        page={pageNumber}
        onPageChange={(e, n) => onPageNumberChange(n)}
        onRowsPerPageChange={(e) => onPageSizeChange(parseInt(e.target.value, 10))}
      />
    </TableContainer>
    </ThemeProvider>
  );
};

export default FiltersTable;
