import React, { DetailedHTMLProps, FC, HTMLAttributes } from 'react';
import Heading from '../Heading/Heading';
import HeadingStyle from '../Heading/HeadingStyle.enum';
import styles from './GeneralInfoCard.module.scss';

export interface GeneralInfoCardProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement> {
  title?: string;
}

const GeneralInfoCard: FC<GeneralInfoCardProps> = ({ title, children, ...rest }) => {
  return (
    <div className={styles.cardContainer}>
      <Heading headingStyle={HeadingStyle.CONTAINER_TITLE}>{title}</Heading>
      <Heading headingStyle={HeadingStyle.CONTAINER_SUBTITLE}>
        <span className={styles.alignedChildren}>{children}</span>
      </Heading>
    </div>
  );
};

export default GeneralInfoCard;
