import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import styles from './BillInnerCard.module.scss';
import { policyIcon } from 'src/const/policyIcon';
import { statusIcon } from 'src/const/statusIcon';
import { COMPANION_BILLS_QUERY } from './BillInnerCard.graphql';
import { useQuery } from '@apollo/client';
import { TopFiveSponsorsList } from '../../../components/TopFiveSponsorsList/TopFiveSponsorsList';
import GeneralLinkBody from 'src/components/GeneralLinkBody/GeneralLinkBody';
import { BillData } from "../../../types/BillData.interface";
import { BillInterface } from 'src/types/Bill.interface';
import {
  CompanionBillsQueryInterface,
  CompanionBillsQueryVariableInterface,
} from '../../../types/CompanionBills.interface';
import {policyText} from "../../../const/policyText";
import {statusText} from "../../../const/statusText";

interface BillInnerCardProps {
  currentBillDetails: BillData;
  scrollToSponsors: () => void;
}

const BillInnerCard: FC<BillInnerCardProps> = ({ currentBillDetails, scrollToSponsors }) => {
  const params = useParams();

  let currentBillId = params.id
    ? Number.parseInt(params.id, 10)
    : 0;

  const { data: dataCompanionBills } = useQuery<CompanionBillsQueryInterface, CompanionBillsQueryVariableInterface>(
    COMPANION_BILLS_QUERY,
    {
      fetchPolicy: 'network-only',
      variables: {
        companionsOf: currentBillDetails.id,
      },
    },
  );

  let companionBills: BillInterface[] = [];

  if (dataCompanionBills) {
    const billsArray = dataCompanionBills.companionBills.filter(
      (bill: BillInterface, i: number) =>
        i ===
        dataCompanionBills.companionBills.findIndex((duplicate: BillInterface) => bill.billId === duplicate.billId),
    );
    companionBills = billsArray.filter((bill: BillInterface) => bill.osBillId !== currentBillId).reverse();
    companionBills.sort((a: BillInterface, b: BillInterface) => Number.parseInt(a.billId, 10) - Number.parseInt(b.billId, 10));
  }

  return (
    <section className={styles.card}>
      <div className={styles.cardInfo} tabIndex={0}>
        <div className={styles.billOuterLeft}>
          <span className={styles.sectionTitle}>Last updated on</span>
          <span>{currentBillDetails?.lastUpdated}</span>
        </div>
        <div className={styles.billInner}>
          <span className={styles.sectionTitle}>Primary Sponsors</span>
          <div className={styles.sponsors}>
            <TopFiveSponsorsList sponsorsObject={currentBillDetails?.sponsors} scrollToSponsors={scrollToSponsors} />
          </div>
        </div>
        <div className={`${styles.billInner} ${styles.overflow}`}>
          <span className={styles.sectionTitle}>Policy Category</span>
          <div className={styles.billIconContainer}>
            <img src={policyIcon[currentBillDetails?.policyCategory]} alt="" className={styles.billIcon} />
            <span>{capitalize(policyText[currentBillDetails?.policyCategory])}</span>
          </div>
        </div>
        <div className={styles.billInner}>
          <span className={styles.sectionTitle}>Status</span>
          <div className={styles.billIconContainer}>
            <img src={statusIcon[currentBillDetails?.status]} alt="" className={styles.billIcon} />
            <span>{capitalize(statusText[currentBillDetails.status])}</span>
          </div>
        </div>
        <div className={styles.billOuterRight}>
          <span className={styles.sectionTitle}>Companion Bill(s)</span>
          {companionBills.length
            ? companionBills
              .filter((bill: BillInterface) => bill.osBillId !== currentBillDetails?.billId)
              .map((bill: BillInterface) => {
                return (
                  <GeneralLinkBody
                    key={bill.osBillId}
                    navigateTo={`/bill-details/${bill.osBillId}`}
                    className={styles.companionBillLink}
                  >
                    {bill.state} {bill.number}
                  </GeneralLinkBody>
                );
              })
            : 'None'}
        </div>
      </div>
    </section>
  );
};

export default BillInnerCard;

function capitalize(str: string | undefined) {
  if (str === undefined) return '';
  return str.split(' ').map(s => s.charAt(0).toUpperCase() + s.slice(1)).join(' ');
}
