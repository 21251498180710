import React, {ReactElement} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import {tableTheme} from 'src/theme/tableStyling';
import { TableHead, TableSortLabel } from '@mui/material';
import { colors } from '../../theme/colors';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { SortDirectionEnum } from '../../types/SortDirection.enum';
import {ThemeProvider} from "@mui/material/styles";

interface AdminTableColumn<T> {
  id: Extract<keyof T, string>
  label: string
}

interface AdminTableProps<T> {
  title: string
  columns: AdminTableColumn<T>[],
  columnRender?: (k: keyof T, v: T[Extract<keyof T, string>]) => string,
  data: T[]
  count: number
  pageNumber: number
  pageSize: number
  sortColumn: string
  sortDirection: SortDirectionEnum
  onPageNumberChange: (n: number) => void
  onPageSizeChange: (n: number) => void
  onSortChange: (column: string, direction: SortDirectionEnum) => void
  actions: (data: T) => ReactElement<any, any>
}

export const AdminTable = <T,> ({
  title,
  columns,
  columnRender = (k, v) => `${v}`,
  data,
  count,
  pageSize,
  pageNumber,
  sortColumn,
  sortDirection,
  onPageNumberChange,
  onPageSizeChange,
  onSortChange,
  actions
}: AdminTableProps<T>) => {
  // MUI tables use lower-case asc/desc
  const tableSortDirection = sortDirection === SortDirectionEnum.ASC ? 'asc' : 'desc';

  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    const isAsc = sortColumn === property && tableSortDirection === 'asc';
    onSortChange(property, isAsc ? SortDirectionEnum.DESC : SortDirectionEnum.ASC);
  };

  return (
    <ThemeProvider theme={tableTheme}>
      <TableContainer>
        <Table size="small" aria-label={title}>
          <TableHead>
            <TableRow>
              {columns.map(({ id, label }) => (
                <TableCell
                  size={'small'}
                  key={id}
                  sortDirection={sortColumn === id ? tableSortDirection : false}
                >
                  <TableSortLabel
                    sx={{
                      '&:hover': {
                        textDecoration: 'underline',
                        color: colors.black,
                      },
                      '&:focus-visible': {
                        outline: `2px solid ${colors.blue1}`,
                      },
                    }}
                    active={sortColumn === id}
                    direction={sortColumn === id ? tableSortDirection : 'asc'}
                    onClick={createSortHandler(id)}
                    IconComponent={ArrowDropDownIcon}
                  >
                    {label}
                  </TableSortLabel>
                </TableCell>
              ))}
              <TableCell align="center">
                Action
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {data.map((d, index) => (
              <TableRow key={index}>
                {columns.map(({ id }) => <TableCell key={id}>{`${columnRender(id, d[id])}`}</TableCell>)}
                <TableCell scope="row" align="center">
                  {actions(d)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          SelectProps={{
            inputProps: {
              'aria-label': 'rows per page',
            },
            native: true,
          }}
          component="div"
          count={count}
          rowsPerPage={pageSize}
          page={pageNumber}
          onPageChange={(e, n) => onPageNumberChange(n)}
          onRowsPerPageChange={(e) => onPageSizeChange(parseInt(e.target.value, 10))}
        />
      </TableContainer>
    </ThemeProvider>
  );
};

