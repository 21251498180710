import styles from './About.module.scss';
import BreadCrumbs from 'src/components/BreadCrumbs/BreadCrumbs';
import Heading from 'src/components/Heading/Heading';
import HeadingStyle from 'src/components/Heading/HeadingStyle.enum';
import { useQuery } from '@apollo/client';
import renderRichText from 'src/utils/renderRichText.util';
import { ABOUT_QUERY } from './About.graphql';
import HeaderImage from 'src/assets/svg/about-header-image.svg';
import StatesOutlined from 'src/assets/images/usa-outline-green.svg';
import PageSpacerArt from 'src/assets/images/about-page-artwork.png';
import PolicyCatagories from './PolicyCategories';
import { updateTabTitle } from 'src/utils/updateTabTitle';
import PageTitles from 'src/types/PageTitles.enum';
import contentfulClient from 'src/common/apolloClient';

export default function About(): React.ReactElement {
  updateTabTitle(PageTitles.ABOUT);
  const { data } = useQuery(ABOUT_QUERY, { client: contentfulClient });
  let pageHeader, introText, aelDescription, pageSubHeader, outroText;

  if (data) {
    const response = data?.aboutAelTrackerContentCollection?.items[0];
    pageHeader = response.pageHeader;
    introText = response.introBodyText.json;
    aelDescription = response.aelDescriptionText.json;
    pageSubHeader = response.pageSubHeader;
    outroText = response.outroBodyText.json;
  }

  return (
    <section className={styles.aboutPageContent}>
      <BreadCrumbs />
      <div className={styles.pageHeadingSection}>
        <Heading headingStyle={HeadingStyle.PAGE_TITLE}>{pageHeader}</Heading>
        <img src={HeaderImage} alt="Magnifying class over a computer window" />
      </div>
      <section className={styles.introSection}>
        <div>{renderRichText(introText)}</div>
        <img src={PageSpacerArt} alt="Landscape with various icons like bikes, wind turbines, electric vehicles, and leaves commonly identified as “green”" />
        <div className={styles.aelDescriptionSection}>
          <div className={styles.descriptionText}>{renderRichText(aelDescription)}</div>
          <img src={StatesOutlined} alt="A map of the contiguous United States, Alaska, and Hawaii" />
        </div>
      </section>
      <Heading headingStyle={HeadingStyle.PAGE_TITLE}>{pageSubHeader}</Heading>
      <PolicyCatagories />
      <div className={styles.outroSection}>{renderRichText(outroText)}</div>
    </section>
  );
}
