import { FC, ReactElement } from 'react';
import styles from './GeneralAccordion.module.scss';
import { Accordion, AccordionSummary } from './GeneralAccordionTheme';
import { AccordionDetails } from '@mui/material';
import useScreenSize from 'src/hooks/useScreenSize';
import Breakpoint from 'src/types/BreakPoint.enum';

export interface GeneralAccordionProps {
  className?: string;
  accordionTitle?: ReactElement;
  accordionContent?: ReactElement;
  id: string;
}

const GeneralAccordion: FC<GeneralAccordionProps> = ({ accordionTitle, accordionContent, className, id, ...rest }) => {
  const { screenSizeIsAtLeast } = useScreenSize();
  return (
    <Accordion id={id}>
      <AccordionSummary className={styles.summary}>
        <div className={styles.summaryContent}>{accordionTitle}</div>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          margin: '0px',
          padding: '0px',
          paddingTop: screenSizeIsAtLeast[Breakpoint.LG] ? '5px' : '0px',
          paddingBottom: screenSizeIsAtLeast[Breakpoint.LG] ? '23px' : '17px',
        }}
      >
        <div className={styles.detailContent}>{accordionContent}</div>
      </AccordionDetails>
    </Accordion>
  );
};

export default GeneralAccordion;
