import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Stack from '@mui/material/Stack';
import breadcrumbs from 'src/const/breadcrumbs';
import Link from '@mui/material/Link';
import styles from './BreadCrumbs.module.scss';
import { colors } from 'src/theme/colors';

interface BreadCrumbsProps {
  billId?: string;
}

const separator = <span className={styles.separator}>{'>'}</span>;

const BreadCrumbs: FC<BreadCrumbsProps> = ({ billId }) => {
  let location = useLocation();
  const currentPagePath =
    location.pathname.includes('activate/') ||
    location.pathname.includes('unsubscribe/') ||
    location.pathname.includes('password/reset')
      ? '/'
      : location.pathname;
  const currentPageIndex = breadcrumbs.findIndex(
    (page) =>
      page.path === currentPagePath ||
      (page.path.includes('bill-details') && currentPagePath.includes(page.path)) ||
      (page.path.includes('mytracker') && currentPagePath.includes(page.path)),
  );

  const currBreadcrumbLink =
    location.pathname === `/bill-details/${billId}`
      ? `${breadcrumbs[currentPageIndex].path.replace('/bill-details', `/bill-details/${billId}`)}`
      : breadcrumbs[currentPageIndex].path;

  const currentPageOrder = breadcrumbs[currentPageIndex].order;

  return (
    <div className={styles.content}>
      <Stack spacing={2}>
        <Breadcrumbs separator={separator} aria-label="breadcrumb">
          {breadcrumbs.slice(0, currentPageOrder).map((page, index) => (
            <Link underline="none" key={index} href={page.path} color={colors.blue1} className={styles.crumbLink}>
              {page.pageTitle}
            </Link>
          ))}
          <Link
            underline="none"
            key={currentPageIndex}
            href={currBreadcrumbLink}
            color={colors.darkGray2}
            className={styles.crumbLink}
          >
            {breadcrumbs[currentPageIndex].pageTitle}
          </Link>
        </Breadcrumbs>
      </Stack>
    </div>
  );
};

export default BreadCrumbs;
