import React, {FC, useContext, useState} from 'react';
import GeneralSelect from '../GeneralSelect/GeneralSelect';
import styles from './csvDownloadSection.module.scss';
import {billColumns, CsvColumn} from "./util/csvFields";
import dayjs from "dayjs";
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import {useLazyQuery} from "@apollo/client";
import {CsvDownloadSectionQueryData, CsvDownloadSectionQueryVariables} from "./CsvDownloadSection.interface";
import {CSV_DOWNLOAD_QUERY} from "./CsvDownloadSection.graphql";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import {GlobalContext} from "../../hooks/useGlobalContext";
import isUserAdmin from "../../pages/MyTracker/util/isAdmin";

dayjs.extend(isSameOrAfter);

const currentYear = new Date().getFullYear()
const formatYearText = (y: number) => `Bills from ${y} and forward`
const getYears = (fromYear: number) => Array((currentYear + 1) - fromYear)
  .fill(0)
  .map((v, i) => fromYear + i)

const getYearOptions = (yearToGoBack: number) => {
  return Array(currentYear - (yearToGoBack - 1)).fill(0)
    .map((v, i) => ({
      year: currentYear - i,
      text: formatYearText(currentYear - i),
    }))
}
const yearOptions = getYearOptions(2013);

// Hacks because GeneralSelect cannot be given non-string values.
const generalSelectOptions = yearOptions.map((e) => e.text)
const getYearFromValue = (v: string) => yearOptions.find(o => o.text === v)?.year ?? currentYear;
const getValueFromYear = (v: number) => yearOptions.find(o => o.year === v)?.text ?? formatYearText(currentYear);

const decodeHtmlChars = (text: string) => text.replaceAll(/&#(\d+);/g, (_, dec) => String.fromCharCode(dec));

const csvBuilder = (bills: Record<string, any>[], columns: CsvColumn[]) => {
  const properties = columns.map(v => v.property)

  const contents = [
    columns.map(v => v.label),
    ...bills.map(b => {
      return properties.map(p => {
        return decodeHtmlChars(`${b[p] ?? '-'}`)
          .replaceAll(/"/g, '""')
          .replaceAll(/,/g, '')
          .replaceAll(/\r?\n|\r/g, ' ');
      })
    })
  ].map(l => l.join(',')).join('\n')

  return new Blob([contents], {
    type: 'text/csv',
  });
};

const saveBlob = (blob: Blob, fileName: string) => {
  const simulatedLink = document.createElement('a');
  document.body.appendChild(simulatedLink);
  simulatedLink.hidden = true;

  const url = window.URL.createObjectURL(blob);
  simulatedLink.href = url;
  simulatedLink.download = fileName;
  simulatedLink.click();
  window.URL.revokeObjectURL(url);
};

export const CsvDownloadSection: FC = (): any => {
  const [selectedYear, setSelectedYear] = useState<number>(2024);
  const { userData } = useContext(GlobalContext);
  const isAdmin = isUserAdmin(userData.role);
  const inYearsFiled = getYears(selectedYear)
  const variables = isAdmin
    ? { inYearsFiled, cela: true }
    : { inYearsFiled, user: userData.id }

  const [getBills, { loading }] = useLazyQuery<CsvDownloadSectionQueryData, CsvDownloadSectionQueryVariables>(CSV_DOWNLOAD_QUERY, {
    onCompleted(data) {
      saveBlob(csvBuilder(data.bills, billColumns), `bills-${selectedYear}.${Date.now()}.csv`);
    }
  });

  return (
    <div className={styles.csvSectionContainer}>
      <h4 className={styles.csvSectionTitle}>Download results:</h4>
      <div className={styles.csvSectionSelectContainer}>
        <GeneralSelect
          labelText={'Download bills by year'}
          hideLabel={true}
          data={generalSelectOptions}
          id={'findBillsByYear'}
          handleChange={(e) => {
            setSelectedYear(getYearFromValue(e.target.value));
          }}
          value={getValueFromYear(selectedYear)}
        />
      </div>
      <div>
        <PrimaryButton text={loading ? "Please wait..." : "Download"} disabled={loading} onClick={() => {getBills({ variables })}} />
      </div>
    </div>
  );
};
