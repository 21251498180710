import { HeadCell } from '../types/HeadCell.interface';

export const headCells: readonly HeadCell[] = [
  {
    id: 'filterType',
    label: 'Filter Type',
  },
  {
    id: 'phrase',
    label: 'Phrase',
  },
  {
    id: 'autoDateUpdate',
    label: 'Date & Time Created',
  },
  {
    id: 'user',
    label: 'Created By',
  },
];
