import { FC } from 'react';
import { BillData } from "../../../types/BillData.interface";
import Heading from 'src/components/Heading/Heading';
import HeadingStyle from 'src/components/Heading/HeadingStyle.enum';
import PrimaryButton from 'src/components/PrimaryButton/PrimaryButton';
import styles from './BillHeader.module.scss';
import {stateText} from "../../../const/stateText";

interface BillHeaderProps {
  currentBillDetails: BillData;
}

const BillHeader: FC<BillHeaderProps> = ({ currentBillDetails }) => {
  return (
    <section className={styles.mainContainer}>
      <div className={styles.textStateContainer}>
        <div>
          <svg
            className={`icon icon-state-${currentBillDetails.state}}`}
            id={styles.icon}
          >
            <use
              xlinkHref={`#icon-state-${currentBillDetails.state}`}
            ></use>
          </svg>
        </div>
        <div>
          <Heading headingStyle={HeadingStyle.PAGE_TITLE}>
            {stateText[currentBillDetails.state]}{' '}
            {currentBillDetails.designation}{' '}
            <span>| {currentBillDetails.introductionYear}</span>
          </Heading>
          <Heading headingStyle={HeadingStyle.PAGE_SUBTITLE} className={styles.subtitle}>
            {currentBillDetails.description}
          </Heading>
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <PrimaryButton text="View Latest Bill" id={styles.billButton} onClick={() => window.location.href = currentBillDetails.apiUrl ?? ''} />
      </div>
    </section>
  );
};

export default BillHeader;
