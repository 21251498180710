import { Dispatch, FC } from 'react';
import ReactTooltip from 'react-tooltip';
import styles from './BillCountTable.module.scss';
import {StateEnum} from "../../../types/State.enum";
import {stateText} from "../../../const/stateText";

interface ItemData {
  state: StateEnum;
  value: number;
}

interface BillCountTableProps {
  items: ItemData[];
  onHighlight: Dispatch<StateEnum|undefined>;
  onTooltipChange: Dispatch<string>;
}

export const BillCountTable: FC<BillCountTableProps> = ({
  items,
  onHighlight,
  onTooltipChange,
}) => {
  return (
    <div className={styles.resultTable}>
      <table>
        <caption>Bill count results by state</caption>
        <thead>
          <tr tabIndex={0}>
            <th scope="col">State</th>
            <th scope="col">Bill Count</th>
          </tr>
        </thead>
        <tbody>
        {items.map(({ state, value }) => (
          <tr
            key={state}
            tabIndex={0}
            onFocus={() => {
              ReactTooltip.hide();
              onHighlight(state);
              onTooltipChange(`${stateText[state]}'s total: ${value}`);
            }}
          >
            <th scope="row">{stateText[state]}</th>
            <td>{value}</td>
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  );
};
