import { FC, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { GlobalContext } from 'src/hooks/useGlobalContext';
import useTimeFilterFromUrl from '../hooks/useTimeFilterFromUrl';

interface ProtectedRouteProps {
  children: any;
  setModalOpen: React.Dispatch<boolean>;
}

export const ProtectedRoute: FC<ProtectedRouteProps> = ({ children, setModalOpen }) => {
  const navigate = useNavigate();
  const { authenticated, setMyTrackerTimeFilter, setRecentBillChangesSection, setNotificationPreferencesSection } =
    useContext(GlobalContext);
  const timeFilter = useTimeFilterFromUrl(true);

  if (window.location.href.indexOf('recent-bill-changes') > -1) {
    setRecentBillChangesSection(true);
  }

  if (window.location.href.indexOf('notification-preferences') > -1) {
    setNotificationPreferencesSection(true);
  }

  if (!authenticated) {
    navigate('/');
    setMyTrackerTimeFilter(timeFilter);
    setModalOpen(true);
    return null;
  } else if (authenticated === 'loading') {
    return null;
  } else {
    return children;
  }
};
