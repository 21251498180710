import {PolicyEnum} from "../types/Policy.enum";
import {StatusEnum} from "../types/Status.enum";

export const colors = {
  black: '#000000',
  blackTransparent: 'rgba(0, 0, 0, 0.85)',
  white: '#ffffff',
  darkGray1: '#333333',
  darkGray2: '#666666',
  darkGray3: '#4d4d4d',
  medGray1: '#999999',
  lightGray1: '#f4f4f4',
  lightGray2: '#e4e4e4',
  lightGray3: '#e8e8e8',
  lightGray4: '#cccccc',
  lightGray5: '#f6f6f6',
  lightGray6: '#fcfafa',
  lightGray7: '#f3f3f3',
  lightGray8: '#d8d8d8',
  lightGray9: '#f9f9f9',
  blue1: '#1a78be',
  blue2: '#065694',
  blue3: '#0080B2',
  blue4: '#0f5874',
  blue6: '#1465a1',
  green1: '#09812f',
  green2: '#61a739',
  green3: '#076324',
  green4: '#5c8427',
  green5: '#7D9A59',
  limeGreen: '#8CC63F',
  orange1: '#ffa100',
  orange2: '#E6901A',
  yellow1: '#ffcb70',
  yellow2: '#ebd36a24',
  yellow3: '#FECB08',
  yellow4: '#ebd36a',
  gold: '#D8A102',
  focus: 'rgb(239, 58, 88)',
};

export const mapGradients = {
  stateGradient1: '#C4E1B0',
  stateGradient2: '#7DCD41',
  stateGradient3: '#64AF2C',
  stateGradient4: '#4F8B23',
  stateGradient5: '#3C691B',
};

export const gradients = {
  medBlue: 'linear-gradient(#0b9ad2, #0f5874)',
  medGreen: 'linear-gradient(#61A739 30%, #076324)',
  darkGreen: 'linear-gradient(#076324 25%, #000000)',
  darkBlue: 'linear-gradient(#0F5874 25%, #000000)',
  lightGray: `linear-gradient(${colors.lightGray3}, ${colors.lightGray4})`,
  medGray: `linear-gradient(${colors.lightGray4}, ${colors.darkGray2})`,
};

export const policyColors = {
  [PolicyEnum.ECONOMIC]: '#6A2663',
  [PolicyEnum.ELECTRICITY]: '#204284',
  [PolicyEnum.EMISSIONS]: '#46755E',
  [PolicyEnum.ENERGY]: '#E48F26',
  [PolicyEnum.FINANCING]: '#569742',
  [PolicyEnum.INFRASTRUCTURE]: '#B3882F',
  [PolicyEnum.GAS]: '#0088AE',
  [PolicyEnum.REGULATORY]: '#B5351E',
  [PolicyEnum.TRANSPORTATION]: '#B45828',
  [PolicyEnum.OTHER]: '#8DC743',
}

export const statusColors = {
  [StatusEnum.ENACTED]: '#91AD17',
  [StatusEnum.FAILED]: '#DE461B',
  [StatusEnum.INTRODUCED]: '#E4CA71',
  [StatusEnum.VETOED]: '#611F0C',
  [StatusEnum.PASSED_ONE_CHAMBER]: '#E8B616',
  [StatusEnum.PASSED_BOTH_CHAMBERS]: '#DE7F1A',
}
