import { alpha, Box } from '@mui/material';
import { Dispatch, FC, SetStateAction } from 'react';
import { colors } from 'src/theme/colors';
import styles from './BarSegment.module.scss';

interface BarSegmentProps {
  id: string;
  percentage: number;
  color: string;
  isHighlighted: boolean;
  onHighlight: Dispatch<SetStateAction<string>>;
}

const BarItem: FC<BarSegmentProps> = ({ id, percentage, color, isHighlighted, onHighlight }) => {
  const currentColor = isHighlighted ? alpha(color, 0.9) : alpha(color, 0.66);
  const segmentClasses = percentage < 5 ? `${styles.segment} ${styles.hiddenText}` : `${styles.segment}`;
  return (
    <Box
      sx={{
        textAlign: 'center',
        width: `${percentage}%`,
        color: colors.white,
        backgroundColor: currentColor,
      }}
    >
      <div
        className={segmentClasses}
        tabIndex={0}
        onFocus={() => {onHighlight(id)}}
        onBlur={() => {onHighlight('')}}
        onMouseEnter={() => {onHighlight(id)}}
        onMouseLeave={() => {onHighlight('')}}
      >
        {percentage}%
      </div>
    </Box>
  );
};

export default BarItem;
