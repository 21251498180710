import { gql } from '@apollo/client';

export const GET_BILL_IMPORT_FILTERS = gql`
  query ($pageNumber: Int, $pageSize: Int, $sortColumn: String, $sortDirection: SortDirection, $keywords: String) {
    billImportFiltersCount(
      filter: {
        pageNumber: $pageNumber
        pageSize: $pageSize
        sortColumn: $sortColumn
        sortDirection: $sortDirection
        keywords: $keywords
      }
    )
    billImportFilters(
      filter: {
        pageNumber: $pageNumber
        pageSize: $pageSize
        sortColumn: $sortColumn
        sortDirection: $sortDirection
        keywords: $keywords
      }
    ) {
      id
      phrase
      autoDateUpdate
      filterType
      user
    }
  }
`;

export const RUN_ALL_BILL_IMPORT_FILTERS = gql`
  mutation {
    runAllBillFilters
  }
`;
