export const navItems = [
  {
    title: 'AEL Tracker',
    subItems: [
      {
        title: 'About AEL Tracker',
        to: '/about',
      },
      {
        title: 'FAQs',
        to: '/faqs',
      },
      {
        title: 'Maps & Graphs',
        to: '/maps-graphs',
      },
      {
        title: 'myTracker',
        to: '/mytracker',
      },
    ],
  },
  {
    title: 'Trends & Analysis',
    to: '/trends-analysis',
  },
  {
    title: 'Contact',
    to: '/contact',
  },
  {
    title: 'About CNEE',
    href: 'https://cnee.colostate.edu/about-us/',
  },
];
