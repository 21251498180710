import {ChangeEvent, FC} from 'react';
import styles from './FilterSection.module.scss';
import GeneralChipSelect from 'src/components/GeneralChipSelect/GeneralChipSelect';
import { getYearSelectOptions } from 'src/const/yearSelectOptions';
import stateData from '../../../const/stateData.json';
import PrimaryButton from 'src/components/PrimaryButton/PrimaryButton';
import Input from 'src/components/Input/Input';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FilterSectionProps } from './types/FilterSectionProps.interface';
import {policyText} from "../../../const/policyText";
import {statusText} from "../../../const/statusText";

const FiltersSection: FC<FilterSectionProps> = ({
  filtersTemplate,
  clearFilterInputs,
  handleSearch,
  handleSelectedValues,
  handleSubmit,
  handleClear,
  isLoading
}) => {
  const makelist = (importItems: any) => {
    return (Object.keys(importItems) as (keyof typeof importItems)[]).map((key) => {
      return importItems[key];
    });
  };

  const statusOptions = makelist(statusText);
  const categoryOptions = makelist(policyText);
  const stateOptions = stateData.map((item) => item.name);
  const yearOptions = getYearSelectOptions();

  const handlePressEnter = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <div className={styles.filterContainer}>
      <div className={styles.filterHeader}>
        <div className={styles.titleWrapper}>
          <FontAwesomeIcon icon={faFilter} className={styles.icon} />
          <span>Filter By</span>
        </div>
        <button onClick={clearFilterInputs} className={styles.resetButton}>
          Reset all Filters
        </button>
      </div>
      <p>No selection will return a list of all bills from the current year. Use filters to refine your results.</p>
      <form className={styles.filterForm}>
        <div className={styles.input}>
          <GeneralChipSelect
            handleClear={handleClear}
            handleSelectedValues={handleSelectedValues}
            values={filtersTemplate.stateFilters}
            data={stateOptions}
            id={'stateFilters'}
            labelText={'States'}
          />
        </div>
        <div className={styles.input}>
          <GeneralChipSelect
            handleClear={handleClear}
            handleSelectedValues={handleSelectedValues}
            values={filtersTemplate.policyFilters}
            data={categoryOptions}
            id={'policyFilters'}
            labelText={'Policies'}
          />
        </div>
        <div className={styles.input}>
          <GeneralChipSelect
            handleClear={handleClear}
            handleSelectedValues={handleSelectedValues}
            values={filtersTemplate.yearFilters}
            data={yearOptions}
            id={'yearFilters'}
            labelText={'Years'}
          />
        </div>
        <div className={styles.input}>
          <GeneralChipSelect
            handleClear={handleClear}
            handleSelectedValues={handleSelectedValues}
            values={filtersTemplate.statusFilters}
            data={statusOptions}
            id={'statusFilters'}
            labelText={'Statuses'}
          />
        </div>
        <Input
          hasClearOption={true}
          id={'search'}
          label={'Keywords'}
          placeholder={'Search by Keyword'}
          value={filtersTemplate.search}
          handleClear={handleClear}
          handleChange={(e: ChangeEvent<HTMLInputElement>) => handleSearch(e.target.id, e.target.value)}
          onKeyDown={handlePressEnter}
        />
        <div className={styles.buttonWrapper}>
          <PrimaryButton disabled={isLoading} onClick={handleSubmit} text={isLoading ? 'Searching' : 'Find Bills'} />
        </div>
      </form>
    </div>
  );
};

export default FiltersSection;
