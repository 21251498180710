import { gql } from '@apollo/client';

export const EPRC_BILLS_DETAILS_QUERY = gql`
  query ($inOsBillIds: [Int!]) {
    bills(input: { inOsBillIds: $inOsBillIds }) {
      billId
      changeHash
      osState
      osBillId
      apiUrl
      osChamber
      osSession
      lsMigrated
      apiIgnore
      timeStampCreation
      timeStampUpdate
      timeStampNewsGoogle
      timeStampNewsYahoo
      timeStampOsCheck
      autoTimeOsUpdate
      userCreation
      userUpdate
      title
      chamberName
      number
      state
      yearFiled
      enacted
      osDatePassedLower
      osDatePassedUpper
      osDateSigned
      osStatus
      osStatusId
      opposition
      displayType
      keywords
      topic
      policyCategory
      subcategory
      summary
      schedule
      clearedSuspicious
      rollCalls {
        id
        passed
        date
        description
        chamber
        yea
        nay
        total
      }
      docs {
        id
        docId
        date
        type
        url
      }
      actions {
        id
        date
        action
      }
      sponsorships {
        id
        type
        order
        person {
          id
          name
          firstName
          lastName
          party
        }
      }
    }
  }
`;
