import { AccordionSummary } from './BillDetailsAccordionTheme';
import styles from './BillDetailsAccordion.module.scss';
import { FC } from 'react';

interface Props {
  title: string;
  count: number;
}

const AccordionTitle: FC<Props> = ({ title, count }) => {
  return (
    <AccordionSummary className={styles.summary}>
      <div className={styles.summaryContent}>
        <span>{`${title} (${count})`}</span>
      </div>
    </AccordionSummary>
  );
};

export default AccordionTitle;
