import React, {useState} from 'react';
import {organizationTypes} from "../../../const/organizationTypes";
import {CreateAccountAdmin, UpdateAccountPublic} from "../../../services/AuthService";
import {toast} from "react-toastify";
import {UserData} from "../../../types/UserData.interface";
import GeneralModal from "../../../components/GeneralModal/GeneralModal";
import {DeactivateUserPrompt} from "./DeactivateUserPrompt/DeactivateUserPrompt";
import {DeleteUserPrompt} from "./DeleteUserPrompt/DeleteUserPrompt";
import {EditUserForm} from "./EditUserForm/EditUserForm";

interface EditableUser {
  id: string
  active: boolean
  firstName: string
  lastName: string
  email: string
  role: string
  newPassword?: string
  confirmNewPassword?: string
}

interface EditUserModalProps {
  user?: UserData
  open: boolean
  onComplete: () => void
  onCancel: () => void
}

const EditUserModal = ({
  user,
  open,
  onComplete,
  onCancel,
}: EditUserModalProps) => {
  const [formPrompt, setFormPrompt] = useState<'deactivate'|'delete'>()
  const [updatedUserData, setUpdatedUserData] = useState<EditableUser>()

  const handleSubmit = async (data: EditableUser) => {
    if (!data.active) {
      setUpdatedUserData(data)
      setFormPrompt('deactivate')
    } else if (Boolean(user)) {
      updateUser(data)
    } else {
      createUser(data)
    }
  }

  const createUser = async (form: EditableUser) => {
    await CreateAccountAdmin({
      firstName: form.firstName,
      lastName: form.lastName,
      email: form.email,
      role: form.role,
      active: form.active,
      newPassword: form.newPassword,
      confirmNewPassword: form.confirmNewPassword,
      organization: '',
      organizationType: organizationTypes.Other,
    });

    toast.success('User created', {
      position: toast.POSITION.TOP_CENTER,
    });

    onComplete()
  }

  const updateUser = async (form: EditableUser) => {
    await UpdateAccountPublic({
      id: form.id,
      firstName: form.firstName,
      lastName: form.lastName,
      email: form.email,
      role: form.role,
      active: form.active,
    });

    toast.success('Update successful', {
      position: toast.POSITION.TOP_CENTER,
    });

    onComplete()
  }

  // Depending on the mode, the content of the modal changes in-place.
  const EditFormContent = () => {
    if (user && updatedUserData && formPrompt === 'deactivate') {
      return (
        <DeactivateUserPrompt
          user={updatedUserData}
          onComplete={() => {
            setFormPrompt(undefined)
            onComplete()
          }}
          onCancel={() => {setFormPrompt(undefined)}}
        />
      )
    }

    if (user && formPrompt === 'delete') {
      return (
        <DeleteUserPrompt
          user={user}
          onComplete={() => {
            setFormPrompt(undefined)
            onComplete()
          }}
          onCancel={() => {setFormPrompt(undefined)}}
        />
      )
    }

    return <EditUserForm
      user={user}
      onSubmit={(data) => handleSubmit(data)}
      onDelete={() => {setFormPrompt('delete')}}/>
  }

  return (
    <GeneralModal modalOpen={open} onClose={() => {
      setFormPrompt(undefined)
      onCancel()
    }}>
      <EditFormContent />
    </GeneralModal>
  );
};

export default EditUserModal;
