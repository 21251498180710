import React, { FC, useContext } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { navItems } from 'src/const/navItems';
import MenuItemMobile from '../MainNav/MenuItemsMobile/MenuItemsMobile';
import { navItemsAuthenticated } from 'src/const/navItemsAuthenticated';
import { GlobalContext } from 'src/hooks/useGlobalContext';

export interface DrawerMenuProps {
  drawerOpen: boolean;
  setDrawerOpened: React.Dispatch<React.SetStateAction<boolean>>;
}

const DrawerMenu: FC<DrawerMenuProps> = ({ drawerOpen, setDrawerOpened }) => {
  const { authenticated } = useContext(GlobalContext);
  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setDrawerOpened(open);
  };

  // Add depth to each level of nested items and subItems (0, 1, or 2)
  const addDepth = (items: any[], depth = 0) => {
    (items || []).forEach((obj: any) => {
      obj.depth = depth;
      addDepth(obj.subItems, depth + 1);
    });
  };

  if (navItems) {
    addDepth(navItems);
  }

  const links = [...navItems];

  if (authenticated !== 'loading' && authenticated) {
    addDepth(navItemsAuthenticated);
    links.push(...navItemsAuthenticated);
  }

  return (
    <div>
      <Drawer
        anchor={'left'}
        open={drawerOpen}
        BackdropProps={{ invisible: true }}
        PaperProps={{
          style: {
            marginTop: '38px',
            width: '70%',
            marginRight: '0',
            backgroundColor: 'transparent',
            boxShadow: 'none',
          },
        }}
        onClose={toggleDrawer(false)}
      >
        <Box role="presentation" onKeyDown={toggleDrawer(false)}>
          {links.map((item, index) => (
            <MenuItemMobile item={item} key={index} />
          ))}
        </Box>
      </Drawer>
    </div>
  );
};

export default DrawerMenu;
