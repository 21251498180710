import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { headCells } from '../const/headCells';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { colors } from '../../../theme/colors';
import React, { FC } from 'react';
import { ImportFilter } from '../types/ImportFilter.interface';
import { Order } from '../../../types/Order.type';
import { SortDirectionEnum } from '../../../types/SortDirection.enum';

interface FilterTableHeadProps {
  currentSortColumn: keyof ImportFilter;
  setSortColumn: (n: keyof ImportFilter) => void;
  setSortDirection: (n: SortDirectionEnum) => void;
}

export const FilterTableHead: FC<FilterTableHeadProps> = ({ currentSortColumn, setSortColumn, setSortDirection }) => {
  const [order, setOrder] = React.useState<Order>('desc');
  const handleRequestSort = (input: keyof ImportFilter) => {
    if (input === currentSortColumn) {
      const isAsc = order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setSortDirection(isAsc ? SortDirectionEnum.DESC : SortDirectionEnum.ASC);
    } else {
      setSortColumn(input);
      setSortDirection(SortDirectionEnum.DESC);
    }
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id}>
            <TableSortLabel
              sx={{
                '&:hover': {
                  textDecoration: 'underline',
                  color: colors.black,
                },
                '&:focus-visible': {
                  outline: `2px solid ${colors.blue1}`,
                },
              }}
              onClick={() => handleRequestSort(headCell.id)}
              active={currentSortColumn === headCell.id}
              direction={currentSortColumn === headCell.id ? order : 'asc'}
              IconComponent={ArrowDropDownIcon}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell align="center">
          Action
        </TableCell>
      </TableRow>
    </TableHead>
  );
};
