import React, { FC, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import styles from './MapSection.module.scss';
import GradientLegend from './GradientLegend/GradientLegend';
import Heading from 'src/components/Heading/Heading';
import HeadingStyle from 'src/components/Heading/HeadingStyle.enum';
import { BillCountTable } from './BillCountTable/BillCountTable';
import { MapSkipLink } from './MapSkipLink/MapSkipLink';
import {GradientMap} from "../GradientMap/GradientMap";
import {StateEnum} from "../../types/State.enum";

export interface MapItem {
  state: StateEnum;
  value: number;
}

export interface MapSectionProps {
  items: MapItem[];
  className?: string;
}

const MapSection: FC<MapSectionProps> = ({ items, className }) => {
  const [content, setContent] = useState('');
  const [isHighlighted, setIsHighlighted] = useState<StateEnum|undefined>(undefined);

  const classes = `${styles.mapSection} ${className}`;
  return (
    <section className={classes}>
      <div className={styles.visualizationsTab}>
        <Heading headingStyle={HeadingStyle.CONTAINER_TITLE} className={styles.heading}>
          Distribution of Bills Across States
        </Heading>
        <p>Select a state below to see the state's bill count.</p>
        <MapSkipLink id="maptop" to="#mapbottom" text="To bottom of map" />
        <span className={styles.billCountLabel}>{content}</span>
        <BillCountTable
          items={items}
          onHighlight={setIsHighlighted}
          onTooltipChange={setContent}
        />
        <GradientMap
          items={items}
          highlightedState={isHighlighted}
          onHighlight={setIsHighlighted}
          onTooltipChange={setContent} />
        <ReactTooltip className={styles.toolTip}>{content}</ReactTooltip>
        <GradientLegend values={items.map(i => i.value)} />
        <MapSkipLink id="mapbottom" to="#maptop" text="To top of map" />
      </div>
    </section>
  );
};

export default MapSection;
