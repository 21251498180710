import { BillData } from "../../../types/BillData.interface";
import styles from '../BillCard.module.scss';
import React, { FC } from 'react';
import { policyIcon } from '../../../const/policyIcon';
import { statusIcon } from '../../../const/statusIcon';
import {policyText} from "../../../const/policyText";
import {statusText} from "../../../const/statusText";

interface BillIconsProps {
  billCardDetails?: BillData;
}

const BillIcons: FC<BillIconsProps> = ({ billCardDetails }) => {
  if (!billCardDetails) return <></>;

  return (
    <div className={styles.billIcons}>
      <div className={styles.billIconContainer}>
        <img src={policyIcon[billCardDetails.policyCategory]} alt="" className={styles.billIcon} />
        <span className={styles.iconText}>{policyText[billCardDetails.policyCategory] ?? ''}</span>
      </div>
      <div className={styles.billIconContainer}>
        <img src={statusIcon[billCardDetails.status]} alt="" className={styles.billIcon} />
        <span className={styles.iconText}>{statusText[billCardDetails.status]}</span>
      </div>
    </div>
  );
};

export default BillIcons;
