import {createTheme} from "@mui/material/styles";

export const tableTheme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: 'Lato'
        },
        head: {
          textAlign: 'center'
        },
        sizeSmall: {
          padding: '8px'
        }
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        select: {
          fontFamily: 'Lato'
        },
        selectLabel: {
          fontFamily: 'Lato'
        },
        selectIcon: {
          mt: 0,
          p: 0,
        },
        displayedRows: {
          fontFamily: 'Lato'
        },
      }
    }
  }
})
