import React, { FC, useContext, useState } from 'react';
import styles from './BillCard.module.scss';
import { BillData } from "../../types/BillData.interface";
import { GlobalContext } from 'src/hooks/useGlobalContext';
import { useMutation } from '@apollo/client';
import { ADD_BILL_MYTRACKER, DELETE_BILL_MYTRACKER } from './BillCard.graphql';
import { toast } from 'react-toastify';
import Sponsors from './components/Sponsors';
import MainContent from './components/MainContent';
import BillCardWrapper from './components/BillCardWrapper';
import BillIcons from './components/BillIcons';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import isUserAdmin from '../../pages/MyTracker/util/isAdmin';
import { ADD_CELA_BILL, REMOVE_CELA_BILL } from './AdminCard.graphql';
import { AddOrRemoveBillButton } from './components/AddOrRemoveBillButton';

interface BillCardProps {
  billData?: BillData;
  maxTitleLength?: number;
  isInMyTracker?: boolean;
  refetchBills: () => void;
  isTracked: boolean;
}

function toastSuccess(message: string) {
  toast.success(message, {
    position: toast.POSITION.TOP_CENTER,
    closeOnClick: true,
  });
}

function toastError() {
  toast.error('There was an error with your request', {
    position: toast.POSITION.TOP_CENTER,
    closeOnClick: true,
  });
}

const BillCard: FC<BillCardProps> = ({ billData, maxTitleLength, refetchBills, isTracked, isInMyTracker }) => {
  const { userData, setAuthModalOpen, setLoggedOutBillTracked } = useContext(GlobalContext);
  const isAdmin = isUserAdmin(userData.role);
  const billCardDetails = billData as BillData;
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [addTrackedBill] = useMutation(ADD_BILL_MYTRACKER);
  const [removeTrackedBill] = useMutation(DELETE_BILL_MYTRACKER);
  const [addCelaBill] = useMutation(ADD_CELA_BILL);
  const [removeCelaBill] = useMutation(REMOVE_CELA_BILL);

  const handleAddBill = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (userData?.id) {
      if (isAdmin) {
        addCelaBill({ variables: { billId: billCardDetails?.id } })
          .then(() => {
            toastSuccess('Successfully added Cela bill');
            refetchBills();
          })
          .catch(() => toastError());
      } else {
        addTrackedBill({ variables: { userId: userData?.id, billId: billCardDetails?.id } })
          .then(() => {
            toastSuccess('Added bill to MyTracker');
            refetchBills();
          })
          .catch(() => toastError());
      }
    } else {
      setAuthModalOpen(true);
      setLoggedOutBillTracked(billCardDetails?.id || 0);
    }
  };

  const handleDelete = async () => {
    if (isAdmin) {
      removeCelaBill({ variables: { billId: billCardDetails?.id } })
        .then(() => {
          toastSuccess(
            `Removed ${billCardDetails?.state} ${
              billCardDetails?.designation
            } from Cela Bills`,
          );
          refetchBills();
        })
        .catch(() => toastError());
    } else {
      removeTrackedBill({ variables: { userId: userData?.id, billId: billCardDetails?.id } })
        .then(() => {
          toastSuccess(
            `Removed ${billCardDetails?.state} ${
              billCardDetails?.designation
            } from MyTracker`,
          );
          refetchBills();
        })
        .catch(() => toastError());
    }
    setModalOpen(false);
  };

  return (
    <>
      <ConfirmModal
        title="Remove Tracked Bill"
        actionButtonText="Remove"
        description="Are you sure you want to remove this bill from tracker?"
        handleDelete={handleDelete}
        modalOpen={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
      />
      <section className={styles.card}>
        <BillCardWrapper billId={billCardDetails?.billId}>
          <MainContent billCardDetails={billCardDetails} maxTitleLength={maxTitleLength} />
          <Sponsors sponsorsObject={billCardDetails?.sponsors} />
          <BillIcons billCardDetails={billCardDetails} />
        </BillCardWrapper>
        <AddOrRemoveBillButton
          isInMyTracker={isInMyTracker}
          billData={billData}
          isAdmin={isAdmin}
          isTracked={isTracked}
          setModalOpen={setModalOpen}
          handleAddBill={handleAddBill}
        />
      </section>
    </>
  );
};

export default BillCard;
