import { FC } from 'react';
import styles from './PolicyCategories.module.scss';
import { policyIcon } from 'src/const/policyIcon';
import {policyText} from "../../const/policyText";
import Link from "@mui/material/Link";
import {PolicyEnum} from "../../types/Policy.enum";

const POLICIES = [
  PolicyEnum.ECONOMIC,
  PolicyEnum.ELECTRICITY,
  PolicyEnum.EMISSIONS,
  PolicyEnum.ENERGY,
  PolicyEnum.FINANCING,
  PolicyEnum.INFRASTRUCTURE,
  PolicyEnum.GAS,
  PolicyEnum.REGULATORY,
  PolicyEnum.TRANSPORTATION,
  PolicyEnum.OTHER,
];

const PolicyCatagories: FC = () => {
  const policyList = POLICIES.map((policy, index) => {
    return (
      <div className={styles.policyCategory} key={index}>
        <Link href={`/about/${policy.toLowerCase()}`}>
          <img className={styles.icon} src={policyIcon[policy]} alt={`${policyText[policy]} icon`} />
          <span className={styles.text}>{policyText[policy]}</span>
        </Link>
      </div>
    );
  });
  return <section className={styles.policyGrid}>{policyList}</section>;
};

export default PolicyCatagories;
