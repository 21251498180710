import React, { FC } from 'react';
import { mapGradients } from 'src/theme/colors';
import styles from './GradientLegend.module.scss';

interface GradientLegendProps {
  values: number[];
  segments?: number;
}

const GradientLegend: FC<GradientLegendProps> = ({
  values,
  segments = 5
}) => {
  const highest = Math.max(...values);
  const increment = Math.floor(highest / segments);
  const tiers = Array(segments).fill(0)
    .map((v, i) => i * increment)
    .concat(highest)

  return (
    <>
      {tiers.length === 6 && (
        <>
          <div className={styles.gradientTitle}>Bill Volume By State</div>
          <div className={styles.gradientScale}>
            {Object.values(mapGradients).map((g, index) => (
              <div key={index}></div>
            ))}
          </div>
          <div className={styles.gradientLabels}>
            {tiers.map((tier, index) => (
              <div key={index} className={styles.labelContainer}>
                {tier.toString()}
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default GradientLegend;
