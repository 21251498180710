import { FC, ReactNode } from 'react';
import styles from '../BillCard.module.scss';
import openBillDetailsInNewTab from '../../../utils/openBillDetailsInNewTab.util';

interface BillCardWrapperProps {
  children: ReactNode;
  billId: number | undefined | null;
}

const BillCardWrapper: FC<BillCardWrapperProps> = ({ children, billId }) => {
  return (
    <div
      aria-label="Selecting the card and pressing Enter will open the link in a new tab"
      className={styles.cardInfo}
      tabIndex={0}
      onClick={() => {
        openBillDetailsInNewTab(billId);
      }}
      onKeyDownCapture={(event) => {
        if (event.key === 'Enter') {
          openBillDetailsInNewTab(billId);
        }
      }}
    >
      {children}
    </div>
  );
};

export default BillCardWrapper;
