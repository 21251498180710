import { gql } from '@apollo/client';

export const GET_ADMIN_CELA_BILLS = gql`
  query($keywords: String, $pageNumber: Int, $pageSize: Int) {
    listActiveCelaBills: bills(
      input: {
        cela: true,
        likeKeywords: $keywords,
        pageNumber: $pageNumber,
        pageSize: $pageSize,
      }
    ) {
      billId
      title
      cela
      state
      number
      yearFiled
      autoTimeOsUpdate
      chamberName
      osBillId
      osState
      osDatePassedLower
      osDatePassedUpper
      osDateSigned
      osStatus
      osStatusId
      policyCategory
      subcategory
      topic
      summary
      osCreatedAt
      sponsorships {
        id
        type
        order
        person {
          id
          name
          party
          firstName
          lastName
        }
      }
    },
    policyDistribution: billsDistribution(
      input: {
        cela: true,
        likeKeywords: $keywords,
        groupBy: "policyCategory"
      }
    ) {
      group
      count
    },
    statusDistribution: billsDistribution(
      input: {
        cela: true,
        likeKeywords: $keywords,
        groupBy: "osStatus"
      }
    ) {
      group
      count
    },
    stateDistribution: billsDistribution(
      input: {
        cela: true,
        likeKeywords: $keywords,
        groupBy: "state"
      }
    ) {
      group
      count
    },
    billsCount(
      input: {
        cela: true,
        likeKeywords: $keywords,
        groupBy: "state"
      }
    )
  }
`;
