import React, { FC, useEffect, useState } from 'react';
import styles from './MapsAndGraphs.module.scss';
import BreadCrumbs from 'src/components/BreadCrumbs/BreadCrumbs';
import Heading from 'src/components/Heading/Heading';
import HeadingStyle from 'src/components/Heading/HeadingStyle.enum';
import PageTitles from 'src/types/PageTitles.enum';
import { updateTabTitle } from 'src/utils/updateTabTitle';
import { useQuery } from '@apollo/client';
import { BILL_COUNT } from '../Landing/MainSection/MainSection.graphql';
import Box from "@mui/material/Box";
import {NationalDistributionTabs} from "./NationalDistributionTabs/NationalDistributionTabs";
import {PolicyCategoryMap} from "./PolicyCategoryMap/PolicyCategoryMap";

interface BillTotal {
  billsCount: number;
}

const MapsAndGraphs: FC = () => {
  updateTabTitle(PageTitles.MAPS_GRAPHS);
  const [billsCount, setBillsCount] = useState(0);
  const { data } = useQuery<BillTotal>(BILL_COUNT);
  useEffect(() => {
    if (data) {
      setBillsCount(data.billsCount);
    }
  }, [data]);
  const formattedTotal = billsCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return (
    <>
      <BreadCrumbs />
      <div className={styles.pageIntro}>
        <Heading className={styles.pageHeading} headingStyle={HeadingStyle.PAGE_TITLE}>
          Maps & Graphs
        </Heading>
        <Heading className={styles.pageHeading} headingStyle={HeadingStyle.PAGE_SUBTITLE}>
          The AEL Tracker currently contains information on {formattedTotal} bills. The maps and graphs below illustrate
          the national distribution of all bills by state, policy category, and status
        </Heading>
      </div>
      <div>
        <Heading className={styles.tabHeading} headingStyle={HeadingStyle.PAGE_SUBTITLE}>
          National Distribution of Bills
        </Heading>
        <Box sx={{ width: '100%' }}>
          <NationalDistributionTabs />
          <PolicyCategoryMap />
        </Box>
      </div>
    </>
  );
};

export default MapsAndGraphs;
