import React, { useEffect, useContext } from 'react';
import { GlobalContext } from 'src/hooks/useGlobalContext';
import { Logout as logout } from 'src/services/AuthService';
import { toast } from 'react-toastify';
import { Navigate } from 'react-router-dom';

const Logout = () => {
  const { setLoggedOutBillTracked, setAuthenticated, setUserData } = useContext(GlobalContext);

  useEffect(() => {
    setLoggedOutBillTracked(0);
    logout(setAuthenticated, setUserData);
    toast.success('Logged out successfully', {
      position: toast.POSITION.TOP_CENTER,
    });
  });

  return <Navigate to="/" />;
};

export default Logout;
