export const navItemsAuthenticated = [
  {
    title: 'My Account',
    subItems: [
      {
        title: 'Account Settings',
        to: '/mytracker#accountManage',
      },
      {
        title: 'User Account Management',
        to: '/user-account-management',
        role: ['SUPER_ADMIN'],
      },
      {
        title: 'Bill Administration',
        to: '/bill-admin',
        role: ['SUPER_ADMIN', 'ADMIN'],
      },
      {
        title: 'Filter Configuration',
        to: '/filter-config',
        role: ['SUPER_ADMIN'],
      },
      {
        title: 'Log Out',
        to: '/logout',
      },
    ],
  },
];
