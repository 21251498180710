import { alpha, Box } from '@mui/material';
import { Dispatch, FC } from 'react';
import { colors } from 'src/theme/colors';
import styles from './BarItem.module.scss';

interface BarItemProps {
  id: string;
  label: string;
  value: number;
  percentage: number;
  color: string;
  isHighlighted: boolean;
  onHighlight: Dispatch<string>;
}

export const BarItem: FC<BarItemProps> = ({
  id,
  label,
  color,
  value,
  percentage,
  isHighlighted,
  onHighlight,
}) => {
  const currentColor = isHighlighted ? alpha(color, 0.9) : colors.lightGray8;
  const currentFont = isHighlighted ? colors.white : colors.black;
  return (
    <div
      className={styles.itemContainer}
      tabIndex={0}
      onFocus={() => {onHighlight(id)}}
      onBlur={() => {onHighlight('')}}
      onMouseEnter={() => onHighlight(id)}
      onMouseLeave={() => {onHighlight('')}}
    >
      <Box
        className={styles.itemPercent}
        sx={{
          textAlign: 'center',
          width: 120,
          color: currentFont,
          backgroundColor: currentColor,
        }}
      >
        {percentage}% | {value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      </Box>
      <div className={styles.itemText}>{label}</div>
    </div>
  );
};
