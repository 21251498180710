import { FC } from 'react';
import styles from './EditButton.module.scss';

interface EditButtonProps {
  isEditDisabled: boolean;
  handleEdit: () => void;
  handleCancel: () => void;
  checkForm: () => void;
}

const EditButton: FC<EditButtonProps> = ({ isEditDisabled, handleEdit, handleCancel, checkForm }) => {
  return (
    <>
      {isEditDisabled ? (
        <div>
          <button className={styles.editOption} onClick={handleEdit}>
            Edit
          </button>
        </div>
      ) : (
        <div>
          <button className={styles.editOption} onClick={handleCancel}>
            Cancel
          </button>
          <button className={styles.editOption} onClick={checkForm}>
            Save
          </button>
        </div>
      )}
    </>
  );
};

export default EditButton;
