import React, { FC } from 'react';
import Button from '@mui/material/Button';
import { ThemeProvider } from '@mui/material/styles';
import buttonTheme from 'src/theme/buttonTheme';
import { colors } from 'src/theme/colors.js';
import useScreenSize from 'src/hooks/useScreenSize';
import Breakpoint from 'src/types/BreakPoint.enum';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import styles from './PrimaryButton.module.scss';

export interface ButtonProps {
  onClick: (e: any) => void;
  disabled?: boolean;
  text: string;
  className?: string;
  id?: string;
}

const PrimaryButton: FC<ButtonProps> = ({ text, onClick, disabled, className, id }) => {
  const { screenSizeIsAtLeast } = useScreenSize();
  return (
    <ThemeProvider theme={buttonTheme}>
      <Button
        variant="contained"
        onClick={onClick}
        disableElevation
        id={id ? id : undefined}
        className={`${styles.buttonMuiOverride} ${className ? className : ''}`}
        disableFocusRipple
        disabled={disabled ? disabled : false}
        sx={{
          padding: '20px 35px',
          fontSize: screenSizeIsAtLeast[Breakpoint.LG] ? '1rem' : '.9rem',
          width: screenSizeIsAtLeast[Breakpoint.LG] ? 'initial' : '100%',
          lineHeight: '5px',
          borderRadius: '6px',
          '&:focus-visible': {
            outline: `4px double ${colors.focus}`,
            background: 'none',
            backgroundColor: colors.black,
          },
          '&:hover': {
            background: 'none',
            backgroundColor: colors.black,
          },
        }}
      >
        <div>{text}</div>
        <FontAwesomeIcon icon={faArrowRight} className={styles.arrow} />
      </Button>
    </ThemeProvider>
  );
};

export default PrimaryButton;
