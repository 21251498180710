import { FC, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Heading from 'src/components/Heading/Heading';
import HeadingStyle from 'src/components/Heading/HeadingStyle.enum';
import IconButton from 'src/components/IconButton/IconButton';
import { GlobalContext } from 'src/hooks/useGlobalContext';
import styles from './MyTrackerSection.module.scss';

const MyTrackerSection: FC = () => {
  const navigate = useNavigate();
  const { setAuthModalOpen, authenticated } = useContext(GlobalContext);

  const checkLogin = () => {
    return authenticated ? navigate('/mytracker') : setAuthModalOpen(true);
  };

  return (
    <section className={styles.mainContainer}>
      <div className={styles.pageTitleContainer}>
        <div>
          <Heading headingStyle={HeadingStyle.PAGE_TITLE} className={styles.mainHeader}>
            Search the Advanced Energy Legislation Tracker
          </Heading>
          <Heading headingStyle={HeadingStyle.PAGE_SUBTITLE} className={styles.subHeader}>
            Sign up for myTracker to create a personalized list of bills to track.{' '}
          </Heading>
        </div>
      </div>
      <IconButton text="Go to myTracker " onClick={() => checkLogin()} />
    </section>
  );
};

export default MyTrackerSection;
