import { FC, useState, useEffect } from 'react';
import styles from './GeneralChipSelect.module.scss';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { colors } from 'src/theme/colors';
import chevronDown from '../../assets/svg/chevron-down.svg';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ClearFieldButton from './ClearFieldButton/ClearFieldButton';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export interface GeneralChipSelectProps {
  data: string[];
  id: string;
  handleSelectedValues: (values: string[], id: string) => void;
  handleClear: (id: string) => void;
  values: string[];
  labelText?: string;
  label?: string;
  placeholder?: string;
  isRequired?: boolean | false;
}

const GeneralChipSelect: FC<GeneralChipSelectProps> = ({
  data,
  id,
  values,
  labelText,
  handleSelectedValues,
  handleClear,
  label,
  placeholder,
}) => {
  const [selectedParams, setSelectedParams] = useState<string[]>(values);

  useEffect(() => {
    setSelectedParams(values);
  }, [values]);

  const handleChange = (event: SelectChangeEvent<typeof selectedParams>) => {
    const {
      target: { value },
    } = event;
    handleSelectedValues(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
      id,
    );
  };

  const handleDelete = (value: string) => {
    const selectedList = selectedParams.filter((item) => item !== value);
    handleSelectedValues(selectedList, id);
  };

  return (
    <>
      <InputLabel
        id={`${id}-label`}
        htmlFor={`${id}-input-id`}
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          fontFamily: 'Lato',
          marginBottom: '6px',
          color: '#242424',
          fontSize: '.9rem',
        }}
      >
        {labelText}
        <ClearFieldButton isVisible={values.length > 0} handleClear={handleClear} id={id} />
      </InputLabel>
      <FormControl sx={{ width: '100%' }}>
        <Select
          labelId={`${id}-label`}
          id={id}
          label={label ? '' : placeholder}
          placeholder={placeholder}
          inputProps={{ id: `${id}-input-id` }}
          multiple
          value={selectedParams}
          onChange={handleChange}
          onKeyDownCapture={(event) => {
            if (event.key === 'Backspace') {
              handleDelete(event.target.innerText);
            }
          }}
          MenuProps={MenuProps}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip
                  key={value}
                  label={value}
                  onKeyDownCapture={(event) => {
                    if (event.key === 'Backspace') {
                      handleDelete(value);
                    }
                  }}
                  onDelete={() => {}}
                  sx={{
                    borderRadius: '5px',
                    backgroundColor: colors.blue6,
                    fontFamily: 'Lato',
                    color: colors.white,
                    '&:focus-visible': {
                      background: 'none',
                      backgroundColor: colors.black,
                      outline: `4px double ${colors.blue6}`,
                    },
                    '&:hover': {
                      background: 'none',
                      backgroundColor: colors.black,
                    },
                  }}
                  deleteIcon={
                    <div
                      onMouseDown={(event: any) => {
                        event.stopPropagation();
                        handleDelete(value);
                      }}
                    >
                      <HighlightOffIcon sx={{ color: colors.white, padding: '0px' }} />
                    </div>
                  }
                />
              ))}
            </Box>
          )}
          classes={{
            iconOpen: styles.iconOpen,
          }}
          IconComponent={({ className }) => {
            className = className.replace('MuiSelect-iconOpen', '');
            return (
              <div className={`${className} ${styles.iconContainer}`}>
                <img src={chevronDown} alt="chevron down" />
              </div>
            );
          }}
          sx={{
            fontFamily: 'Lato',
            borderRadius: '3px',
            fontSize: '1rem',
            backgroundColor: colors.white,
          }}
        >
          {data.map((value, index) => (
            <MenuItem
              key={index}
              value={value}
              sx={{
                fontFamily: 'Lato',
                fontSize: '1rem',
              }}
            >
              {value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default GeneralChipSelect;
