import { FC } from 'react';
import styles from './AccordionDetails.module.scss';
import { ActionInterface } from '../../../../../types/Action.interface';
import dayjs from 'dayjs';
import BillDetailsWrapper from './BillDetailsWrapper';

interface ActionDetailsProps {
  actions: ActionInterface[];
}

const ActionDetails: FC<ActionDetailsProps> = ({ actions }) => {
  const reverseChronologicalActions = actions.slice().reverse();

  const actionsList = reverseChronologicalActions?.map((action: any, index: number) => {
    const date = dayjs(action.date);
    return (
      <li key={index}>
        <span>{date.format('YYYY-MM-DD')}</span>
        <span className={styles.actionText}>{` - ${action.action}`}</span>
      </li>
    );
  });

  return (
    <BillDetailsWrapper billDetailName="Actions" listCount={actions.length}>
      <ul className={styles.detailContent}>{actionsList}</ul>
    </BillDetailsWrapper>
  );
};

export default ActionDetails;
