import React, { FC, useState } from 'react';
import PrimaryButton from 'src/components/PrimaryButton/PrimaryButton';
import Input from 'src/components/Input/Input';
import GeneralLinkBody from 'src/components/GeneralLinkBody/GeneralLinkBody';
import styles from './AdminLoginForm.module.scss';
import aelLogo from 'src/assets/logos/ael-tracker-colored-text-logo.png';
import ReCAPTCHA from 'react-google-recaptcha';

interface AdminLoginFormProps {
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  loginForm: {
    email: string;
    password: string;
  };
  formError: {
    emailError: { error: boolean; message: string };
    passwordError: { error: boolean; message: string };
  };
  handleLogin: () => void;
  setShowPwRequestForm: React.Dispatch<boolean>;
}
const AdminLoginForm: FC<AdminLoginFormProps> = ({
  handleChange,
  loginForm,
  formError,
  handleLogin,
  setShowPwRequestForm,
}) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const handlePressEnter = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !isDisabled) {
      handleLogin();
    }
  };

  const handleCaptchaChange = (value: any) => {
    return value ? setIsDisabled(false) : setIsDisabled(true);
  };

  return (
    <div className={styles.formSection}>
      <img src={aelLogo} alt="AEL Tracker Logo" />
      <form>
        <Input
          error={formError.emailError.error}
          helperText={formError.emailError.message}
          handleChange={handleChange}
          value={loginForm.email}
          id="email"
          placeholder={'Email Address'}
          onKeyDown={handlePressEnter}
          autoFocus={true}
        />
        <Input
          error={formError.passwordError.error}
          helperText={formError.passwordError.message}
          handleChange={handleChange}
          type="password"
          value={loginForm.password}
          id="password"
          placeholder="Password"
          onKeyDown={handlePressEnter}
        />
        <ReCAPTCHA sitekey={`${process.env.REACT_APP_SITE_KEY}`} onChange={handleCaptchaChange} />
        <div className={styles.buttonLinkContainer}>
          <PrimaryButton disabled={isDisabled} onClick={handleLogin} className={styles.loginButton} text="Log In" />
          <GeneralLinkBody
            className={styles.forgotPassword}
            onClick={(e) => {
              setShowPwRequestForm(true);
            }}
          >
            Forgot Password?
          </GeneralLinkBody>
        </div>
      </form>
    </div>
  );
};

export default AdminLoginForm;
