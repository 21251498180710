import FilterTypeEnum from '../types/FilterType.enum';
import { GeneralSelectOption } from '../../../components/GeneralSelect/GeneralSelect';

export const createImportFilterOptions: GeneralSelectOption[] = [
  {
    value: FilterTypeEnum.REJECT_TITLE,
    label: 'Reject Title',
  },
  {
    value: FilterTypeEnum.REJECT_NUMBER,
    label: 'Reject Number',
  },
  {
    value: FilterTypeEnum.LIKELY_TITLE,
    label: 'Likely Title',
  },
];
