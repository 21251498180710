import { gql } from '@apollo/client';

export const COMPANION_OPTIONS_QUERY = gql`
  query ($companionsOf: ID!, $companionOptions: EprcBillsInput) {
    companionBills(id: $companionsOf) {
      billId
    }
    companionOptions: bills(input: $companionOptions) {
      yearFiled
      billId
      state
      osBillId
      number
      osCreatedAt
      title
      osSessionId
    }
  }
`;

export const SPOT_POLICY_SUBCATEGORIES_QUERY = gql`
  query ($filter: PolicySubcategoryFilter) {
    policySubcategories(filter: $filter) {
      id
      title
    }
  }
`;
