import { gql } from '@apollo/client';

export const COMPANION_BILLS_QUERY = gql`
  query ($companionsOf: ID!) {
    companionBills(id: $companionsOf) {
      billId
      changeHash
      osState
      osBillId
      apiUrl
      osChamber
      osSession
      osSessionId
      lsMigrated
      apiIgnore
      timeStampCreation
      timeStampUpdate
      timeStampNewsGoogle
      timeStampNewsYahoo
      timeStampOsCheck
      autoTimeOsUpdate
      userCreation
      userUpdate
      title
      chamberName
      number
      state
      yearFiled
      enacted
      osDatePassedLower
      osDatePassedUpper
      osDateSigned
      osStatus
      osStatusId
      opposition
      displayType
      keywords
      topic
      policyCategory
      subcategory
      summary
      schedule
      clearedSuspicious
      sponsorships {
        id
        type
        order
        person {
          id
          name
          party
        }
      }
    }
  }
`;
