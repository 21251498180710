import TimeOptions from '../../../../types/TimeOptions.enum';
import dayjs from 'dayjs';

function getFilterDate(currentTimeFilter: string) {
  switch (currentTimeFilter) {
    case TimeOptions.DAY:
      return dayjs().subtract(1, 'day');
    case TimeOptions.WEEK:
      return dayjs().subtract(1, 'week');
    case TimeOptions.MONTH:
      return dayjs().subtract(1, 'month');
  }
  return dayjs();
}

export default getFilterDate;
