import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import GeneralSelect from 'src/components/GeneralSelect/GeneralSelect';
import Heading from 'src/components/Heading/Heading';
import HeadingStyle from 'src/components/Heading/HeadingStyle.enum';
import { UpdateAccountPublic } from 'src/services/AuthService';
import { checkboxStyling } from 'src/theme/checkboxStyling';
import { TrackerFrequency, TrackerFrequencies } from 'src/types/TrackerFrequency.enum';
import EditButton from '../EditButton/EditButton';
import styles from './NotificationPreferences.module.scss';

interface NotificationPreferencesProps {
  userData: any;
}

const NotificationPreferences: FC<NotificationPreferencesProps> = ({ userData }) => {
  const [isEditDisabled, setIsEditDisabled] = useState(true);
  const [preferences, setPreferences] = useState({
    trackerFrequency: TrackerFrequency.NEVER,
    trackerActions: false,
    trackerVersions: false,
    trackerVotes: false,
    trackerStatus: false,
  });

  useEffect(() => {
    const frequency: TrackerFrequency = TrackerFrequency[userData.trackerFrequency as keyof typeof TrackerFrequency];
    setPreferences({
      ...userData,
      trackerFrequency: frequency || TrackerFrequency.NEVER,
      trackerActions: userData.trackerActions || false,
      trackerVersions: userData.trackerVersions || false,
      trackerVotes: userData.trackerVotes || false,
      trackerStatus: userData.trackerStatus || false,
    });
  }, [userData]);

  const handleEdit = () => {
    setIsEditDisabled(!isEditDisabled);
  };

  const handleCancel = () => {
    setIsEditDisabled(true);
  };

  const handleSubmit = async () => {
    const body = { ...preferences, trackerFrequency: preferences.trackerFrequency.toUpperCase(), id: userData.id };

    await UpdateAccountPublic(body);
    toast.success('Update successful', {
      position: toast.POSITION.TOP_CENTER,
    });

    setIsEditDisabled(true);
  };

  const handleChange = (e: any) => {
    const { name, checked } = e.target;

    setPreferences({ ...preferences, [name]: checked });
  };

  const handleChangeSelect = (e: any) => {
    const { name, value } = e.target;

    setPreferences({ ...preferences, [name]: value });
  };

  return (
    <div className={styles.preferenceSection}>
      <div className={styles.headingContainer}>
        <Heading className={styles.heading} headingStyle={HeadingStyle.CONTAINER_SUBTITLE}>
          Notification Preferences
        </Heading>
        <EditButton
          isEditDisabled={isEditDisabled}
          handleEdit={handleEdit}
          handleCancel={handleCancel}
          checkForm={handleSubmit}
        />
      </div>
      <div className={styles.optionContainer}>
        <span> How often would you like to receive notifications via email?</span>
        <div className={styles.dropdownContainer}>
          <GeneralSelect
            labelText="Email Frequency"
            disabled={isEditDisabled}
            data={TrackerFrequencies}
            id={'trackerFrequency'}
            value={preferences.trackerFrequency}
            handleChange={handleChangeSelect}
          />
        </div>
      </div>
      <div className={styles.optionContainer}>
        <span>What would you like to trigger a notification for any given bill?</span>
        <FormGroup>
          <div className={styles.notificationOptions}>
            <FormControlLabel
              className={styles.checkboxLabel}
              control={
                <Checkbox
                  disableRipple
                  sx={checkboxStyling}
                  disabled={isEditDisabled}
                  checked={preferences.trackerActions}
                  onChange={handleChange}
                  name="trackerActions"
                />
              }
              label={<span className={styles.label}>New Actions</span>}
            />
            <FormControlLabel
              className={styles.checkboxLabel}
              control={
                <Checkbox
                  disableRipple
                  sx={checkboxStyling}
                  disabled={isEditDisabled}
                  checked={preferences.trackerVersions}
                  onChange={handleChange}
                  name="trackerVersions"
                />
              }
              label={<span className={styles.label}>New Versions</span>}
            />
            <FormControlLabel
              className={styles.checkboxLabel}
              control={
                <Checkbox
                  disableRipple
                  sx={checkboxStyling}
                  disabled={isEditDisabled}
                  checked={preferences.trackerStatus}
                  onChange={handleChange}
                  name="trackerStatus"
                />
              }
              label={<span className={styles.label}>New Status</span>}
            />
            <FormControlLabel
              className={styles.checkboxLabel}
              control={
                <Checkbox
                  disableRipple
                  sx={checkboxStyling}
                  disabled={isEditDisabled}
                  checked={preferences.trackerVotes}
                  onChange={handleChange}
                  name="trackerVotes"
                />
              }
              label={<span className={styles.label}>New Votes</span>}
            />
          </div>
        </FormGroup>
      </div>
    </div>
  );
};

export default NotificationPreferences;
