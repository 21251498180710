import React, { useState, createContext } from 'react';
import { UserData } from 'src/types/UserData.interface';

export interface GlobalContextProps {
  authenticated: boolean | string;
  setAuthenticated: React.Dispatch<boolean> | React.Dispatch<string>;
  userData?: UserData;
  setUserData: React.Dispatch<any>;
  authModalOpen: boolean;
  setAuthModalOpen: React.Dispatch<boolean>;
  loggedOutBillTracked: number;
  setLoggedOutBillTracked: React.Dispatch<any>;
  myTrackerTimeFilter: string;
  setMyTrackerTimeFilter: React.Dispatch<string>;
  recentBillChangesSection: boolean;
  setRecentBillChangesSection: React.Dispatch<boolean>;
  notificationPreferencesSection: boolean;
  setNotificationPreferencesSection: React.Dispatch<boolean>;
}

export const GlobalContext = createContext<any>(null);

export const useGlobalContext = (): GlobalContextProps => {
  const [authenticated, setAuthenticated] = useState('loading');
  const [authModalOpen, setAuthModalOpen] = useState(false);
  const [loggedOutBillTracked, setLoggedOutBillTracked] = useState<number>(0);
  const [myTrackerTimeFilter, setMyTrackerTimeFilter] = useState('');
  const [recentBillChangesSection, setRecentBillChangesSection] = useState(false);
  const [notificationPreferencesSection, setNotificationPreferencesSection] = useState(false);
  const [userData, setUserData] = useState<UserData>();
  return {
    authenticated,
    setAuthenticated,
    userData,
    setUserData,
    authModalOpen,
    setAuthModalOpen,
    loggedOutBillTracked,
    setLoggedOutBillTracked,
    myTrackerTimeFilter,
    setMyTrackerTimeFilter,
    recentBillChangesSection,
    setRecentBillChangesSection,
    notificationPreferencesSection,
    setNotificationPreferencesSection,
  };
};
