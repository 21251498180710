import {FC, useCallback, useContext, useEffect, useRef, useState} from 'react';
import styles from './MyTracker.module.scss';
import BreadCrumbs from 'src/components/BreadCrumbs/BreadCrumbs';
import { updateTabTitle } from 'src/utils/updateTabTitle';
import PageTitles from 'src/types/PageTitles.enum';
import MyTrackerHeader from './MyTrackerHeader';
import BillChangesSection from './BillChangesSection/BillChangesSection';
import AccountPrefSection from './AccountPrefSection/AccountPrefSection';
import {BillDistributionTabs} from 'src/components/BillDistributionTabs/BillDistributionTabs';
import {useMutation, useQuery} from '@apollo/client';
import BillCard from 'src/components/BillCards/BillCard';
import { mapResponseToBill } from 'src/utils/mapResponseToBill.util';
import { BillData } from "../../types/BillData.interface";
import {UserTrackerBillsData, UserTrackerBillsInput} from './UserTracker.interface';
import { CsvDownloadSection } from '../../components/CsvDownloadSection/CsvDownloadSection';
import { UserData } from '../../types/UserData.interface';
import { toast } from 'react-toastify';
import {ADD_BILL_MYTRACKER, USER_TRACKER_BILLS_QUERY} from './UserTracker.graphql';
import { GlobalContext } from 'src/hooks/useGlobalContext';
import {Box, Grid, Pagination} from '@mui/material';
import BillSearch from '../BillAdministration/BillSearch/BillSearch';
import {BillDistributionInterface} from "../../types/BillDistribution.interface";
import { PolicyEnum } from "../../types/Policy.enum";
import {StateEnum} from "../../types/State.enum";
import {StatusEnum} from "../../types/Status.enum";
import * as React from "react";
import {debounce} from "lodash";

interface MyTrackerProps {
  userData: UserData;
  loggedOutBillTracked: number;
}

const scrollToRef = (ref: React.RefObject<HTMLDivElement>) => {
  ref.current?.scrollIntoView({
    behavior: 'smooth',
  });
};

export const UserTracker: FC<MyTrackerProps> = ({ loggedOutBillTracked, userData }) => {
  updateTabTitle(PageTitles.MYTRACKER);

  const { setLoggedOutBillTracked, setRecentBillChangesSection, setNotificationPreferencesSection } = useContext(GlobalContext);

  const [addTrackedBill] = useMutation(ADD_BILL_MYTRACKER);

  const topOfBills = useRef<null | HTMLDivElement>(null);
  const changesRef = useRef<HTMLDivElement>(null);
  const preferencesRef = useRef<HTMLDivElement>(null);

  const [billsData, setBillsData] = useState<BillData[]>([]);
  const [keywords, setKeywords] = useState<string>('');
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [billsCount, setBillsCount] = useState(0);
  const [policyDistribution, setPolicyDistribution] = useState<BillDistributionInterface[]>([]);
  const [statusDistribution, setStatusDistribution] = useState<BillDistributionInterface[]>([]);
  const [stateDistribution, setStateDistribution] = useState<BillDistributionInterface[]>([]);

  // eslint-disable-next-line
  const debouncedSetKeywords = useCallback(debounce(v => {setKeywords(v)}, 1000), []);

  const { refetch: refetchBills } = useQuery<UserTrackerBillsData, UserTrackerBillsInput>(USER_TRACKER_BILLS_QUERY, {
    onCompleted(data) {
      setBillsData(data.userBills.map(mapResponseToBill));
      setPolicyDistribution(data.policyDistribution)
      setStatusDistribution(data.statusDistribution)
      setStateDistribution(data.stateDistribution)
      setBillsCount(data.billsCount)
      setPageCount(Math.ceil(data.billsCount / pageSize))
    }
  })

  const handleLoggedOutBillTracked = async () => {
    if (loggedOutBillTracked && userData?.id) {
      await addTrackedBill({ variables: { userId: userData?.id, billId: loggedOutBillTracked } })
      toast.success('Successfully added bill to MyTracker', {
        position: toast.POSITION.TOP_CENTER,
      });
      setLoggedOutBillTracked(0);
      await refetchBills();
    }
  };

  const updateBillList = () => {
    if (userData?.id) {
      refetchBills({
        pageNumber: pageNumber - 1,
        pageSize,
        keywords,
        user: userData.id
      })
    }
  }

  useEffect(() => {
    handleLoggedOutBillTracked();
    // eslint-disable-next-line
  }, [loggedOutBillTracked, userData?.id]);

  useEffect(() => {
    updateBillList();
    // eslint-disable-next-line
  }, [pageNumber, keywords, userData]);

  useEffect(() => {
    updateBillList();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      window.location.href.indexOf('day') > -1 ||
      window.location.href.indexOf('week') > -1 ||
      window.location.href.indexOf('month') > -1
    ) {
      scrollToRef(changesRef);
      setRecentBillChangesSection(false);
    }
  }, [setRecentBillChangesSection, billsData]);

  useEffect(() => {
    if (window.location.href.indexOf('notification-preferences') > -1) {
      scrollToRef(preferencesRef);
      setNotificationPreferencesSection(false);
    }
  }, [setNotificationPreferencesSection, billsData]);

  return (
    <>
      <BreadCrumbs />
      <MyTrackerHeader userBillsDataLength={billsCount} />
      <div className={styles.trackerContainer}>
        <BillDistributionTabs
            policyDistribution={policyDistribution.map(({ group, count }) => ({ policy: group as PolicyEnum, value: count }))}
            stateDistribution={stateDistribution.map(({ group, count }) => ({ state: group as StateEnum, value: count }))}
            statusDistribution={statusDistribution.map(({ group, count }) => ({ status: group as StatusEnum, value: count }))}
        >
          <div className={styles.cardsContainer}>
            <Box component={Grid} container justifyContent="space-between" spacing={1}>
              <Grid item xs={12} lg={6}>
                <BillSearch
                  value={keywords}
                  handleSearch={(e) => {
                    setPageNumber(1);
                    debouncedSetKeywords(e.target.value)
                  }} />
              </Grid>
              <Grid item xs={12} lg={6}>
                <CsvDownloadSection />
              </Grid>
            </Box>
            <div ref={topOfBills} tabIndex={0} aria-label="top of bills"></div>
            {billsData.length ? (
              <>
                <section className={styles.cards}>
                  {billsData?.map((bill) => (
                    <BillCard
                      key={bill.billId}
                      billData={bill}
                      maxTitleLength={135}
                      refetchBills={refetchBills}
                      isTracked={true}
                      isInMyTracker={true}
                    />
                  ))}
                </section>
                {pageCount > 1 && <Pagination
                  page={pageNumber}
                  count={pageCount}
                  shape="rounded"
                  onChange={(e, n: number) => {
                    topOfBills.current?.focus();
                    setPageNumber(n)
                  }}/>}
              </>
            ) : (
              'You have not added any bills to your myTracker account.'
            )}
          </div>
        </BillDistributionTabs>
        <BillChangesSection billsData={billsData} sectionRef={changesRef} />
      </div>
      <AccountPrefSection sectionRef={preferencesRef} />
    </>
  );
};
