import {PendingBillInterface} from "../../../types/PendingBill.interface";
import {PendingBillFilterInterface} from "../../../types/PendingBillFilter.interface";
import {ApproveBillInputInterface} from "../../../types/ApproveBillInput.interface";

export interface PendingBillsQueryInterface {
  pendingBills: PendingBillInterface[]
  pendingBillsCount: number
}

export interface PendingBillsQueryVariableInterface {
  pendingBills: PendingBillFilterInterface
}

export interface ApprovePendingBillVariableInterface {
  input: ApproveBillInputInterface;
}

export enum PendingBillActions {
  APPROVE = 'APPROVE',
  REJECT = 'REJECT',
}
