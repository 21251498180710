import { FC } from 'react';
import styles from './VoteDetailCard.module.scss';
import {RollCallInterface} from "../../../../../types/RollCall.interface";
import {chamberText} from "../../../../../const/chamberText";

interface VoteDetailCardProps {
  voteData: RollCallInterface;
}

const VoteDetailCard: FC<VoteDetailCardProps> = ({ voteData }) => {
  const passed = voteData.passed ? 'Yes' : 'No';
  return (
    <div className={styles.card}>
      <CardSection name="Date" content={voteData.date} />
      <CardSection name="Motion" content={voteData.description} isMotion={true} />
      <CardSection name="Chamber" content={chamberText[voteData.chamber]} />
      <CardSection name="Yes Votes" content={voteData.yea} />
      <CardSection name="No Votes" content={voteData.nay} />
      <CardSection name="Other Votes" content={voteData.total - (voteData.yea + voteData.nay)} />
      <CardSection name="Passed" content={passed} />
    </div>
  );
};

function CardSection({ name, content, isMotion }: { name: string; content?: string | number; isMotion?: boolean }) {
  return (
    <div className={`${styles.cardSection} ${isMotion && styles.motion}`}>
      <span className={styles.title}>{name}</span>
      <span>{content}</span>
    </div>
  );
}

export default VoteDetailCard;
