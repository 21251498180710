import { gql } from '@apollo/client';

export const MYTRACKER_HEADING_TEXT_QUERY = gql`
  query {
    myTrackerHeadingContentCollection {
      items {
        descriptionText
      }
    }
  }
`;