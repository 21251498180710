import { FC, ReactNode } from 'react';
import { AccordionDetails } from '@mui/material';

interface BillDetailsWrapperProps {
  children: ReactNode;
  listCount: number;
  billDetailName: string;
}

const BillDetailsWrapper: FC<BillDetailsWrapperProps> = ({ children, listCount, billDetailName }) => {
  return <AccordionDetails>{listCount > 0 ? children : <span>No {billDetailName} Found</span>}</AccordionDetails>;
};

export default BillDetailsWrapper;
