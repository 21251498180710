import { FC, useRef } from 'react';
import BillCard from './BillCard';
import styles from './BillCards.module.scss';
import { BillData } from "../../types/BillData.interface";
import {Pagination} from "@mui/material";
import * as React from "react";

interface BillCardsProps {
  bills: BillData[];
  refetchBills: () => void;
  page: number;
  pageCount: number,
  onPageChange: (n: number) => void;
  trackedBillIds: string[];
}

const BillCards: FC<BillCardsProps> = ({ bills, trackedBillIds, refetchBills, page, pageCount, onPageChange }) => {
  const topOfBills = useRef<null | HTMLDivElement>(null);

  const handlePaginationChange = (event: React.ChangeEvent<unknown>, page: number) => {
    onPageChange(page);
    if (topOfBills.current) {
      topOfBills.current.focus();
    }
  }

  return (
    <div className={styles.cardsContainer}>
      <div ref={topOfBills} tabIndex={0} aria-label="top of bills"></div>
      <section className={styles.cards}>
        {bills.map((bill, index) => (
          <BillCard
              key={index}
              billData={bill}
              maxTitleLength={135}
              isTracked={trackedBillIds.includes(bill.id)}
              refetchBills={refetchBills}
          />
        ))}
      </section>
      {pageCount > 1 && <Pagination
        page={page}
        count={pageCount}
        shape="rounded"
        onChange={handlePaginationChange}/>}
    </div>
  );
};

export default BillCards;
