export const mobileTab= {
  tabWidth: '80px',
  fontSize: '.65rem',
  tabHighlightWidth: '40px'
}

export const desktopTab= {
  tabWidth: '150px',
  fontSize: '.9rem',
  tabHighlightWidth: '90px'
}

export const largeDesktopTab= {
  tabWidth: '200px',
  fontSize: '1.2rem',
  tabHighlightWidth: '140px'
}

