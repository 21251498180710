import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GetUser } from './services/AuthService';
import { GlobalContext, useGlobalContext } from './hooks/useGlobalContext';
import { ProtectedRoute } from './services/ProtectedRoute';
import styles from './App.module.scss';
import CneeHomeNav from './components/CneeHomeNav/CneeHomeNav';
import MainNav from './components/MainNav/MainNav';
import DrawerMenu from './components/DrawerMenu/DrawerMenu';
import AelBanner from './components/AelBanner/AelBanner';
import SiteFooter from './components/SiteFooter/SiteFooter';
import StyleGuide from './pages/StyleGuide/StyleGuide';
import MyTracker from './pages/MyTracker/MyTracker';
import TrendsAndAnalysis from './pages/TrendsAndAnalysis/TrendsAndAnalysis';
import BillDetails from './pages/BillDetails/BillDetails';
import {BillAdministration} from './pages/BillAdministration/BillAdministration';
import MapsAndGraphs from './pages/MapsAndGraphs/MapsAndGraphs';
import FilterConfig from './pages/FilterConfig/FilterConfig';
import About from './pages/About/About';
import Landing from './pages/Landing/Landing';
import Faqs from './pages/Faqs/Faqs';
import {UserAccountManagement} from './pages/UserAccountManagement/UserAccountManagement';
import Logout from './pages/Logout/Logout';
import Contact from './pages/Contact/Contact';
import { Logout as logout } from 'src/services/AuthService';
import {PolicyDetail} from "./pages/PolicyDetail/PolicyDetail";

library.add(fab as any);

function App(): React.ReactElement {
  const userDataContext = useGlobalContext();
  const { authenticated, setAuthenticated, setUserData, loggedOutBillTracked, setAuthModalOpen } = userDataContext;
  const [drawerOpen, setDrawerOpened] = useState(false);

  // Set Authentication status and User Data on initial App Load
  useEffect(() => {
    GetUser(setAuthenticated, setUserData);
    if (authenticated === false) {
      logout(setAuthenticated, setUserData);
    }
  }, [authenticated, setAuthenticated, setUserData]);

  return (
    <Router>
      <GlobalContext.Provider value={userDataContext}>
        <div id={styles.mainWrapper}>
          <a className={styles.skipNavOption} href="#maincontent">
            Skip to main content
          </a>
          <CneeHomeNav drawerOpen={drawerOpen} setDrawerOpened={setDrawerOpened} />
          <MainNav />
          <AelBanner />
          <div id="maincontent" className={styles.mainContent}>
            <div className={styles.innerContent}>
              <Routes>
                <Route path="/" element={<Landing />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/activate/:activationId" element={<Landing />} />
                <Route path="/unsubscribe/:uuid" element={<Landing />} />
                <Route path="/password/reset/:resetToken" element={<Landing />} />
                <Route path="/styleguide" element={<StyleGuide />} />
                <Route path="/faqs" element={<Faqs />} />
                <Route path="/about" element={<About />} />
                <Route path="/about/:policy" element={<PolicyDetail />} />
                <Route path="/maps-graphs" element={<MapsAndGraphs />} />
                <Route path="/bill-details/:id" element={<BillDetails />} />
                <Route path="/trends-analysis" element={<TrendsAndAnalysis />} />
                <Route
                  path="/mytracker"
                  element={
                    <ProtectedRoute setModalOpen={setAuthModalOpen}>
                      <MyTracker loggedOutBillTracked={loggedOutBillTracked} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/mytracker/:timeFilter"
                  element={
                    <ProtectedRoute setModalOpen={setAuthModalOpen}>
                      <MyTracker loggedOutBillTracked={loggedOutBillTracked} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/filter-config"
                  element={
                    <ProtectedRoute setModalOpen={setAuthModalOpen}>
                      <FilterConfig />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/bill-admin"
                  element={
                    <ProtectedRoute setModalOpen={setAuthModalOpen}>
                      <BillAdministration />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/user-account-management"
                  element={
                    <ProtectedRoute setModalOpen={setAuthModalOpen}>
                      <UserAccountManagement />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/logout"
                  element={
                    <ProtectedRoute setModalOpen={setAuthModalOpen}>
                      <Logout />
                    </ProtectedRoute>
                  }
                />
              </Routes>
            </div>
          </div>
          <SiteFooter />
          <DrawerMenu drawerOpen={drawerOpen} setDrawerOpened={setDrawerOpened} />
        </div>
        <ToastContainer className={styles.toast} />
      </GlobalContext.Provider>
    </Router>
  );
}

export default App;
