import React, { FC, useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import styles from './BillDetailsTab.module.scss';
import { colors, gradients } from 'src/theme/colors.js';
import useScreenSize from 'src/hooks/useScreenSize';
import Breakpoint from 'src/types/BreakPoint.enum';
import { desktopTab, largeDesktopTab, mobileTab } from 'src/theme/tabSizing';
import BillInnerCard from '../BillInnerCard/BillInnerCard';
import { BillData } from "../../../types/BillData.interface";
import ActionDetails from './BillDetailsAccordion/AccordionDetails/ActionDetails';
import { Accordion } from './BillDetailsAccordion/BillDetailsAccordionTheme';
import AccordionTitle from './BillDetailsAccordion/AccordionTitle';
import StatusDetails from './BillDetailsAccordion/AccordionDetails/StatusDetails';
import VoteDetails from './BillDetailsAccordion/AccordionDetails/VoteDetails';
import VersionDetails from './BillDetailsAccordion/AccordionDetails/VersionDetails';
import SponsorDetails from './BillDetailsAccordion/AccordionDetails/SponsorDetails';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const useStyles = makeStyles((theme: any) => ({
  tab: {
    '&:hover,&:focus-visible': {
      textDecoration: 'underline',
      color: colors.white,
      background: gradients.medGray,
      fontWeight: '600',
    },

    '&.Mui-selected': {
      background: colors.lightGray7,
      color: colors.darkGray1,
      fontWeight: '600',
      '&:hover': {
        textDecoration: 'underline',
        color: colors.darkGray1,
      },
      '&:focus-visible': {
        background: gradients.lightGray,
      },
    },
  },
}));

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      className={styles.tabPanel}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface BillDetailsTabProps {
  currentBillDetails: BillData;
}

const BillDetailsTab: FC<BillDetailsTabProps> = ({ currentBillDetails }) => {
  const [value, setValue] = useState(0);
  const sponsorsRef = useRef<HTMLDivElement>(null);
  const [sponsorsAccordionExpanded, setSponsorsAccordionExpanded] = useState<boolean>(false);
  const { screenSizeIsAtLeast } = useScreenSize();
  const classes = useStyles();
  const tabClasses = { root: classes.tab };
  const tabSize = screenSizeIsAtLeast[Breakpoint.BASE] ? desktopTab : mobileTab;
  const tabStyles = {
    background: gradients.lightGray,
    color: colors.black,
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '8px',
    minWidth: screenSizeIsAtLeast[Breakpoint.LG] ? largeDesktopTab.tabWidth : tabSize.tabWidth,
    marginRight: '8px',
    minHeight: screenSizeIsAtLeast[Breakpoint.LG] ? '60px' : '50px',
    fontFamily: 'Lato',
    textTransform: 'none',
    fontSize: screenSizeIsAtLeast[Breakpoint.LG] ? largeDesktopTab.fontSize : tabSize.fontSize,
    '&:hover,&:focus-visible': {
      textDecortation: 'underline',
    },
  };

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const scrollToSponsorsAccordion = () => {
    if (sponsorsRef) sponsorsRef?.current?.scrollIntoView({ behavior: 'smooth' });
    setSponsorsAccordionExpanded(true);
  };

  const toggleSponsorsAccordion = () => {
    setSponsorsAccordionExpanded(!sponsorsAccordionExpanded);
  };

  const statusChanges = {
    createdAt: currentBillDetails.createdAt,
    datePassedLower: currentBillDetails.datePassedLower,
    datePassedUpper: currentBillDetails.datePassedUpper,
    dateSigned: currentBillDetails.dateSigned,
  };
  const statusCount = Object.values(statusChanges).filter(item => item != null).length;

  return (
    <>
      <Box sx={{ width: '100%', marginTop: '45px' }}>
        <Box sx={{ borderColor: 'divider' }}>
          <Tabs
            value={value}
            textColor="inherit"
            TabIndicatorProps={{
              style: {
                background: colors.medGray1,
                width: screenSizeIsAtLeast[Breakpoint.LG]
                  ? largeDesktopTab.tabHighlightWidth
                  : tabSize.tabHighlightWidth,
                marginLeft: '30px',
                height: '1px',
              },
            }}
            onChange={handleChange}
            aria-label="National Distribution of Bills Tabs"
          >
            <Tab label="Bill Details" disableFocusRipple classes={tabClasses} {...a11yProps(0)} sx={tabStyles} />
          </Tabs>
          <TabPanel value={value} index={0}>
            <BillInnerCard currentBillDetails={currentBillDetails} scrollToSponsors={scrollToSponsorsAccordion} />
            <h3 id={styles.timelineHeading}>Bill Timeline and Actions</h3>
            <Accordion>
              <AccordionTitle title="Actions" count={currentBillDetails?.actions?.length ?? 0} />
              <ActionDetails actions={currentBillDetails?.actions ?? []} />
            </Accordion>
            <div ref={sponsorsRef}></div>
            <Accordion
              expanded={sponsorsAccordionExpanded}
              onClick={toggleSponsorsAccordion}
              onKeyDown={(e) => {
                if (e.key === 'Enter') toggleSponsorsAccordion();
              }}
            >
              <AccordionTitle
                title="Sponsors"
                count={
                  (currentBillDetails?.sponsors?.primary?.length ?? 0) +
                  (currentBillDetails?.sponsors?.other?.length ?? 0)
                }
              />
              <SponsorDetails
                primary={currentBillDetails?.sponsors?.primary ?? []}
                other={currentBillDetails?.sponsors?.other ?? []}
              />
            </Accordion>
            <Accordion>
              <AccordionTitle title="Status Changes" count={statusCount} />
              <StatusDetails
                statusChanges={statusChanges}
                currentStatus={currentBillDetails.status}
                lastUpdated={currentBillDetails.lastUpdated} />
            </Accordion>
            <Accordion>
              <AccordionTitle title="Votes" count={currentBillDetails?.rollCalls?.length ?? 0} />
              <VoteDetails votes={currentBillDetails?.rollCalls ?? []} />
            </Accordion>
            <Accordion>
              <AccordionTitle title="Versions" count={currentBillDetails?.docs?.length ?? 0} />
              <VersionDetails versions={currentBillDetails?.docs ?? []} />
            </Accordion>
          </TabPanel>
        </Box>
      </Box>
    </>
  );
};

export default BillDetailsTab;
