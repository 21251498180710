import {gql} from "@apollo/client";

export const ADMIN_USERS_QUERY = gql`
  query ($usersInput: UsersInput) {
    usersCount(input: $usersInput)
    users(input: $usersInput) {
      id,
      createdDate,
      lastLogin,
      firstName,
      lastName,
      email,
      role,
      active
    }
  }
`;
