import React, { FC, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import styles from './BillDistributionTabs.module.scss';
import { colors, gradients, policyColors, statusColors } from 'src/theme/colors.js';
import useScreenSize from 'src/hooks/useScreenSize';
import Breakpoint from 'src/types/BreakPoint.enum';
import MapSection from 'src/components/MapSection/MapSection';
import {StackedBarChart} from '../StackedBarChart/StackedBarChart';
import { STACKED_BAR_TEXT } from './const/stackedBarText';
import {StateEnum} from "../../types/State.enum";
import {PolicyEnum} from "../../types/Policy.enum";
import {StatusEnum} from "../../types/Status.enum";
import {policyText} from "../../const/policyText";
import {statusText} from "../../const/statusText";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface StateDistribution {
  state: StateEnum;
  value: number;
}

interface PolicyDistribution {
  policy: PolicyEnum;
  value: number;
}

interface StatusDistribution {
  status: StatusEnum;
  value: number;
}

interface GenericTabsProps {
  children?: React.ReactNode;
  stateDistribution: StateDistribution[];
  policyDistribution: PolicyDistribution[];
  statusDistribution: StatusDistribution[];
}

const useStyles = makeStyles((theme: any) => ({
  tab: {
    '&:hover,&:focus-visible': {
      textDecoration: 'underline',
      color: colors.white,
      background: gradients.medGray,
      fontWeight: '600',
    },

    '&.Mui-selected': {
      background: colors.lightGray7,
      color: colors.darkGray1,
      fontWeight: '600',
      '&:hover': {
        textDecoration: 'underline',
        color: colors.darkGray1,
      },
      '&:focus-visible': {
        background: gradients.lightGray,
      },
    },
  },
}));

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      className={styles.tabPanel}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const BillDistributionTabs: FC<GenericTabsProps> = ({
  children,
  stateDistribution,
  statusDistribution,
  policyDistribution
}) => {
  const [value, setValue] = useState(0);
  const { screenSizeIsAtLeast } = useScreenSize();
  const classes = useStyles();
  const tabClasses = { root: classes.tab };

  const tabStyles = {
    background: gradients.lightGray,
    color: colors.black,
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '8px',
    minWidth: screenSizeIsAtLeast[Breakpoint.LG] ? '200px' : '150px',
    marginRight: '8px',
    minHeight: screenSizeIsAtLeast[Breakpoint.LG] ? '60px' : '50px',
    fontFamily: 'Lato',
    textTransform: 'none',
    fontSize: screenSizeIsAtLeast[Breakpoint.LG] ? '1.2rem' : '.9rem',
    '&:hover,&:focus-visible': {
      textDecortation: 'underline',
    },
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const policyItems = policyDistribution.map(v => ({
    id: v.policy,
    label: policyText[v.policy],
    color: policyColors[v.policy],
    value: v.value,
  })).sort((f, s) => f.label.localeCompare(s.label))

  const statusItemsLookup = Object.fromEntries(statusDistribution
    .map(v => [v.status, {
      id: v.status,
      label: statusText[v.status],
      color: statusColors[v.status],
      value: v.value,
    }]))

  const statusItems = [
    StatusEnum.INTRODUCED,
    StatusEnum.PASSED_ONE_CHAMBER,
    StatusEnum.PASSED_BOTH_CHAMBERS,
    StatusEnum.ENACTED,
    StatusEnum.FAILED,
    StatusEnum.VETOED,
  ]
    .filter(k => Object.hasOwn(statusItemsLookup, k))
    .map(s => statusItemsLookup[s]);

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderColor: 'divider' }}>
          <Tabs
            value={value}
            textColor="inherit"
            TabIndicatorProps={{
              style: {
                background: colors.medGray1,
                width: screenSizeIsAtLeast[Breakpoint.LG] ? '140px' : '90px',
                marginLeft: '30px',
                height: '1px',
              },
            }}
            onChange={handleChange}
            aria-label="Bills and Visualizations Tabs"
          >
            <Tab label="Bills" disableFocusRipple classes={tabClasses} {...a11yProps(0)} sx={tabStyles} />
            <Tab label="Visualizations" disableFocusRipple classes={tabClasses} {...a11yProps(1)} sx={tabStyles} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <div className={styles.billsTab}>{children}</div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <MapSection
              items={stateDistribution}
              className={styles.visualizationBordered} />
          <StackedBarChart
              title={STACKED_BAR_TEXT[0].title}
              description={STACKED_BAR_TEXT[0].description}
              items={policyItems}
              className={styles.visualizationBordered} />
          <StackedBarChart
              title={STACKED_BAR_TEXT[1].title}
              description={STACKED_BAR_TEXT[1].description}
              items={statusItems} />
        </TabPanel>
      </Box>
    </>
  );
};
