import React, {FC, useState} from 'react';
import { makeStyles } from '@mui/styles';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import CircleIcon from '@mui/icons-material/Circle';
import styles from './PolicyCategoryMap.module.scss';
import { colors, gradients, policyColors } from 'src/theme/colors.js';
import ReactTooltip from 'react-tooltip';
import GradientLegend from '../../../components/MapSection/GradientLegend/GradientLegend';
import useScreenSize from 'src/hooks/useScreenSize';
import Breakpoint from 'src/types/BreakPoint.enum';
import HeadingStyle from 'src/components/Heading/HeadingStyle.enum';
import Heading from 'src/components/Heading/Heading';
import { desktopTab, largeDesktopTab, mobileTab } from 'src/theme/tabSizing';
import { BillCountTable } from 'src/components/MapSection/BillCountTable/BillCountTable';
import { MapSkipLink } from 'src/components/MapSection/MapSkipLink/MapSkipLink';
import {StateEnum} from "../../../types/State.enum";
import {GradientMap} from "../../../components/GradientMap/GradientMap";
import {useQuery} from "@apollo/client";
import {POLICY_TOTALS_QUERY} from "./PolicyCategoryMap.graphql";
import {PolicyTotalsQueryResult} from "./PolicyCategoryMap.interface";
import {PolicyEnum} from "../../../types/Policy.enum";
import {BillDistributionInterface} from "../../../types/BillDistribution.interface";
import {policyText} from "../../../const/policyText";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const useStyles = makeStyles((theme: any) => ({
  tab: {
    '&:hover,&:focus-visible': {
      textDecoration: 'underline',
      color: colors.white,
      background: gradients.medGray,
      fontWeight: '600',
    },

    '&.Mui-selected': {
      background: colors.lightGray7,
      color: colors.darkGray1,
      fontWeight: '600',
      '&:hover': {
        textDecoration: 'underline',
        color: colors.darkGray1,
      },
      '&:focus-visible': {
        background: gradients.lightGray,
      },
    },
  },
}));

const TabPanel: FC<TabPanelProps> = ({ children, value, index, ...other }) => (
  <div
    role="tabpanel"
    className={styles.tabPanel}
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}
  >
    {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
  </div>
);

const a11yProps = (index: number) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
})

export const PolicyCategoryMap = () => {
  const [policyCategoryValue, setPolicyCategoryValue] = useState(0);
  const [content, setContent] = useState('');
  const [isHighlighted, setIsHighlighted] = useState<StateEnum|undefined>();
  const { screenSizeIsAtLeast } = useScreenSize();
  const classes = useStyles();
  const tabClasses = { root: classes.tab };
  const tabSize = screenSizeIsAtLeast[Breakpoint.BASE] ? desktopTab : mobileTab;

  const { data: policyTotals } = useQuery<PolicyTotalsQueryResult>(POLICY_TOTALS_QUERY);

  if (!policyTotals) return (<></>)

  const tabStyles = {
    background: gradients.lightGray,
    color: colors.black,
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '8px',
    minWidth: screenSizeIsAtLeast[Breakpoint.LG] ? largeDesktopTab.tabWidth : tabSize.tabWidth,
    marginRight: '8px',
    minHeight: screenSizeIsAtLeast[Breakpoint.LG] ? '60px' : '50px',
    fontFamily: 'Lato',
    textTransform: 'none',
    fontSize: screenSizeIsAtLeast[Breakpoint.LG] ? largeDesktopTab.fontSize : tabSize.fontSize,
    '&:hover,&:focus-visible': {
      textDecortation: 'underline',
    },
  };

  return (
    <>
      <Heading className={styles.tabHeading} headingStyle={HeadingStyle.PAGE_SUBTITLE}>
        National Distribution of Bills by Policy Category
      </Heading>
      <Box sx={{ borderColor: 'divider' }}>
        <Tabs
          value={policyCategoryValue}
          textColor="inherit"
          variant="scrollable"
          scrollButtons={true}
          allowScrollButtonsMobile
          TabIndicatorProps={{
            style: {
              background: colors.medGray1,
              height: '1px',
            },
          }}
          onChange={(e, v: number) => {setPolicyCategoryValue(v)}}
          aria-label="National Distribution of Bills Tabs by Policy Category"
          sx={{
            [`& .${tabsClasses.scrollButtons}`]: {
              paddingTop: '10px',
              '&.Mui-disabled': { opacity: 0.3, display: screenSizeIsAtLeast[Breakpoint.BASE] ? 'inherit' : 'none' },
            },
          }}
        >
          {Object.entries<BillDistributionInterface[]>(policyTotals).map(([policy], index) => (
            <Tab
              key={index}
              value={index}
              icon={<CircleIcon sx={{ margin: '0', padding: '0', color: policyColors[policy as PolicyEnum] }} />}
              iconPosition="start"
              label={policyText[policy as PolicyEnum]}
              disableFocusRipple
              classes={tabClasses}
              {...a11yProps(index)}
              sx={tabStyles}
            />
          ))}
        </Tabs>
      </Box>
      {Object.entries(policyTotals).map(([policy, distribution], index) =>  (
        <TabPanel key={index} value={policyCategoryValue} index={index}>
          <div className={styles.policyCategoryMapSection}>
            <Heading headingStyle={HeadingStyle.CONTAINER_TITLE} className={styles.heading}>
              {policyText[policy as PolicyEnum]} Bills by State
            </Heading>
            <p className={styles.description}>Select a state below to see the state's bill count.</p>
            <MapSkipLink id={`maptop-${policy}`} to={`#mapbottom-${policy}`} text="To bottom of map" />
            <span className={styles.billCountLabel}>{content}</span>
            <BillCountTable
              items={distribution.map(v => ({ state: v.group as StateEnum, value: v.count }))}
              onHighlight={setIsHighlighted}
              onTooltipChange={setContent}
            />
            <GradientMap
              items={distribution.map(v => ({ state: v.group as StateEnum, value: v.count }))}
              highlightedState={isHighlighted}
              onHighlight={setIsHighlighted}
              onTooltipChange={setContent} />
            <ReactTooltip className={styles.toolTip}>{content}</ReactTooltip>
            <GradientLegend values={distribution.map(v => v.count)} />
            <MapSkipLink id={`mapbottom-${policy}`} to={`#maptop-${policy}`} text="To top of map" />
          </div>
        </TabPanel>
      ))}
    </>
  );
};
