import React, { DetailedHTMLProps, FC, HTMLAttributes } from 'react';
import Card from '@mui/material/Card';
import { colors } from 'src/theme/colors';

export interface BasicCardProps extends DetailedHTMLProps<HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement> {
  className?: string;
}

const BasicCard: FC<BasicCardProps> = ({ children, id, ...rest }) => {
  return (
    <Card
      className={rest.className}
      sx={{
        width: '100%',
        backgroundColor: colors.lightGray7,
        borderRadius: 2,
        padding: 3,
        boxShadow: 'none',
      }}
      id={id || undefined}
    >
      <div>{children}</div>
    </Card>
  );
};

export default BasicCard;
