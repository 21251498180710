import {PolicyEnum} from "../types/Policy.enum";

export const policyText = {
  [PolicyEnum.ECONOMIC]: 'Economic Development',
  [PolicyEnum.ELECTRICITY]: 'Electricity Generation',
  [PolicyEnum.EMISSIONS]: 'Emissions',
  [PolicyEnum.ENERGY]: 'Energy Efficiency',
  [PolicyEnum.FINANCING]: 'Financing and Financial Incentives',
  [PolicyEnum.INFRASTRUCTURE]: 'Infrastructure',
  [PolicyEnum.GAS]: 'Natural Gas Development',
  [PolicyEnum.REGULATORY]: 'Regulatory',
  [PolicyEnum.TRANSPORTATION]: 'Transportation',
  [PolicyEnum.OTHER]: 'Other Energy',
}

export function getPolicyEnumfromString(policyString: string) {
  if (!policyString) return null;
  return Object.entries(policyText)
    .filter(([key, val]) => val === policyString)
    .map(([key, val]) => PolicyEnum[key as keyof typeof PolicyEnum])
    .reduce((c, v) => v);
}
