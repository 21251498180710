import {StatusEnum} from "../types/Status.enum";

export const statusText = {
  [StatusEnum.INTRODUCED]: 'Introduced',
  [StatusEnum.PASSED_ONE_CHAMBER]: 'Passed One Chamber',
  [StatusEnum.PASSED_BOTH_CHAMBERS]: 'Passed Both Chambers',
  [StatusEnum.ENACTED]: 'Enacted',
  [StatusEnum.FAILED]: 'Failed',
  [StatusEnum.VETOED]: 'Vetoed',
}

export const getStatusEnumfromString = (statusString: string) => {
  return Object.entries(statusText)
    .filter(([key, val]) => val === statusString)
    .map(([key, val]) => StatusEnum[key as keyof typeof StatusEnum])
    .reduce((c, v) => v);
}
