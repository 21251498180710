import emissionsIcon from 'src/assets/icons/emissions.svg';
import economicIcon from 'src/assets/icons/economic-development.svg';
import regulatoryIcon from 'src/assets/icons/regulatory.svg';
import financingIcon from 'src/assets/icons/financing.svg';
import electrictyIcon from 'src/assets/icons/electricity-generation.svg';
import energyIcon from 'src/assets/icons/energy-efficiency.svg';
import naturalGasIcon from 'src/assets/icons/natural-gas.svg';
import infrastructureIcon from 'src/assets/icons/infrastructure.svg';
import transportationIcon from 'src/assets/icons/transportation.svg';
import otherEnergyIcon from 'src/assets/icons/other-energy.svg';
import { PolicyEnum } from '../types/Policy.enum'

export const policyIcon = {
  [PolicyEnum.ECONOMIC]: economicIcon,
  [PolicyEnum.ELECTRICITY]: electrictyIcon,
  [PolicyEnum.EMISSIONS]: emissionsIcon,
  [PolicyEnum.ENERGY]: energyIcon,
  [PolicyEnum.FINANCING]: financingIcon,
  [PolicyEnum.INFRASTRUCTURE]: infrastructureIcon,
  [PolicyEnum.GAS]: naturalGasIcon,
  [PolicyEnum.REGULATORY]: regulatoryIcon,
  [PolicyEnum.TRANSPORTATION]: transportationIcon,
  [PolicyEnum.OTHER]: otherEnergyIcon,
};
