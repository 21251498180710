import { TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { FC } from 'react';
import { colors } from 'src/theme/colors';

export interface BillSearchProps {
  value: string;
  handleSearch: (e: { target: { value: string } }) => void;
}

const BillSearch: FC<BillSearchProps> = ({ handleSearch, value }) => {
  return (
    <TextField
      defaultValue={value}
      onChange={handleSearch}
      label="Search by Keyword"
      variant="outlined"
      sx={{
        width: '100%',
        fontFamily: 'Lato',
        backgroundColor: colors.white,
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default BillSearch;
