import { FC, useContext } from 'react';
import styles from './AccountPrefSection.module.scss';
import BasicCard from 'src/components/BasicCard/BasicCard';
import Heading from 'src/components/Heading/Heading';
import HeadingStyle from 'src/components/Heading/HeadingStyle.enum';
import AccountInfo from './AccountInfo/AccountInfo';
import { GlobalContext } from 'src/hooks/useGlobalContext';
import NotificationPreferences from './NotificationPreferences/NotificationPreferences';

interface AccountPrefSectionProps {
  sectionRef: React.RefObject<HTMLDivElement>;
}

const AccountPrefSection: FC<AccountPrefSectionProps> = ({ sectionRef }) => {
  const { userData } = useContext(GlobalContext);

  return (
    <section id="accountManage" ref={sectionRef}>
      <Heading headingStyle={HeadingStyle.CONTAINER_TITLE}>My Account & Preferences</Heading>
      <div className={styles.accountPrefContainer}>
        <BasicCard className={styles.accountCard}>
          <AccountInfo userData={userData} />
        </BasicCard>
        <BasicCard className={styles.accountCard}>
          <NotificationPreferences userData={userData} />
        </BasicCard>
      </div>
    </section>
  );
};

export default AccountPrefSection;
