export enum SubcategoryEnum {
  AGRICULTURE = 'AGRICULTURE',
  APPLIANCE_EQUIPMENT_STANDARDS = 'APPLIANCE_EQUIPMENT_STANDARDS',
  AUTONOMOUS_VEHICLES = 'AUTONOMOUS_VEHICLES',
  BIOFUEL = 'BIOFUEL',
  BIOMASS = 'BIOMASS',
  BONDS = 'BONDS',
  CELA = 'CELA',
  CLIMATE = 'CLIMATE',
  COMMISSION_MEMBERSHIP = 'COMMISSION_MEMBERSHIP',
  CONNECTING = 'CONNECTING',
  DISCLOSURE = 'DISCLOSURE',
  DISTRIBUTED_SOLAR_GENERATION = 'DISTRIBUTED_SOLAR_GENERATION',
  ECONOMIC_DEVELOPMENT = 'ECONOMIC_DEVELOPMENT',
  ELECTRICITY_GENERATION = 'ELECTRICITY_GENERATION',
  EMISSIONS = 'EMISSIONS',
  ENERGY_COST_ASSISTANCE = 'ENERGY_COST_ASSISTANCE',
  ENERGY_EFFICIENCY = 'ENERGY_EFFICIENCY',
  ENERGY_STAR_APPLIANCES = 'ENERGY_STAR_APPLIANCES',
  ENVIRONMENTAL_JUSTICE = 'ENVIRONMENTAL_JUSTICE',
  FINANCING = 'FINANCING',
  FRACKING = 'FRACKING',
  FUEL_CELL = 'FUEL_CELL',
  GEOTHERMAL = 'GEOTHERMAL',
  GRANT_PROGRAM = 'GRANT_PROGRAM',
  GREEN_JOBS = 'GREEN_JOBS',
  HEALTH_IMPACTS = 'HEALTH_IMPACTS',
  HYDROPOWER = 'HYDROPOWER',
  IMPACTS = 'IMPACTS',
  INFRASTRUCTURE = 'INFRASTRUCTURE',
  JOB_SKILLS_TRAINING = 'JOB_SKILLS_TRAINING',
  LANDOWNER_EFFECTS = 'LANDOWNER_EFFECTS',
  LEAD_BY_EXAMPLE = 'LEAD_BY_EXAMPLE',
  LEASING_ADJUSTMENTS_FOR_STATE_PROPERTY = 'LEASING_ADJUSTMENTS_FOR_STATE_PROPERTY',
  LEV = 'LEV',
  LOANS = 'LOANS',
  LOANS_AND_GRANTS = 'LOANS_AND_GRANTS',
  LOAN_PROGRAM = 'LOAN_PROGRAM',
  LOW_INCOME_ENERGY_ASSISTANCE = 'LOW_INCOME_ENERGY_ASSISTANCE',
  LOW_INCOME_RENEWABLE_ENERGY = 'LOW_INCOME_RENEWABLE_ENERGY',
  METERING = 'METERING',
  METERS = 'METERS',
  METHANE = 'METHANE',
  MICROGRID = 'MICROGRID',
  MINERAL_ESTATE = 'MINERAL_ESTATE',
  MODIFY = 'MODIFY',
  MORATORIUM = 'MORATORIUM',
  NATURAL_GAS = 'NATURAL_GAS',
  NATURAL_GAS_DEVELOPMENT = 'NATURAL_GAS_DEVELOPMENT',
  NET_METERING = 'NET_METERING',
  NEW_OFFICE = 'NEW_OFFICE',
  NOTICE = 'NOTICE',
  NUCLEAR = 'NUCLEAR',
  OTHER_ENERGY = 'OTHER_ENERGY',
  OVERSIGHT = 'OVERSIGHT',
  PACE = 'PACE',
  PIPELINE = 'PIPELINE',
  POOLING = 'POOLING',
  PROCEDURAL_DIRECTION = 'PROCEDURAL_DIRECTION',
  PRODUCTION_BY_PRODUCTS = 'PRODUCTION_BY_PRODUCTS',
  PURCHASING = 'PURCHASING',
  RATES = 'RATES',
  REGULATORY = 'REGULATORY',
  RENEWABLES = 'RENEWABLES',
  RENEWABLE_PORTFOLIO_STANDARD = 'RENEWABLE_PORTFOLIO_STANDARD',
  RESEARCH_AND_DEVELOPMENT = 'RESEARCH_AND_DEVELOPMENT',
  RESOURCE_PLANNING = 'RESOURCE_PLANNING',
  RPS = 'RPS',
  SALES_TAX = 'SALES_TAX',
  SBC = 'SBC',
  SECURITIZATION = 'SECURITIZATION',
  SEVERANCE_PRODUCTION_TAXES = 'SEVERANCE_PRODUCTION_TAXES',
  SEVERANCE_TAXES = 'SEVERANCE_TAXES',
  SITING = 'SITING',
  SMART_GRID = 'SMART_GRID',
  SMART_METERS = 'SMART_METERS',
  SOLAR = 'SOLAR',
  SPLIT_ESTATE = 'SPLIT_ESTATE',
  STATE_PRE_EMPTION = 'STATE_PRE_EMPTION',
  STORAGE = 'STORAGE',
  STUDIES = 'STUDIES',
  TAXATION = 'TAXATION',
  TAX_CREDITS = 'TAX_CREDITS',
  TAX_INCENTIVES = 'TAX_INCENTIVES',
  THIRD_PARTY_LEASING = 'THIRD_PARTY_LEASING',
  TIDAL = 'TIDAL',
  TOLL_DISCOUNT = 'TOLL_DISCOUNT',
  TRANSITION = 'TRANSITION',
  TRANSMISSION = 'TRANSMISSION',
  TRANSPORTATION = 'TRANSPORTATION',
  UTILITY_PAYMENT_FOR_ELECTRICITY_GENERATION = 'UTILITY_PAYMENT_FOR_ELECTRICITY_GENERATION',
  WATER = 'WATER',
  WIND = 'WIND',
  WIND_HYDROPOWER = 'WIND_HYDROPOWER',
  ZEV = 'ZEV',
}
