import { gql } from '@apollo/client';

export const POLICY_QUERY = gql`
  query($key: String) {
    aelPolicyDescriptionPageCollection (where: {
      key: $key
    }) {
      items {
        name,
        description {
          json
        },
        key
      }
    }
  }
`;
