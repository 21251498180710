export type CsvColumn = {
  property: string;
  label: string;
};

export const billColumns: CsvColumn[] = [
  {
    property: 'billId',
    label: 'bill_id',
  },
  {
    property: 'state',
    label: 'state',
  },
  {
    property: 'number',
    label: 'number',
  },
  {
    property: 'yearFiled',
    label: 'year_filed',
  },
  {
    property: 'title',
    label: 'title',
  },
  {
    property: 'osStatus',
    label: 'os_status',
  },
  {
    property: 'osStatusId',
    label: 'os_status_id',
  },
  {
    property: 'policyCategory',
    label: 'policy_category',
  },
  {
    property: 'subcategory',
    label: 'subcategory',
  },
  {
    property: 'topic',
    label: 'topic',
  },
  {
    property: 'summary',
    label: 'summary',
  },
  {
    property: 'autoTimeOsUpdate',
    label: 'updated',
  },
  {
    property: 'osCreatedAt',
    label: 'created',
  },
];
