import { gql } from '@apollo/client';

export const FOOTER_QUERY = gql`
query {
  footerTextCollection(limit:1) {
    items {
      copyrightYear
      copyrightText
      copyrightLink
      address
      learnMoreLinksCollection{
        items{
          text
          externalLink
        }
      }
      socialMediaLinksCollection{
        items {
          socialMediaIconLabel
          iconName
          socialMediaLink
        }
      }
    }
  }
}
`;