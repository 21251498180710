import { FC, useContext, useState } from 'react';
import styles from './AuthorizationModal.module.scss';
import { Login } from 'src/services/AuthService';
import LoginForm from './loginForm/LoginForm';
import NewAccountForm from './newAccountForm/NewAccountForm';
import { GlobalContext } from 'src/hooks/useGlobalContext';
import { useNavigate } from 'react-router-dom';
import GeneralModal from 'src/components/GeneralModal/GeneralModal';
import { validateEmail } from 'src/utils/validateEmail';
import { PasswordResetForm } from './PasswordResetForm/PasswordResetForm';
import { PasswordRequestForm } from './PasswordRequestForm/PasswordRequestForm';

interface AuthorizationModalProps {
  showPwResetForm: boolean;
  userForReset: { email: string; passwordResetToken: string };
}

const AuthorizationModal: FC<AuthorizationModalProps> = ({ showPwResetForm, userForReset }) => {
  const {
    setAuthenticated,
    setUserData,
    authModalOpen,
    setAuthModalOpen,
    myTrackerTimeFilter,
    setMyTrackerTimeFilter,
  } = useContext(GlobalContext);
  const navigate = useNavigate();
  const [renderCreateAcct, setRenderCreateAcct] = useState(false);
  const [showPwRequestForm, setShowPwRequestForm] = useState(false);
  const [loginForm, setLoginForm] = useState({
    email: '',
    password: '',
  });
  const [formError, setFormError] = useState({
    emailError: { error: false, message: '' },
    passwordError: { error: false, message: '' },
  });
  const handleClose = () => {
    setAuthModalOpen(false);
    setLoginForm({ email: '', password: '' });
    setRenderCreateAcct(false);
    setShowPwRequestForm(false);
  };

  const handleChange = (e: any) => {
    const { id, value } = e.target;
    if (formError) {
      setFormError({
        emailError: { error: false, message: '' },
        passwordError: { error: false, message: '' },
      });
    }
    setLoginForm({ ...loginForm, [id]: value });
  };

  const handleLogin = async () => {
    const isEmailValid = validateEmail(loginForm.email);
    if (!isEmailValid) {
      return setFormError({ ...formError, emailError: { error: true, message: 'Email not vaild' } });
    }
    try {
      await Login(loginForm, setAuthenticated, setUserData, handleClose);
      navigate(`/mytracker/${myTrackerTimeFilter.toLowerCase()}`);
      setMyTrackerTimeFilter('');
    } catch (e) {
      setFormError({
        emailError: { error: true, message: 'Incorrect username or password. Please try again.' },
        passwordError: { error: true, message: 'Incorrect username or password. Please try again.' },
      });
    }
  };

  return (
    <GeneralModal modalOpen={authModalOpen} onClose={handleClose}>
      <div className={styles.modalContent}>
        {!renderCreateAcct && !showPwRequestForm && !showPwResetForm ? (
          <LoginForm
            handleChange={handleChange}
            handleLogin={handleLogin}
            loginForm={loginForm}
            formError={formError}
            setRenderCreateAcct={setRenderCreateAcct}
            setShowPwRequestForm={setShowPwRequestForm}
          />
        ) : !renderCreateAcct && !showPwResetForm && showPwRequestForm ? (
          <PasswordRequestForm setRenderCreateAcct={setRenderCreateAcct} />
        ) : !renderCreateAcct && !showPwRequestForm && showPwResetForm ? (
          <PasswordResetForm userForReset={userForReset} handleClose={handleClose} />
        ) : (
          <NewAccountForm handleClose={handleClose} />
        )}
      </div>
    </GeneralModal>
  );
};

export default AuthorizationModal;
