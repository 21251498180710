import React, { useState, useEffect, ReactElement, useContext } from 'react';
import GeneralLinkBody from 'src/components/GeneralLinkBody/GeneralLinkBody';
import styles from './MenuItemsDesktop.module.scss';
import { NavigationItems } from 'src/types/NavigationItems.interface.js';
import triangleDown from 'src/assets/svg/triangle-down.svg';
import { useNavigate } from 'react-router-dom';
import { GlobalContext } from 'src/hooks/useGlobalContext';

export interface MenuItemsProps {
  item: NavigationItems;
}

const MenuItemsDesktop = ({ item }: MenuItemsProps): ReactElement => {
  const navigate = useNavigate();
  const [menuCollapsed, setMenuCollapsed] = useState(false);
  const { userData } = useContext(GlobalContext);

  useEffect(() => {
    if (menuCollapsed) {
      setMenuCollapsed(false);
    }
    // eslint-disable-next-line
  }, []);

  //If Link does not have any nested sublinks, Display just the link.
  // If nested sublinks, Display the submenus of these nav items

  const handleKeyDown = (e: any, item: any) => {
    if (e.key === 'Enter') {
      if (item.to) {
        navigate(item.to);
        setMenuCollapsed(true);
      } else if (item.href) {
        window.location.assign(item.href);
        setMenuCollapsed(true);
      }
    }
  };

  return (
    <>
      {!item.subItems && (
        <li tabIndex={0} role="button" onKeyDown={(e: any) => handleKeyDown(e, item)}>
          <GeneralLinkBody navigateTo={item.to} href={item.href}>
            {item.title}
          </GeneralLinkBody>
        </li>
      )}
      {item.subItems && (
        <li tabIndex={0} onMouseOver={() => setMenuCollapsed(false)} role="button">
          <GeneralLinkBody>
            {item.title}
            <img src={triangleDown} alt="" />
          </GeneralLinkBody>

          {/* Submenu 1  - depth 1 */}
          <ul id={menuCollapsed ? 'menuHidden' : ''}>
            <div className={styles.goldLine}></div>
            {item.subItems.map((child, index) => {
              if (!child.role || (child.role && child.role.indexOf(userData.role) > -1))
                return (
                  <li
                    role="button"
                    key={child.title}
                    tabIndex={0}
                    onClick={() => setMenuCollapsed(true)}
                    onKeyDown={(e) => handleKeyDown(e, child)}
                  >
                    <GeneralLinkBody navigateTo={child.to || undefined} href={child.href || undefined}>
                      {child.title}
                    </GeneralLinkBody>

                    {/* Submenu 2 - depth 2  */}

                    {child.subItems ? (
                      <ul id={menuCollapsed ? 'menuHidden' : ''}>
                        {child.subItems.map((subItem, index) => (
                          <li
                            key={index}
                            tabIndex={0}
                            onClick={() => setMenuCollapsed(true)}
                            onKeyDown={(e) => handleKeyDown(e, child)}
                          >
                            <GeneralLinkBody navigateTo={subItem.to || undefined} href={subItem.href || undefined}>
                              {subItem.title}
                            </GeneralLinkBody>
                          </li>
                        ))}
                      </ul>
                    ) : null}
                  </li>
                );
              return <></>;
            })}
          </ul>
        </li>
      )}
    </>
  );
};

export default MenuItemsDesktop;
