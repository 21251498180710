import { FC, useEffect, useState } from 'react';
import styles from './AccountInfo.module.scss';
import GeneralSelect from 'src/components/GeneralSelect/GeneralSelect';
import Heading from 'src/components/Heading/Heading';
import HeadingStyle from 'src/components/Heading/HeadingStyle.enum';
import Input from 'src/components/Input/Input';
import EditButton from '../EditButton/EditButton';
import { UpdateAccountPublic } from 'src/services/AuthService';
import { validateEmail } from 'src/utils/validateEmail';
import { organizationTypes } from 'src/const/organizationTypes';
import { OrganizationTypeMap } from 'src/common/interfaces/organizationTypeMap';
import { toast } from 'react-toastify';

interface AccountInfoProps {
  userData: any;
}

const AccountInfo: FC<AccountInfoProps> = ({ userData }) => {
  const [isEditDisabled, setIsEditDisabled] = useState(true);
  const [formError, setFormError] = useState({
    emailError: { error: false, message: '' },
    passwordError: { error: false, message: '' },
  });
  const [acctForm, setAcctForm] = useState({
    firstName: '',
    lastName: '',
    email: '',
    organization: '',
    organizationType: 'Select an Organization Type',
    newPassword: '',
    confirmNewPassword: '',
  });

  const orgTypes: OrganizationTypeMap = { ...organizationTypes };

  function getKeyByValue(object: any, value: any) {
    return Object.keys(object).find((key) => object[key] === value);
  }

  useEffect(() => {
    setAcctForm({
      ...acctForm,
      firstName: userData.firstName || '',
      lastName: userData.lastName || '',
      email: userData.email || '',
      organization: userData.organization || '',
      organizationType: getKeyByValue(organizationTypes, userData.organizationType) || 'Select an Organization Type',
      newPassword: '',
      confirmNewPassword: '',
    });
    // eslint-disable-next-line
  }, [userData]);

  const handleEdit = () => {
    setIsEditDisabled(!isEditDisabled);
  };

  const handleCancel = () => {
    setFormError({
      emailError: { error: false, message: '' },
      passwordError: { error: false, message: '' },
    });
    setAcctForm({
      ...acctForm,
      firstName: userData.firstName || '',
      lastName: userData.lastName || '',
      email: userData.email || '',
      organization: userData.organization || '',
      organizationType: getKeyByValue(organizationTypes, userData.organizationType) || 'Select an Organization Type',
      newPassword: '',
      confirmNewPassword: '',
    });
    setIsEditDisabled(true);
  };

  const checkForm = () => {
    const isChangingEmail = acctForm.email !== userData.email;
    const isChangingPassword = acctForm.newPassword.length > 0 || acctForm.confirmNewPassword.length > 0;
    if (isChangingEmail) {
      const isEmailValid = validateEmail(acctForm.email);
      return isEmailValid
        ? handleSubmit()
        : setFormError({ ...formError, emailError: { error: true, message: 'Email must be valid' } });
    } else if (isChangingPassword) {
      const isMatch = acctForm.newPassword === acctForm.confirmNewPassword;
      return isMatch
        ? handleSubmit()
        : setFormError({ ...formError, passwordError: { error: true, message: 'Passwords need to match' } });
    } else {
      return handleSubmit();
    }
  };

  const handleSubmit = async () => {
    const body = { ...acctForm, id: userData.id, organizationType: orgTypes[acctForm.organizationType] };

    await UpdateAccountPublic(body);
    toast.success('Update successful', {
      position: toast.POSITION.TOP_CENTER,
    });

    setIsEditDisabled(true);
  };

  const handleChange = (e: any) => {
    const { id, value } = e.target;
    if (formError) {
      setFormError({
        emailError: { error: false, message: '' },
        passwordError: { error: false, message: '' },
      });
    }
    setAcctForm({ ...acctForm, [id]: value });
  };

  const handleChangeSelect = (e: any) => {
    const { name, value } = e.target;
    if (formError) {
      setFormError({
        emailError: { error: false, message: '' },
        passwordError: { error: false, message: '' },
      });
    }
    setAcctForm({ ...acctForm, [name]: value });
  };

  return (
    <>
      <div className={styles.headingContainer}>
        <Heading className={styles.heading} headingStyle={HeadingStyle.CONTAINER_SUBTITLE}>
          Account Information
        </Heading>
        <EditButton
          isEditDisabled={isEditDisabled}
          handleEdit={handleEdit}
          handleCancel={handleCancel}
          checkForm={checkForm}
        />
      </div>
      <form>
        <div className={styles.inputsContainer}>
          <div className={styles.input}>
            <Input
              disabled={isEditDisabled}
              handleChange={handleChange}
              label="First Name"
              value={acctForm.firstName}
              id="firstName"
              placeholder={'First Name'}
            />
          </div>
          <div className={styles.input}>
            <Input
              disabled={isEditDisabled}
              handleChange={handleChange}
              label="Last Name"
              value={acctForm.lastName}
              id="lastName"
              placeholder={'Last Name'}
            />
          </div>
        </div>
        <div className={styles.inputsContainer}>
          <div className={styles.input}>
            <Input
              disabled={isEditDisabled}
              handleChange={handleChange}
              label="Organization"
              value={acctForm.organization}
              id="organization"
              placeholder={'Organization'}
            />
          </div>
          <div className={styles.input}>
            <GeneralSelect
              disabled={isEditDisabled}
              labelText="Organization Type"
              id="organizationType"
              handleChange={handleChangeSelect}
              value={acctForm.organizationType}
              placeholder="Select Organization Type"
              data={Object.keys(organizationTypes)}
            />
          </div>
        </div>
        <div className={styles.inputsContainerEmail}>
          <div className={styles.input}>
            <Input
              error={formError.emailError.error}
              helperText={formError.emailError.message}
              disabled={isEditDisabled}
              handleChange={handleChange}
              label="Email Address"
              value={acctForm.email}
              id="email"
              placeholder={'Email Address'}
            />
          </div>
        </div>
        <div className={styles.inputsContainer}>
          <div className={styles.input}>
            <Input
              autoComplete="new-password"
              error={formError.passwordError.error}
              helperText={formError.passwordError.message}
              disabled={isEditDisabled}
              handleChange={handleChange}
              label="New Password"
              type="password"
              value={acctForm.newPassword}
              id="newPassword"
              placeholder={'Password'}
            />
          </div>
          <div className={styles.input}>
            <Input
              autoComplete="new-password"
              error={formError.passwordError.error}
              helperText={formError.passwordError.message}
              disabled={isEditDisabled}
              handleChange={handleChange}
              label="Confirm Password"
              type="password"
              value={acctForm.confirmNewPassword}
              id="confirmNewPassword"
              placeholder={'Confirm Password'}
            />
          </div>
        </div>
      </form>
    </>
  );
};

export default AccountInfo;
