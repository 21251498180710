import { FC } from 'react';
import styles from './LearnMoreLinks.module.scss';
import GeneralLinkBody from '../../GeneralLinkBody/GeneralLinkBody';

interface LearnMoreLinksProps {
  links: {
    text: string;
    externalLink?: string;
    internalLink?: string;
  }[];
}

const LearnMoreLinks: FC<LearnMoreLinksProps> = ({ links }) => {
  const learnLinks = links.map((linkItem, index) => {
    return (
      <div key={index}>
        <GeneralLinkBody
          href={linkItem.externalLink}
          navigateTo={linkItem.internalLink}
          className={styles.learnMoreLink}
        >
          {linkItem.text}
        </GeneralLinkBody>
      </div>
    );
  });

  return <div className={styles.learnMoreLinks}>{learnLinks}</div>;
};

export default LearnMoreLinks;
