import { gql } from '@apollo/client';

export const LEGLISLATIVE_LINK_QUERY = gql`
  query {
    legislativeScheduleLinkCollection {
      items {
        hyperLinkText
        legislativeSchedule {
          url
        }
      }
    }
  }
`;

// @todo Trim this down. We don't need all this data on the search page.
export const MAIN_SECTION_QUERY = gql`
  query (
    $inYearsFiled: [Int!]
    $inOsStates: [String!]
    $inOsStatuses: [String!]
    $likePolicyCategories: [String!]
    $likeKeywords: String
    $sortColumn: String
    $sortDirection: SortDirection
    $pageNumber: Int
    $pageSize: Int
    $user: Int
  ) {
    bills(
      input: {
        inYearsFiled: $inYearsFiled
        inOsStates: $inOsStates
        inOsStatuses: $inOsStatuses
        likePolicyCategories: $likePolicyCategories
        likeKeywords: $likeKeywords
        sortColumn: $sortColumn
        sortDirection: $sortDirection
        pageNumber: $pageNumber
        pageSize: $pageSize
      }
    ) {
      billId
      changeHash
      osState
      osBillId
      apiUrl
      osChamber
      osSession
      osSessionId
      lsMigrated
      apiIgnore
      timeStampCreation
      timeStampUpdate
      timeStampNewsGoogle
      timeStampNewsYahoo
      timeStampOsCheck
      autoTimeOsUpdate
      userCreation
      userUpdate
      title
      chamberName
      number
      state
      yearFiled
      enacted
      osDatePassedLower
      osDatePassedUpper
      osDateSigned
      osStatus
      osStatusId
      opposition
      displayType
      keywords
      topic
      policyCategory
      subcategory
      summary
      schedule
      clearedSuspicious
      cela
      sponsorships {
        id
        type
        order
        person {
          id
          name
          firstName
          lastName
          party
        }
      }
    },
    billsCount(
      input: {
        inYearsFiled: $inYearsFiled
        inOsStates: $inOsStates
        inOsStatuses: $inOsStatuses
        likePolicyCategories: $likePolicyCategories
        likeKeywords: $likeKeywords
        sortColumn: $sortColumn
        sortDirection: $sortDirection
      }
    ),
    policyDistribution: billsDistribution(
      input: {
        inYearsFiled: $inYearsFiled,
        inOsStates: $inOsStates,
        inOsStatuses: $inOsStatuses,
        likePolicyCategories: $likePolicyCategories,
        likeKeywords: $likeKeywords,
        groupBy: "policyCategory"
      }
    ) {
      group
      count
    },
    statusDistribution: billsDistribution(
      input: {
        inYearsFiled: $inYearsFiled,
        inOsStates: $inOsStates,
        inOsStatuses: $inOsStatuses,
        likePolicyCategories: $likePolicyCategories,
        likeKeywords: $likeKeywords,
        groupBy: "osStatus"
      }
    ) {
      group
      count
    },
    stateDistribution: billsDistribution(
      input: {
        inYearsFiled: $inYearsFiled,
        inOsStates: $inOsStates,
        inOsStatuses: $inOsStatuses,
        likePolicyCategories: $likePolicyCategories,
        likeKeywords: $likeKeywords,
        groupBy: "state"
      }
    ) {
      group
      count
    },
    trackedBills: bills(
      input: {
        user: $user
      }
    ) {
      billId
    }
  }
`;

export const BILL_COUNT = gql`
  query {
    billsCount
  }
`;
