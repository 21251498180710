import { FC } from 'react';
import { DocInterface } from 'src/types/Doc.interface';
import { TextTypeNameEnum } from 'src/types/TextTypeName.enum';
import styles from './AccordionDetails.module.scss';
import BillDetailsWrapper from './BillDetailsWrapper';

interface VersionDetailsProps {
  versions: DocInterface[];
}

const compareDocId = (a: any, b: any) => {
  return b.docId - a.docId;
};

const VersionDetails: FC<VersionDetailsProps> = ({ versions }) => {
  const versionsList = [...versions].sort(compareDocId).map((version: DocInterface, index: number) => {
    return (
      <li key={index}>
        <span>{TextTypeNameEnum[version.type]}</span>
        {' - '}
        <a href={version.url} target="_blank" rel="noreferrer">
          {'view bill version >'}
        </a>
      </li>
    );
  });

  return (
    <BillDetailsWrapper billDetailName="Versions" listCount={versionsList.length}>
      <ul className={styles.detailContent}>{versionsList}</ul>
    </BillDetailsWrapper>
  );
};

export default VersionDetails;
