import Heading from '../../components/Heading/Heading';
import HeadingStyle from '../../components/Heading/HeadingStyle.enum';
import styles from './FilterConfig.module.scss';
import React from 'react';

function FilterConfigPageTitle() {
  return (
    <>
      <Heading headingStyle={HeadingStyle.PAGE_TITLE} className={styles.header}>
        LegiScan Configuration
      </Heading>
      <span>There are three filter types:</span>
      <ol>
        <li className={styles.listItem}>
          <strong>Reject Title:</strong> Phrase in this filter will be compared to the LegiScan bill “title”. If a bill
          title contains any of these phrases (in their entirety, case insensitive) it will be rejected.
        </li>
        <li className={styles.listItem}>
          <strong>Reject Number:</strong> Phrases in this filter will be compared to the LegiScan bill “number”. If a
          bill number contains any of these phrases (in their entirety, case insensitive) it will be rejected.
        </li>
        <li className={styles.listItem}>
          <strong>Likely Title:</strong> Phrases in this filter will be compared to the LegiScan bill “title”. If a bill
          title contains any of these phrases (in their entirety, case insensitive) it will be marked as “likely”.
        </li>
      </ol>
    </>
  );
}

export default FilterConfigPageTitle;
