import { gql } from '@apollo/client';

// @todo Trim the fields down to only the ones needed for cache refresh.
export const REJECT_PENDING_BILL = gql`
  mutation ($id: ID!) {
    rejectBill(id: $id) {
      id
      status
      likely
      osBillId
      osSessionId
      changeHash
      apiUrl
      osChamber
      osSession
      osState
      osStatus
      osCreatedAt
      osUpdatedAt
      osSubjects
      title
      number
      autoAdded
      changeStatusAt
    }
  }
`;
