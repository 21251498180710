import { gql } from '@apollo/client';

export const ABOUT_QUERY = gql`
  query {
    aboutAelTrackerContentCollection {
      items {
        pageHeader
        introBodyText{
          json
        }
        aelDescriptionText{
          json
        }
        pageSubHeader
        outroBodyText {
          json
        }
      }
    }
  }
`;