import { FC } from 'react';
import Button from '@mui/material/Button';
import { ThemeProvider } from '@mui/material/styles';
import buttonTheme from 'src/theme/buttonTheme';
import greenGrid from 'src/assets/svg/color-grid-green.svg';
import styles from './IconButton.module.scss';
import { gradients } from '../../theme/colors';
import { colors } from '../../theme/colors';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface ButtonProps {
  onClick: () => void;
  disabled?: boolean;
  text: string;
}

const IconButton: FC<ButtonProps> = ({ text, onClick, disabled }) => {
  return (
    <ThemeProvider theme={buttonTheme}>
      <Button
        className={styles.buttonMuiOverride}
        variant="contained"
        onClick={onClick}
        disableElevation
        disableFocusRipple
        disabled={disabled ? disabled : false}
        sx={{
          padding: '10px 10px',
          margin: '5px 10px',
          borderRadius: '8px',
          background: gradients.medGreen,
          '&:hover': {
            background: 'none',
            backgroundColor: colors.limeGreen,
            transition: 'none',
            color: colors.darkGray1,
          },
          '&:focus-visible': {
            background: 'none',
            backgroundColor: colors.limeGreen,
            color: colors.darkGray1,
          },
        }}
      >
        <div className={styles.textIconContainer}>
          <div>{text}</div>
          <img src={greenGrid} alt="" />
        </div>
        <FontAwesomeIcon icon={faArrowRight} className={styles.arrow} />
      </Button>
    </ThemeProvider>
  );
};

export default IconButton;
