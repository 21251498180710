import { FC } from 'react';
import styles from './FaqSection.module.scss';
import FaqAccordion from './FaqAccordion/FaqAccordion';

const FaqSection: FC = () => {
  return (
    <section className={styles.faqSection}>
      <FaqAccordion />
    </section>
  );
};

export default FaqSection;
