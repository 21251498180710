import React, {FC, useContext, useEffect, useState} from 'react';
import BasicCard from 'src/components/BasicCard/BasicCard';
import GeneralLinkLarge from 'src/components/GeneralLinkLarge/GeneralLinkLarge';
import styles from './MainSection.module.scss';
import { useLazyQuery, useQuery } from '@apollo/client';
import { MAIN_SECTION_QUERY, LEGLISLATIVE_LINK_QUERY } from './MainSection.graphql';
import FiltersSection from '../FiltersSection/FiltersSection';
import GeneralSelect from 'src/components/GeneralSelect/GeneralSelect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort } from '@fortawesome/free-solid-svg-icons';
import contentfulClient from 'src/common/apolloClient';
import { FiltersTemplate } from '../FiltersSection/types/FilterTemplate.interface';
import { BillData } from "../../../types/BillData.interface";
import { mapResponseToBill } from '../../../utils/mapResponseToBill.util';
import {BillDistributionTabs} from 'src/components/BillDistributionTabs/BillDistributionTabs';
import Box from '@mui/material/Box/Box';
import {
  MainSectionQueryData,
  MainSectionQueryInput,
  SortTemplate
} from './MainSection.interface';
import { SortDirectionEnum } from 'src/types/SortDirection.enum';
import BillCards from "../../../components/BillCards/BillCards";
import {BillDistributionInterface} from "../../../types/BillDistribution.interface";
import {StateEnum} from "../../../types/State.enum";
import {PolicyEnum} from "../../../types/Policy.enum";
import {StatusEnum} from "../../../types/Status.enum";
import {GlobalContext} from "../../../hooks/useGlobalContext";
import _ from "lodash";
import {getPolicyEnumfromString} from "../../../const/policyText";
import {getStatusEnumfromString} from "../../../const/statusText";
import {getStateEnumfromString} from "../../../const/stateText";
import {Grid} from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

const defaultFilter: FiltersTemplate = {
  stateFilters: [],
  policyFilters: [],
  yearFilters: [new Date().getFullYear().toString()],
  statusFilters: [],
  search: '',
};

const BillSortArray: SortTemplate[] = [
  { display: 'Sort Bills By', column: 'osCreatedAt', direction: SortDirectionEnum.DESC },
  { display: 'State(s) (A-Z)', column: 'state', direction: SortDirectionEnum.ASC },
  { display: 'State(s) (Z-A)', column: 'state', direction: SortDirectionEnum.DESC },
  { display: 'Bill Number (A-Z)', column: 'number', direction: SortDirectionEnum.ASC },
  { display: 'Bill Number (Z-A)', column: 'number', direction: SortDirectionEnum.DESC },
  { display: 'Bill Status (A-Z)', column: 'osStatus', direction: SortDirectionEnum.ASC },
  { display: 'Bill Status (Z-A)', column: 'osStatus', direction: SortDirectionEnum.DESC },
  { display: 'Bill Title (A-Z)', column: 'title', direction: SortDirectionEnum.ASC },
  { display: 'Bill Title (Z-A)', column: 'title', direction: SortDirectionEnum.DESC },
  { display: 'Policy Category (A-Z)', column: 'policyCategory', direction: SortDirectionEnum.ASC },
  { display: 'Policy Category (Z-A)', column: 'policyCategory', direction: SortDirectionEnum.DESC },
  { display: 'Primary Sponsor (A-Z)', column: 'osCreatedAt', direction: SortDirectionEnum.DESC },
  { display: 'Primary Sponsor (Z-A)', column: 'osCreatedAt', direction: SortDirectionEnum.DESC },
  { display: 'Date Updated (Recent to Old)', column: 'autoTimeOsUpdate', direction: SortDirectionEnum.DESC },
  { display: 'Date Updated (Old to Recent)', column: 'autoTimeOsUpdate', direction: SortDirectionEnum.ASC },
  { display: 'Year (Low to High)', column: 'yearFiled', direction: SortDirectionEnum.ASC },
  { display: 'Year (High to Low)', column: 'yearFiled', direction: SortDirectionEnum.DESC },
];

const sortDropDownOptions = BillSortArray.map(s => s.display);

const findSortOption = (text: string) => BillSortArray.find(s => s.display === text);

const getStatesInputArray = (input: string[]): string[] | undefined => {
  const stateAbbrevArray = _.compact(input.map((e) => getStateEnumfromString(e)));
  return stateAbbrevArray.length > 0 ? stateAbbrevArray : undefined;
}

const getStatusInputArray = (input: string[]): string[] | undefined => {
  const statusAbbrevArray = _.compact(input.map((e) => getStatusEnumfromString(e)));
  return statusAbbrevArray.length > 0 ? statusAbbrevArray : undefined;
}

const getPolicyCategoriesInputArray = (input: string[]): string[] | undefined => {
  const policyCategoryArray = _.compact(input.map((e) => getPolicyEnumfromString(e)));
  return policyCategoryArray.length > 0 ? policyCategoryArray : undefined;
}

const sanitizeFilterParams = (params: FiltersTemplate) => {
  return {
    inOsStates: getStatesInputArray(params.stateFilters),
    likePolicyCategories: getPolicyCategoriesInputArray(params.policyFilters),
    inYearsFiled: params.yearFilters.length > 0 ? params.yearFilters.map((str: string) => parseInt(str)) : undefined,
    inOsStatuses: getStatusInputArray(params.statusFilters),
    likeKeywords: params.search,
  };
};

const ResultsCount:FC<{ count: Number }> = ({ count }) => (
  <Box>
    <p>
      This search found <strong>{`${count || 0} bills`}</strong>.
    </p>
  </Box>
);

const NoBillsFound = () => (
  <span>
    This search found 0 bills. Adjust your selected search criteria with at least one filter selected or{' '}
    <GeneralLinkLarge className={styles.searchErrorLink} navigateTo="/contact">
      request a bill
    </GeneralLinkLarge>{' '}
    to be added to the tracker.
  </span>
);

const MainSection: FC = () => {
  const { userData } = useContext(GlobalContext);

  const [bills, setBills] = useState<BillData[]>([]);
  const [total, setTotal] = useState(0);
  const [policyDistribution, setPolicyDistribution] = useState<BillDistributionInterface[]>([]);
  const [statusDistribution, setStatusDistribution] = useState<BillDistributionInterface[]>([]);
  const [stateDistribution, setStateDistribution] = useState<BillDistributionInterface[]>([]);
  const [filter, setFilter] = useState(defaultFilter);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [sortColumn, setSortColumn] = useState('osCreatedAt');
  const [sortDirection, setSortDirection] = useState(SortDirectionEnum.DESC);
  const [trackedBillIds, setTrackedBillIds] = useState<string[]>([])

  // Only used by a widget.
  const [orderBy, setOrderBy] = useState('Sort Bills By');

  const legislative = useQuery(LEGLISLATIVE_LINK_QUERY, { client: contentfulClient });

  const [getBills, { error: billError, loading, refetch: refetchBills }] = useLazyQuery<MainSectionQueryData, MainSectionQueryInput>(
    MAIN_SECTION_QUERY,
    {
      onCompleted(data) {
        // @todo Why is mapResponseToBill needed?
        setBills(data.bills.map(mapResponseToBill));
        setTotal(data.billsCount);
        setPolicyDistribution(data.policyDistribution);
        setStatusDistribution(data.statusDistribution);
        setStateDistribution(data.stateDistribution);
        setPageCount(Math.ceil(data.billsCount / pageSize))
        // This is a hack. Not supplying user to the query implies get bills not specific to user.
        // We need this value to be blank when there is no user.
        setTrackedBillIds(userData?.id ? data.trackedBills.map(v => v.billId) : [])
      },
    },
  );

  const updateBills = () => {
    if (loading) return;
    getBills({
      variables: {
        ...sanitizeFilterParams(filter),
        sortColumn,
        sortDirection,
        pageNumber: pageNumber - 1,
        pageSize,
        user: userData?.id
      }
    });
  }

  useEffect(() => {
    updateBills()
  // eslint-disable-next-line
  }, [pageNumber, userData]);

  const handleSort = (v: string): void => {
    setOrderBy(v);
    const options = findSortOption(v)

    if (options != null) {
      setSortColumn(options.column)
      setSortDirection(options.direction)
    }
  };

  const clearFilterInputs = (): void => {
    setFilter(defaultFilter);
  };

  const handleClear = (id: string): void => {
    setFilter({ ...filter, [id]: [] });
  };

  const handleSelectedValues = (values: string[], id: string): void => {
    setFilter({ ...filter, [id]: values });
  };

  const handleSearch = (id:string, value: string): void => {
    setFilter({ ...filter, [id]: value });
  };

  const handlePageChange = (n: number) => {
    setPageNumber(n);
  }

  const errors = legislative?.error ||  billError;
  if (errors) throw errors;

  const response = legislative?.data?.legislativeScheduleLinkCollection?.items[0];
  const legislativeLinkText = response?.hyperLinkText;
  const fileLink = response?.legislativeSchedule?.url;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} lg={4}>
        <Box sx={{ mb: '1rem' }}>
          <BasicCard>
            <FiltersSection
              handleClear={handleClear}
              handleSearch={handleSearch}
              handleSelectedValues={handleSelectedValues}
              filtersTemplate={filter}
              clearFilterInputs={clearFilterInputs}
              handleSubmit={updateBills}
              isLoading={loading}
            />
          </BasicCard>
        </Box>
        <Box sx={{ mb: '1rem' }}>
          <BasicCard>
            <div className={styles.inputWrapper}>
              <div className={styles.sortHeading}>
                <FontAwesomeIcon icon={faSort} />
                <span>Sort by</span>
              </div>
              <GeneralSelect
                data={sortDropDownOptions}
                id={'sortBillsBy'}
                handleChange={(e) => handleSort(e.target.value)}
                value={orderBy}
                labelText={'Select a sort option'}
              />
            </div>
          </BasicCard>
        </Box>
        <Box sx={{ mb: '1rem' }}>
          <List>
            <ListItem>
              <GeneralLinkLarge className={styles.link} href={fileLink}>
                {legislativeLinkText}
              </GeneralLinkLarge>
            </ListItem>
            <ListItem>
              <GeneralLinkLarge className={styles.link} navigateTo="/contact">
                Request to add a bill
              </GeneralLinkLarge>
            </ListItem>
          </List>
        </Box>
      </Grid>
      <Grid item xs={12} lg={8}>
        <BillDistributionTabs
            policyDistribution={policyDistribution.map(({ group, count }) => ({ policy: group as PolicyEnum, value: count }))}
            stateDistribution={stateDistribution.map(({ group, count }) => ({ state: group as StateEnum, value: count }))}
            statusDistribution={statusDistribution.map(({ group, count }) => ({ status: group as StatusEnum, value: count }))}
        >
          <HelperText />
          {!bills.length && !loading && <NoBillsFound />}
          {bills.length > 0 && <ResultsCount count={total} />}
          <BillCards
            refetchBills={refetchBills}
            bills={bills}
            trackedBillIds={trackedBillIds}
            page={pageNumber}
            pageCount={pageCount}
            onPageChange={handlePageChange} />
        </BillDistributionTabs>
      </Grid>
    </Grid>
  );
};

export function HelperText() {
  return (
    <Box mb={'30px'}>
      <span className={styles.helperText}>
        Select a bill to view more details about the bill. Each bill opens in a new tab.
      </span>
    </Box>
  );
}

export default MainSection;
