import GeneralModal from '../GeneralModal/GeneralModal';
import styles from './ConfirmModal.module.scss';
import AdminButton from '../AdminButton/AdminButton';
import React from 'react';

interface Props {
  onClose: () => void;
  modalOpen: boolean;
  handleDelete: () => void;
  title: string;
  description: string;
  actionButtonText: string;
  isActionPending?: boolean;
}

function ConfirmModal({
  onClose,
  modalOpen,
  handleDelete,
  title,
  description,
  actionButtonText,
  isActionPending,
}: Props) {
  return (
    <GeneralModal onClose={onClose} modalOpen={modalOpen}>
      <div className={styles.modalContainer}>
        <h3 className={styles.modalTitle}>{title}</h3>
        <p className={styles.modalDescription}>{description}</p>
        <div className={styles.modalBtnContainer}>
          <AdminButton text="Cancel" onClick={onClose} />
          <AdminButton
            disabled={isActionPending ? isActionPending : false}
            text={actionButtonText}
            onClick={handleDelete}
          />
        </div>
      </div>
    </GeneralModal>
  );
}

export default ConfirmModal;
