import React, { FC, useContext, useState } from 'react';
import Input from 'src/components/Input/Input';
import styles from './CreatePhraseForm.module.scss';
import GeneralSelect from 'src/components/GeneralSelect/GeneralSelect';
import { ImportFilter } from '../types/ImportFilter.interface';
import AdminButton from 'src/components/AdminButton/AdminButton';
import { GlobalContext } from '../../../hooks/useGlobalContext';
import { useMutation } from '@apollo/client';
import { CreateImportFilterVars } from '../FiltersTableSection/FiltersTable.interface';
import { CREATE_IMPORT_FILTER } from './CreatePhraseForm.graphql';
import FilterTypeEnum from '../types/FilterType.enum';
import { createImportFilterOptions } from '../const/createImportFilterOptions';
import { toast } from 'react-toastify';

interface CreatePhraseFormProps {
  handleClose: () => void;
  refetchBillImportFilters: () => void;
}

const CreatePhraseForm: FC<CreatePhraseFormProps> = ({ handleClose, refetchBillImportFilters }) => {
  const { userData } = useContext(GlobalContext);
  const [newPhraseForm, setNewPhraseForm] = useState<CreateImportFilterVars>({
    filterType: FilterTypeEnum.REJECT_TITLE,
    phrase: '',
    user: userData?.fullName ?? '',
  });
  const [formError, setFormError] = useState({
    error: false,
    message: '',
  });

  const [createBillImportFilter] = useMutation<ImportFilter, CreateImportFilterVars>(CREATE_IMPORT_FILTER, {
    onCompleted(data) {
      handleClose();
      toast.success('New bill import filter created', {
        position: toast.POSITION.TOP_CENTER,
      });
      refetchBillImportFilters();
    },
    onError(error) {
      toast.error('There was an error with your request', {
        position: toast.POSITION.TOP_CENTER,
      });
    },
  });

  const handleSubmit = () => {
    createBillImportFilter({ variables: newPhraseForm });
  };

  const handleChange = (e: any) => {
    const { id, value } = e.target;
    if (formError) {
      setFormError({ error: false, message: '' });
    }
    setNewPhraseForm({ ...newPhraseForm, [id]: value });
  };

  const formCheck = () => {
    const isBillNumber = newPhraseForm.filterType === FilterTypeEnum.REJECT_NUMBER;
    const hasCharacterLimit = isBillNumber ? newPhraseForm.phrase.length <= 20 : true;
    const isFilled = newPhraseForm.phrase.length > 0 && hasCharacterLimit;
    return isFilled ? handleSubmit() : setFormError({ error: true, message: 'Phrase required (20 character Limit)' });
  };

  const handleChangeSelect = (e: any) => {
    const { name, value } = e.target;
    if (formError) {
      setFormError({ error: false, message: '' });
    }
    setNewPhraseForm({ ...newPhraseForm, [name]: value });
  };
  return (
    <>
      <div className={styles.heading}>
        <h1>Create Legiscan Filter</h1>
      </div>
      <div className={styles.formContainer}>
        <form>
          <GeneralSelect
            isRequired={true}
            labelText="Filter Type"
            id="filterType"
            handleChange={handleChangeSelect}
            value={newPhraseForm.filterType}
            data={createImportFilterOptions}
          />

          <Input
            label="Phrase"
            error={formError.error}
            helperText={formError.message}
            handleChange={handleChange}
            value={newPhraseForm.phrase}
            placeholder={'Filter Phrase *'}
            id="phrase"
          />
        </form>
        <div className={styles.buttonContainer}>
          <AdminButton onClick={() => handleClose()} text="Cancel" />
          <AdminButton onClick={() => formCheck()} text="Save" />
        </div>
      </div>
    </>
  );
};
export default CreatePhraseForm;
