import React, { FC } from 'react';
import Button from '@mui/material/Button';
import { ThemeProvider } from '@mui/material/styles';
import buttonTheme from 'src/theme/buttonTheme';
import './HeroButton.module.scss';
import styles from './HeroButton.module.scss';

export interface ButtonProps {
  onClick: () => void;
  disabled?: boolean;
  text: string;
  emphasisText: string;
  id?: string;
}

const HeroButton: FC<ButtonProps> = ({ text, onClick, disabled, emphasisText, id }) => {
  return (
    <ThemeProvider theme={buttonTheme}>
      <Button
        variant="contained"
        onClick={onClick}
        id={id ? id : undefined}
        disableElevation
        disableFocusRipple
        color="secondary"
        disabled={disabled ? disabled : false}
        sx={{
          padding: '10px 30px',
          margin: '5px auto',
          width: {
            xs: '90%',
            sm: '80%',
            lg: '370px',
          },
          borderRadius: '0px',
          fontWeight: '400',
          fontSize: {
            xs: '10px',
            sm: '18px',
            xl: '22px',
          },
        }}
      >
        {text} <strong className={styles.emphasisText}>&nbsp;{emphasisText}</strong>
      </Button>
    </ThemeProvider>
  );
};

export default HeroButton;
