import { gql } from '@apollo/client';

export const FAQS_QUERY = gql`
  query {
    faqsCollection(limit: 50) {
      items {
        order
        questionTitle
        onHomePage
        answerBody {
          json
          links {
            entries {
              inline {
                sys {
                  id
                }
              }
              block {
                sys {
                  id
                }
              }
            }
            assets {
              hyperlink {
                sys {
                  id
                }
                url
                fileName
                contentType
              }
              block {
                sys {
                  id
                }
                url
                title
                width
                contentType
                height
                description
              }
            }
          }
        }
        categoryCollection {
          items {
            categoryName
          }
        }
        sys {
          id
          firstPublishedAt
        }
      }
    }
  }
`;
