import React, {ReactElement, useState, useContext, useEffect, useCallback} from 'react';
import {Box, Button, CircularProgress, Grid, Typography} from '@mui/material';
import {GlobalContext, GlobalContextProps} from 'src/hooks/useGlobalContext';
import { Navigate } from 'react-router-dom';
import { UserRole } from 'src/types/UserRole.enum';
import {SortDirectionEnum} from "../../types/SortDirection.enum";
import {ThemeProvider} from "@mui/material/styles";
import buttonTheme from "../../theme/buttonTheme";
import {AdminTable} from "../../components/AdminTable/AdminTable";
import {useLazyQuery} from "@apollo/client";
import {AdminUsersQueryDataInterface, AdminUsersQueryVariableInterface} from "./UserAccountManagement.interface";
import {ADMIN_USERS_QUERY} from "./UserAccountManagement.graphql";
import {UserData} from "../../types/UserData.interface";
import TextField from "@mui/material/TextField";
import {debounce} from "lodash";
import EditUserModal from "./EditUserModal/EditUserModal";
import {updateTabTitle} from "../../utils/updateTabTitle";
import PageTitles from "../../types/PageTitles.enum";

const columns: {id: keyof UserData, label: string}[] = [
  { id: 'firstName', label: 'First Name' },
  { id: 'lastName', label: 'Last Name', },
  { id: 'email', label: 'Email' },
  { id: 'role', label: 'Role' },
  { id: 'createdDate', label: 'Date Added' },
  { id: 'lastLogin', label: 'Last Log-In' },
  { id: 'active', label: 'Account Status' },
];

export const UserAccountManagement = (): ReactElement => {
  updateTabTitle(PageTitles.USER_ACCOUNT_MANAGEMENT);
  const { authenticated, userData } = useContext<GlobalContextProps>(GlobalContext);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [user, setUser] = useState<UserData>();

  // Search input
  const [keywords, setKeywords] = useState<string>('');

  // Pagination states
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [sortColumn, setSortColumn] = useState<string>('createdDate');
  const [sortDirection, setSortDirection] = useState<SortDirectionEnum>(SortDirectionEnum.DESC);

  const [getUsers, { data: adminUsersQueryData, loading: usersLoading, error: usersError, refetch: refetchUsers }] = useLazyQuery<
    AdminUsersQueryDataInterface,
    AdminUsersQueryVariableInterface
  >(ADMIN_USERS_QUERY);

  // Invoke on initial render, and when after state, keywords, and statuses change.
  useEffect(() => {
    getUsers({
      variables: {
        usersInput: {
          keywords: keywords,
          pageNumber,
          pageSize,
          sortColumn,
          sortDirection,
        },
      },
    });
  }, [getUsers, keywords, pageNumber, pageSize, sortColumn, sortDirection]);

  // eslint-disable-next-line
  const debouncedSetKeywords = useCallback(debounce(v => {setKeywords(v)}, 1000), []);

  if (!(authenticated && userData?.role === UserRole.SUPER_ADMIN)) return <Navigate to="/" />;

  return (
    <Box>

      <EditUserModal
        user={user}
        open={isEditModalOpen}
        onCancel={() => {
          setUser(undefined)
          setIsEditModalOpen(false)
        }}
        onComplete={() => {
          setUser(undefined)
          setIsEditModalOpen(false)
          refetchUsers()
        }}
      />

      <Grid container sx={{ mt: 6 }}>
        <Grid item xs={12} sm={12}>
          <Typography variant="h4" component="h1" sx={{ mb: 3 }}>
            User Account Management
          </Typography>
        </Grid>
        <Grid item container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item xs={6} sm={6}>
            <form>
              <TextField
                id="pending-bills-keyword"
                label="Search by Keyword"
                onChange={(e) => {
                  debouncedSetKeywords(e.target.value);
                  setPageNumber(0);
                }}/>
            </form>
          </Grid>
          <Grid item xs={6} sm={6} display="flex" justifyContent="end" alignSelf="flex-end">
            <ThemeProvider theme={buttonTheme}>
              <Button
                variant="contained"
                onClick={() => {
                  setIsEditModalOpen(true)
                  setUser(undefined)
                }}
              >
                Add New User
              </Button>
            </ThemeProvider>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12}>
          {usersError && (
            <Typography>Failed to load users.</Typography>
          )}

          {usersLoading && (
            <Box sx={{ textAlign: 'center', mt: 2 }}>
              <CircularProgress />
            </Box>
          )}

          {adminUsersQueryData && (
            <AdminTable
              title={'Users table'}
              columns={columns}
              columnRender={(k, v) => {
                switch(k) {
                  case 'lastLogin':
                    return v ?? ''
                  case 'active':
                    return v ? 'Active' : 'Disabled'
                  default:
                    return v
                }
              }}
              data={adminUsersQueryData.users}
              count={adminUsersQueryData.usersCount}
              pageNumber={pageNumber}
              pageSize={pageSize}
              sortColumn={sortColumn}
              sortDirection={sortDirection}
              onPageNumberChange={setPageNumber}
              onPageSizeChange={setPageSize}
              onSortChange={(c, d) => {
                setSortColumn(c);
                setSortDirection(d);
                setPageNumber(0);
              }}
              actions={(user) => (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                  <ThemeProvider theme={buttonTheme}>
                    <Button variant="contained" onClick={() => {
                      setIsEditModalOpen(true)
                      setUser(user);
                    }}>
                      Edit
                    </Button>
                  </ThemeProvider>
                </Box>
              )}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
