import Heading from 'src/components/Heading/Heading';
import styles from './StyleGuide.module.scss';
import HeadingStyle from 'src/components/Heading/HeadingStyle.enum';
import GeneralInfoCard from 'src/components/GeneralInfoCard/GeneralInfoCard';
import Card from '@mui/material/Card';
import { colors, gradients } from 'src/theme/colors.js';
import BreadCrumbs from 'src/components/BreadCrumbs/BreadCrumbs';
import PrimaryButton from 'src/components/PrimaryButton/PrimaryButton';
import HeroButton from 'src/components/HeroButton/HeroButton';
import GeneralSelect from 'src/components/GeneralSelect/GeneralSelect';
import IconButton from 'src/components/IconButton/IconButton';
import GeneralLinkBody from 'src/components/GeneralLinkBody/GeneralLinkBody';
import GeneralLinkLarge from 'src/components/GeneralLinkLarge/GeneralLinkLarge';
import IconCard from 'src/components/IconCard/IconCard';
import Input from 'src/components/Input/Input';
import { updateTabTitle } from 'src/utils/updateTabTitle';
import PageTitles from 'src/types/PageTitles.enum';

export default function StyleGuide(): React.ReactElement {
  updateTabTitle(PageTitles.STYLE);
  const chromaticColors = [
    { text: 'Text Links/Buttons #1A78BE', styles: { backgroundColor: colors.blue1 } },
    { text: 'Container Light Yellow #EBD36A24', styles: { backgroundColor: colors.yellow2 } },
    { text: 'Yellow Emphasis Text #FFCB70', styles: { backgroundColor: colors.yellow1 } },
    { text: '"Yes" Status Bar #076324', styles: { backgroundColor: colors.green3 } },
    { text: '"No" Status Bar #FECB08 ', styles: { backgroundColor: colors.yellow3 } },
    { text: 'CNEE Brand Blue #0080B2', styles: { backgroundColor: colors.blue3 } },
    { text: 'CNEE Brand Orange #E6901A', styles: { backgroundColor: colors.orange2 } },
    { text: 'CNEE Brand Lime Green #8CC63F', styles: { backgroundColor: colors.limeGreen } },
    { text: 'Page Element Green #7D9A59', styles: { backgroundColor: colors.green5 } },
    { text: 'Page Element Yellow #EBD36A', styles: { backgroundColor: colors.yellow4 } },
    { text: 'Page Element Gold #D8A102', styles: { backgroundColor: colors.gold } },
    {
      text: 'Gradient Blue #0B9AD2>#0F5874',
      styles: { background: gradients.medBlue },
    },
    {
      text: 'Gradient Light Green #61A739 > #076324',
      styles: { background: gradients.medGreen },
    },
    {
      text: 'Gradient Dark Green #076324 > #000000',
      styles: { background: gradients.darkGreen },
    },
    {
      text: 'Gradient Dark Blue #0F5874 > #000000',
      styles: { background: gradients.darkBlue },
    },
  ];

  const achromaticColors = [
    { text: 'Global Text Dark Gray #333333', styles: { backgroundColor: colors.darkGray1 } },
    { text: 'Breadcrumb/Placeholder Text #999999', styles: { backgroundColor: colors.medGray1 } },
    { text: 'Form Elements/Tabs #F4F4F4', styles: { backgroundColor: colors.lightGray1 } },
    { text: 'Comparison Card #666666', styles: { backgroundColor: colors.darkGray2 } },
    { text: 'Hover State Numbers #E4E4E4', styles: { backgroundColor: colors.lightGray2 } },
    { text: 'True Black #000000', styles: { backgroundColor: colors.black } },
  ];

  const sampleSelectData = ['Colorado', 'Massachusetts', 'Maine'];

  const handleChange = () => {
    console.log('styleguide');
  };
  return (
    <>
      <BreadCrumbs />
      <div className={styles.content}>
        <section>
          <h1 className={styles.coloredText}>Components</h1>
          <h2 className={styles.subHeading}>Buttons</h2>
          <PrimaryButton
            text={'Primary Button'}
            onClick={() => {
              alert('pressed');
            }}
          />
          <HeroButton
            text={'Get started with'}
            emphasisText={'AEL Tracker'}
            onClick={() => {
              alert('Pressed');
            }}
          />
          <IconButton
            text={'Go to MyTracker'}
            onClick={() => {
              alert('Pressed');
            }}
          />
          <h2 className={styles.subHeading}>General Select</h2>
          <GeneralSelect value={'state'} handleChange={handleChange} id={'styleguide'} data={sampleSelectData} />
        </section>
        <h2 className={styles.subHeading}>Breadcrumbs</h2>
        <div className={styles.breadCrumbsContainer}>
          <BreadCrumbs />
        </div>
        <h2 className={styles.subHeading}>Form Elements</h2>
        <div className={styles.inputContainer}>
          <Input
            value={'test'}
            handleChange={handleChange}
            id="emailAddress"
            label={'Email Address'}
            placeholder={'Email Address'}
          />
          <Input
            value="test"
            handleChange={handleChange}
            label={'Email Address'}
            id="emailAddress"
            placeholder={'Email Address'}
            error={true}
            helperText={'This is an error message.'}
          />
        </div>
        <h2 className={styles.subHeading}>General Links</h2>
        <GeneralLinkBody href="https://cnee.colostate.edu/">Center for the New Energy Economy</GeneralLinkBody>
        <br />
        <br />
        <GeneralLinkLarge navigateTo="/test-page">Download the legislative Schedule</GeneralLinkLarge>
        <h2 className={styles.subHeading}>Icon Card</h2>
        <IconCard text="My Tracker is your personalized list of bills to track." />
        <section>
          <h1 className={styles.coloredText}>Color Palette</h1>
          <h2 className={styles.subHeading}>Chromatic</h2>
          <div className={styles.colorGrid}>
            {chromaticColors.map((color, index) => (
              <Card variant="outlined" className={styles.colorItem} key={index}>
                <h2>{color.text}</h2>
                <div style={color.styles}></div>
              </Card>
            ))}
          </div>
          <h2 className={styles.subHeading}>Achromatic</h2>
          <div className={styles.colorGrid}>
            {achromaticColors.map((color, index) => (
              <Card variant="outlined" className={styles.colorItem} key={index}>
                <h2>{color.text}</h2>
                <div style={color.styles}></div>
              </Card>
            ))}
          </div>
        </section>
        <section>
          <h1 className={styles.coloredText}>Headers/Typography</h1>
          <h2 className={styles.subHeading}>Page Titles (H1)</h2>
          <Heading headingStyle={HeadingStyle.PAGE_TITLE}>Search the Advanced Energy Legislation Tracker</Heading>

          <h2 className={styles.subHeading}>Page SubTitles (H2)</h2>
          <Heading headingStyle={HeadingStyle.PAGE_SUBTITLE}>
            Sign up for MyTracker to create a personalized list of bills to track.{' '}
          </Heading>
          <h2 className={styles.subHeading}>Container Title and Body (H3 and H4)</h2>
          <GeneralInfoCard title={'Decoupling and DSM Performance Incentives'}>
            {' '}
            Decoupling of utility revenue from sales removes a disincentive for utilities to invest in energy
            efficiency. Broadly, decoupling refers to a process by which a utility commission grants a utility a level
            of return on equity that is not dependent on volumetric sales. For more information on the components of the
            policy see the full policy brief. For more information on the components of the policy see the full policy
            brief | <GeneralLinkLarge>Download the pdf</GeneralLinkLarge>
          </GeneralInfoCard>
        </section>
      </div>
    </>
  );
}
