import React, { useEffect, useState } from 'react';
import CreatePhraseModal from './CreatePhraseModal/CreatePhraseModal';
import styles from './FilterConfig.module.scss';
import { ImportFilter } from './types/ImportFilter.interface';
import { updateTabTitle } from 'src/utils/updateTabTitle';
import PageTitles from 'src/types/PageTitles.enum';
import {
  GetImportFiltersVariable,
  RawBillImportFiltersData,
  RunAllBillImportFiltersRes,
} from './FiltersTableSection/FiltersTable.interface';
import { SortDirectionEnum } from '../../types/SortDirection.enum';
import { useLazyQuery, useMutation } from '@apollo/client';
import FilterConfigPageTitleAndDescription from './FilterConfigPageTitleAndDescription';
import FiltersTable from './FiltersTableSection/FiltersTable';
import { SearchRunAndCreateFields } from './SearchRunAndCreateFields';
import { Box, CircularProgress, Typography } from '@mui/material';
import { debounce } from 'lodash';
import ConfirmModal from '../../components/ConfirmModal/ConfirmModal';
import { GET_BILL_IMPORT_FILTERS, RUN_ALL_BILL_IMPORT_FILTERS } from './FiltersConfig.graphql';
import { toast } from 'react-toastify';

export default function FilterConfig(): React.ReactElement {
  updateTabTitle(PageTitles.FILTER_CONFIG);
  const [createFilterModalOpen, setCreateFilterModalOpen] = useState(false);
  const [runFiltersModalOpen, setRunFiltersModalOpen] = useState(false);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [sortColumn, setSortColumn] = useState<keyof ImportFilter>('autoDateUpdate');
  const [sortDirection, setSortDirection] = useState<SortDirectionEnum>(SortDirectionEnum.DESC);
  const [keywords, setKeywords] = useState<string>('');

  const [getImportFilters, { data: billImportFilters, loading, error, refetch: refetchBillImportFilters }] =
    useLazyQuery<RawBillImportFiltersData, GetImportFiltersVariable>(GET_BILL_IMPORT_FILTERS);

  const [runAllBillFilters, { loading: isRunAllBillFiltersPending }] =
    useMutation<RunAllBillImportFiltersRes>(RUN_ALL_BILL_IMPORT_FILTERS);

  useEffect(() => {
    getImportFilters({
      variables: {
        pageNumber,
        pageSize,
        sortColumn,
        sortDirection,
        keywords,
      },
    });
  }, [getImportFilters, keywords, pageNumber, pageSize, sortColumn, sortDirection]);

  const debounceSetKeywords = debounce((input: string) => {
    setKeywords(input);
  }, 100);

  const handleSearch = (input: string) => {
    debounceSetKeywords(input);
  };

  const handleRunFilters = () => {
    const id = toast.loading('Running all bill filters', { position: toast.POSITION.TOP_CENTER });
    runAllBillFilters()
      .then((res) => {
        toast.update(id, {
          position: toast.POSITION.TOP_CENTER,
          render: res.data?.runAllBillFilters,
          type: 'success',
          isLoading: false,
          closeOnClick: true,
          autoClose: 3000,
        });
        setRunFiltersModalOpen(false);
      })
      .catch(() => {
        toast.update(id, {
          position: toast.POSITION.TOP_CENTER,
          render: 'There was an error with your request',
          type: 'error',
          isLoading: false,
          closeOnClick: true,
          autoClose: 3000,
        });
      });
  };

  const openRunFiltersModal = () => {
    setRunFiltersModalOpen(true);
  };
  const openCreateFilterModal = () => {
    setCreateFilterModalOpen(true);
  };
  return (
    <section className={styles.filterConfigSection}>
      <FilterConfigPageTitleAndDescription />
      <section className={styles.filtersTableSection}>
        <SearchRunAndCreateFields
          count={billImportFilters?.billImportFiltersCount ?? 0}
          pageSize={pageSize}
          handleSearch={handleSearch}
          handleCreateFilter={openCreateFilterModal}
          handleRunFilters={openRunFiltersModal}
        />
        {loading && (
          <Box sx={{ textAlign: 'center', mt: 2 }}>
            <CircularProgress />
          </Box>
        )}
        {error && <Typography p={'20px'}>Failed to load import filters.</Typography>}
        {billImportFilters && billImportFilters.billImportFiltersCount === 0 && (
          <Typography p={'20px'}>No filters matched your search.</Typography>
        )}
        {billImportFilters && billImportFilters.billImportFiltersCount !== 0 && (
          <FiltersTable
            count={billImportFilters.billImportFiltersCount}
            filters={billImportFilters.billImportFilters}
            refetchBillImportFilters={refetchBillImportFilters}
            pageNumber={pageNumber}
            pageSize={pageSize}
            sortColumn={sortColumn}
            onPageNumberChange={setPageNumber}
            onPageSizeChange={setPageSize}
            setSortColumn={setSortColumn}
            setSortDirection={setSortDirection}
          />
        )}
      </section>
      <CreatePhraseModal
        modalOpen={createFilterModalOpen}
        onClose={() => setCreateFilterModalOpen(false)}
        refetchBillImportFilters={refetchBillImportFilters}
      />
      <ConfirmModal
        title="Run Filters"
        actionButtonText="Run Filters"
        description="Are you sure you want to run filters for all pending bills?"
        isActionPending={isRunAllBillFiltersPending}
        handleDelete={handleRunFilters}
        modalOpen={runFiltersModalOpen}
        onClose={() => {
          setRunFiltersModalOpen(false);
        }}
      />
    </section>
  );
}
