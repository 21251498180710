import { TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { FC } from 'react';

export interface FilterSearchProps {
  handleSearch: (e: { target: { value: string } }) => void;
}

const FilterSearch: FC<FilterSearchProps> = ({ handleSearch }) => {
  return (
    <TextField
      onChange={handleSearch}
      label="Search Filters by keyword"
      variant="outlined"
      sx={{
        width: '50%',
        fontFamily: 'Lato',
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default FilterSearch;
