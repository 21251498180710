import React, { FC, useState } from 'react';
import PrimaryButton from 'src/components/PrimaryButton/PrimaryButton';
import Input from 'src/components/Input/Input';
import GeneralLinkBody from 'src/components/GeneralLinkBody/GeneralLinkBody';
import styles from './LoginForm.module.scss';
import ReCAPTCHA from 'react-google-recaptcha';

interface LoginFormProps {
  handleChange: (e: any) => void;
  loginForm: {
    email: string;
    password: string;
  };
  formError: {
    emailError: { error: boolean; message: string };
    passwordError: { error: boolean; message: string };
  };
  handleLogin: () => void;
  setRenderCreateAcct: React.Dispatch<boolean>;
  setShowPwRequestForm: React.Dispatch<boolean>;
}
const LoginForm: FC<LoginFormProps> = ({
  handleChange,
  loginForm,
  formError,
  handleLogin,
  setRenderCreateAcct,
  setShowPwRequestForm,
}) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const handlePressEnter = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !isDisabled) {
      handleLogin();
    }
  };

  const handleCaptchaChange = (value: any) => {
    return value ? setIsDisabled(false) : setIsDisabled(true);
  };

  return (
    <>
      <div className={styles.contentContainer}>
        <div className={styles.headingSection}>
          <h2>myTracker</h2>
          <p>Create a personalized list of bills to track to stay up-to-date on recent bill action and news.</p>
        </div>
        <div className={styles.formSection}>
          <form>
            <Input
              error={formError.emailError.error}
              helperText={formError.emailError.message}
              label="Email"
              handleChange={handleChange}
              value={loginForm.email}
              id="email"
              placeholder={'Email Address'}
              onKeyDown={handlePressEnter}
              autoFocus={true}
            />
            <Input
              error={formError.passwordError.error}
              helperText={formError.passwordError.message}
              label="Password"
              handleChange={handleChange}
              type="password"
              value={loginForm.password}
              id="password"
              placeholder="Password"
              onKeyDown={handlePressEnter}
            />
            <ReCAPTCHA sitekey={`${process.env.REACT_APP_SITE_KEY}`} onChange={handleCaptchaChange} />
            <div className={styles.buttonLinkContainer}>
              <GeneralLinkBody
                className={styles.forgotPassword}
                onClick={(e) => {
                  setShowPwRequestForm(true);
                }}
              >
                Forgot Password?
              </GeneralLinkBody>
              <PrimaryButton disabled={isDisabled} onClick={handleLogin} text="Log In" />
            </div>
          </form>
          <div className={styles.createAcctContainer}>
            <div>
              <PrimaryButton onClick={() => setRenderCreateAcct(true)} text="Create a new account" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginForm;
