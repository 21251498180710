import React, { DetailedHTMLProps, FC, HTMLAttributes } from 'react';
import styles from './GeneralLinkLarge.module.scss';
import Link from '@mui/material/Link';
import { useNavigate } from 'react-router-dom';

export interface GeneralLinkLargeProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement> {
  href?: string;
  navigateTo?: string;
  className?: string;
}

const GeneralLinkLarge: FC<GeneralLinkLargeProps> = ({ href, navigateTo, children, className, ...rest }) => {
  const navigate = useNavigate();
  return (
    <Link
      underline="none"
      tabIndex={0}
      className={`${styles.generalLink} ${className}`}
      href={href ? href : undefined}
      onClick={
        navigateTo
          ? () => {
              navigate(navigateTo);
              window.scrollTo(0, 0);
            }
          : undefined
      }
      onKeyPress={
        navigateTo
          ? () => {
              navigate(navigateTo);
              window.scrollTo(0, 0);
            }
          : undefined
      }
    >
      {children}
    </Link>
  );
};

export default GeneralLinkLarge;
