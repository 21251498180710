import {FC, useCallback, useContext, useEffect, useRef, useState} from 'react';
import styles from './MyTracker.module.scss';
import BreadCrumbs from 'src/components/BreadCrumbs/BreadCrumbs';
import { updateTabTitle } from 'src/utils/updateTabTitle';
import PageTitles from 'src/types/PageTitles.enum';
import MyTrackerHeader from './MyTrackerHeader';
import BillChangesSection from './BillChangesSection/BillChangesSection';
import AccountPrefSection from './AccountPrefSection/AccountPrefSection';
import {BillDistributionTabs} from 'src/components/BillDistributionTabs/BillDistributionTabs';
import {useMutation, useQuery} from '@apollo/client';
import { mapResponseToBill } from 'src/utils/mapResponseToBill.util';
import { GET_ADMIN_CELA_BILLS } from './AdminTracker.graphql';
import AdminBillCard from '../../components/BillCards/AdminBillCard';
import { BillData } from "../../types/BillData.interface";
import {RawAdminBillData, RawAdminBillVariables} from './AdminTracker.interface';
import { CsvDownloadSection } from '../../components/CsvDownloadSection/CsvDownloadSection';
import { toast } from 'react-toastify';
import { UserData } from '../../types/UserData.interface';
import { GlobalContext } from 'src/hooks/useGlobalContext';
import { ADD_CELA_BILL } from '../../components/BillCards/AdminCard.graphql';
import {Box, Grid, Pagination} from '@mui/material';
import BillSearch from '../BillAdministration/BillSearch/BillSearch';
import {BillDistributionInterface} from "../../types/BillDistribution.interface";
import { PolicyEnum } from "../../types/Policy.enum";
import {StateEnum} from "../../types/State.enum";
import {StatusEnum} from "../../types/Status.enum";
import * as React from "react";
import {debounce} from "lodash";

interface AdminTrackerProps {
  loggedOutBillTracked: number;
  userData: UserData;
}

const isTimeFilterInUrl = (url: string): boolean => {
  return url.indexOf('day') > -1 || url.indexOf('week') > -1 || url.indexOf('month') > -1;
};

const scrollToRef = (ref: React.RefObject<HTMLDivElement>) => {
  ref.current?.scrollIntoView({
    behavior: 'smooth',
  });
};

export const AdminTracker: FC<AdminTrackerProps> = ({ userData, loggedOutBillTracked }) => {
  updateTabTitle(PageTitles.MYTRACKER);

  const { setLoggedOutBillTracked, setRecentBillChangesSection, setNotificationPreferencesSection } = useContext(GlobalContext);

  const topOfBills = useRef<null | HTMLDivElement>(null);
  const changesRef = useRef<HTMLDivElement>(null);
  const preferencesRef = useRef<HTMLDivElement>(null);

  const [billsData, setBillsData] = useState<BillData[]>([]);
  const [keywords, setKeywords] = useState<string>('');
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [billsCount, setBillsCount] = useState(0);
  const [policyDistribution, setPolicyDistribution] = useState<BillDistributionInterface[]>([]);
  const [statusDistribution, setStatusDistribution] = useState<BillDistributionInterface[]>([]);
  const [stateDistribution, setStateDistribution] = useState<BillDistributionInterface[]>([]);

  // eslint-disable-next-line
  const debouncedSetKeywords = useCallback(debounce(v => {setKeywords(v)}, 1000), []);

  const [addCelaBill] = useMutation(ADD_CELA_BILL);
  const { loading, error, refetch: refetchBills } = useQuery<RawAdminBillData, RawAdminBillVariables>(GET_ADMIN_CELA_BILLS, {
    onCompleted(data) {
      setBillsData(data.listActiveCelaBills.map(mapResponseToBill));
      setPolicyDistribution(data.policyDistribution)
      setStatusDistribution(data.statusDistribution)
      setStateDistribution(data.stateDistribution)
      setBillsCount(data.billsCount)
      setPageCount(Math.ceil(data.billsCount / pageSize))
    }
  });

  const handleLoggedOutBillTracked = async () => {
    if (loggedOutBillTracked && userData?.id) {
      await addCelaBill({ variables: { billId: loggedOutBillTracked } })
      toast.success('Successfully added bill to Cela bills', {
        position: toast.POSITION.TOP_CENTER,
      });
      setLoggedOutBillTracked(0);
      await refetchBills()
    }
  };

  const updateBillList = async () => {
    await refetchBills({
      pageNumber: pageNumber - 1,
      pageSize,
      keywords,
    })
  }

  useEffect(() => {
    handleLoggedOutBillTracked();
    // eslint-disable-next-line
  }, [loggedOutBillTracked, userData?.id]);

  useEffect(() => {
    updateBillList();
    // eslint-disable-next-line
  }, [pageNumber, keywords, userData]);

  useEffect(() => {
    updateBillList();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isTimeFilterInUrl(window.location.href)) {
      scrollToRef(changesRef);
      setRecentBillChangesSection(false);
    }
  }, [setRecentBillChangesSection, billsData]);

  useEffect(() => {
    if (window.location.href.indexOf('notification-preferences') > -1) {
      scrollToRef(preferencesRef);
      setNotificationPreferencesSection(false);
    }
  }, [setNotificationPreferencesSection, billsData]);

  return (
    <>
      <BreadCrumbs />
      <MyTrackerHeader userBillsDataLength={billsCount} />
      <div className={styles.trackerContainer}>
        <BillDistributionTabs
          policyDistribution={policyDistribution.map(({ group, count }) => ({ policy: group as PolicyEnum, value: count }))}
          stateDistribution={stateDistribution.map(({ group, count }) => ({ state: group as StateEnum, value: count }))}
          statusDistribution={statusDistribution.map(({ group, count }) => ({ status: group as StatusEnum, value: count }))}
        >
          <div className={styles.cardsContainer}>
            <Box component={Grid} container justifyContent="space-between" spacing={1}>
              <Grid item xs={12} lg={6}>
                <BillSearch
                  value={keywords}
                  handleSearch={(e) => {
                    setPageNumber(1);
                    debouncedSetKeywords(e.target.value)
                  }} />
              </Grid>
              <Grid item xs={12} lg={6}>
                <CsvDownloadSection />
              </Grid>
            </Box>
            <div ref={topOfBills} tabIndex={0} aria-label="top of bills"></div>
            {loading && <div>Loading bills</div>}
            {error && <div>Failed to load bills</div>}
            {billsData.length > 0 && (
              <>
                <section className={styles.cards}>
                  {billsData?.map((bill) => (
                    <AdminBillCard
                      key={bill.billId}
                      billData={bill}
                      refetchBills={refetchBills} />
                  ))}
                </section>

                {pageCount > 1 && <Pagination
                  page={pageNumber}
                  count={pageCount}
                  shape="rounded"
                  onChange={(e, n: number) => {
                    topOfBills.current?.focus();
                    setPageNumber(n)
                  }}/>}
              </>
            )}
          </div>
        </BillDistributionTabs>
        <BillChangesSection billsData={billsData} sectionRef={changesRef} />
      </div>
      <AccountPrefSection sectionRef={preferencesRef} />
    </>
  );
};
