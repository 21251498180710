import { gql } from '@apollo/client';

export const PENDING_BILLS_QUERY = gql`
  query ($pendingBills: PendingBillsFilter) {
    pendingBillsCount(filter: $pendingBills)
    pendingBills(filter: $pendingBills) {
      id
      osStatus
      yearFiled
      osBillId
      osState
      number
      osSession
      osSessionId
      osChamber
      title
      osSubjects
      status
      likely
      changeStatusAt
      osCreatedAt
      osUpdatedAt
    }
  }
`;

export const APPROVE_BILL = gql`
  mutation ($input: ApproveBillInput!) {
    approveBill(input: $input) {
      billId
      yearFiled
      osCreatedAt
      autoTimeOsUpdate
      osBillId
      osState
      number
      osSession
      title
      summary
      osChamber
      policyCategory
      subcategory
      spotPolicies {
        policyId
      }
    }
  }
`;
