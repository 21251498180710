import styles from '../BillCard.module.scss';
import React, { FC } from 'react';
import { BillData } from "../../../types/BillData.interface";
import {stateText} from "../../../const/stateText";

interface MainContentProps {
  billCardDetails: BillData;
  maxTitleLength?: number;
}

const MainContent: FC<MainContentProps> = ({ billCardDetails, maxTitleLength }) => {
  if (!billCardDetails) return <></>;
  return (
    <div className={styles.billSummary}>
      <span className={styles.billDesignation}>{`${stateText[billCardDetails?.state]} ${
        billCardDetails?.designation
      }`}</span>
      <span className={styles.billDate}>Introduced {billCardDetails?.introductionYear}</span>
      <p className={styles.billDescription}>
        {getBillTitle(billCardDetails?.title ?? '', maxTitleLength)}
      </p>
      <span className={styles.billDate}>Last updated {billCardDetails?.lastUpdated}</span>
    </div>
  );
};

function getBillTitle(billTitle: string, maxTitleLength?: number) {
  if (maxTitleLength && billTitle.length >= maxTitleLength) return billTitle.slice(0, maxTitleLength) + '...';
  return billTitle;
}

export default MainContent;
