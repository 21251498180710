import { FC } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import useScreenSize from 'src/hooks/useScreenSize';
import Breakpoint from 'src/types/BreakPoint.enum';
import {IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface GeneralModalProps {
  onClose: () => void
  modalOpen: boolean;
  children: any;
  styling?: {
    width: string;
    padding: string;
  };
  ariaLabel?: string;
  ariaDescribedBy?: string;
}

const GeneralModal: FC<GeneralModalProps> = ({
  onClose,
  modalOpen,
  children,
  styling,
  ariaLabel,
  ariaDescribedBy,
}) => {
  const { screenSizeIsAtLeast } = useScreenSize();
  const defaultSize = {
    width: screenSizeIsAtLeast[Breakpoint.LG] ? 'initial' : '85%',
    padding: screenSizeIsAtLeast[Breakpoint.LG] ? '35px 33px' : '22px',
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderRadius: 2,
    fontFamily: 'Lato',
    boxShadow: 24,
    p: 4,
    maxHeight: '95vh',
    width: styling?.width ? styling.width : defaultSize.width,
    padding: styling?.padding ? styling.padding : defaultSize.padding,
    overflowY: 'auto',
  };

  return (
    <Modal open={modalOpen} onClose={onClose} aria-labelledby={ariaLabel} aria-describedby={ariaDescribedBy}>
      <Box sx={style}>
        <IconButton
          sx={{ position: 'absolute', top: '16px', right: '16px' }}
          onClick={onClose}
          aria-label="close Modal">
          <CloseIcon />
        </IconButton>
        {children}
      </Box>
    </Modal>
  );
};

export default GeneralModal;
