import {SubcategoryEnum} from "../types/Subcategory.enum";

export const subcategoryText = {
  [SubcategoryEnum.AGRICULTURE]: 'Agriculture',
  [SubcategoryEnum.APPLIANCE_EQUIPMENT_STANDARDS]: 'Applicance Equipment Standards',
  [SubcategoryEnum.AUTONOMOUS_VEHICLES]: 'Autonomous Vehicles',
  [SubcategoryEnum.BIOFUEL]: 'Biofuel',
  [SubcategoryEnum.BIOMASS]: 'Biomass',
  [SubcategoryEnum.BONDS]: 'Bonds',
  [SubcategoryEnum.CELA]: 'CELA',
  [SubcategoryEnum.CLIMATE]: 'Climate',
  [SubcategoryEnum.COMMISSION_MEMBERSHIP]: 'Comission Membership',
  [SubcategoryEnum.CONNECTING]: 'Connecting',
  [SubcategoryEnum.DISCLOSURE]: 'Disclosure',
  [SubcategoryEnum.DISTRIBUTED_SOLAR_GENERATION]: 'Distributed Solar Generation',
  [SubcategoryEnum.ECONOMIC_DEVELOPMENT]: 'Economic development',
  [SubcategoryEnum.ELECTRICITY_GENERATION]: 'Electricity Generation',
  [SubcategoryEnum.EMISSIONS]: 'Emissions',
  [SubcategoryEnum.ENERGY_COST_ASSISTANCE]: 'Energy Cost Assistance',
  [SubcategoryEnum.ENERGY_EFFICIENCY]: 'Energy Efficiency',
  [SubcategoryEnum.ENERGY_STAR_APPLIANCES]: 'Energy Star Appliances',
  [SubcategoryEnum.ENVIRONMENTAL_JUSTICE]: 'Environmental Justice',
  [SubcategoryEnum.FINANCING]: 'Financing',
  [SubcategoryEnum.FRACKING]: 'Fracking',
  [SubcategoryEnum.FUEL_CELL]: 'Fuel Cell',
  [SubcategoryEnum.GEOTHERMAL]: 'Geothermal',
  [SubcategoryEnum.GRANT_PROGRAM]: 'Grant Program',
  [SubcategoryEnum.GREEN_JOBS]: 'Green Jobs',
  [SubcategoryEnum.HEALTH_IMPACTS]: 'Health Impacts',
  [SubcategoryEnum.HYDROPOWER]: 'Hydropower',
  [SubcategoryEnum.IMPACTS]: 'Impacts',
  [SubcategoryEnum.INFRASTRUCTURE]: 'Infrastructure',
  [SubcategoryEnum.JOB_SKILLS_TRAINING]: 'Jobs/Skills Training',
  [SubcategoryEnum.LANDOWNER_EFFECTS]: 'Land-Owner Effects',
  [SubcategoryEnum.LEAD_BY_EXAMPLE]: 'Lead By Example',
  [SubcategoryEnum.LEASING_ADJUSTMENTS_FOR_STATE_PROPERTY]: 'Leaing Adjustments for State Property',
  [SubcategoryEnum.LEV]: 'LEV',
  [SubcategoryEnum.LOANS]: 'Loans',
  [SubcategoryEnum.LOANS_AND_GRANTS]: 'Loans and Grants',
  [SubcategoryEnum.LOAN_PROGRAM]: 'Loan Program',
  [SubcategoryEnum.LOW_INCOME_ENERGY_ASSISTANCE]: 'Low-income Energy Assisstance',
  [SubcategoryEnum.LOW_INCOME_RENEWABLE_ENERGY]: 'Low-income Renewable Energy',
  [SubcategoryEnum.METERING]: 'Metering',
  [SubcategoryEnum.METERS]: 'Meters',
  [SubcategoryEnum.METHANE]: 'Methane',
  [SubcategoryEnum.MICROGRID]: 'Microgrid',
  [SubcategoryEnum.MINERAL_ESTATE]: 'Mineral Estate',
  [SubcategoryEnum.MODIFY]: 'Modify',
  [SubcategoryEnum.MORATORIUM]: 'Moratorium',
  [SubcategoryEnum.NATURAL_GAS]: 'Natural Gas',
  [SubcategoryEnum.NATURAL_GAS_DEVELOPMENT]: 'Natural Gas Development',
  [SubcategoryEnum.NET_METERING]: 'Net Metering',
  [SubcategoryEnum.NEW_OFFICE]: 'New Office',
  [SubcategoryEnum.NOTICE]: 'Notice',
  [SubcategoryEnum.NUCLEAR]: 'Nuclear',
  [SubcategoryEnum.OTHER_ENERGY]: 'Other Energy',
  [SubcategoryEnum.OVERSIGHT]: 'Oversight',
  [SubcategoryEnum.PACE]: 'PACE',
  [SubcategoryEnum.PIPELINE]: 'Pipeline',
  [SubcategoryEnum.POOLING]: 'Pooling',
  [SubcategoryEnum.PROCEDURAL_DIRECTION]: 'Procedural Direction',
  [SubcategoryEnum.PRODUCTION_BY_PRODUCTS]: 'Production By-Products',
  [SubcategoryEnum.PURCHASING]: 'Purchasing',
  [SubcategoryEnum.RATES]: 'Rates',
  [SubcategoryEnum.REGULATORY]: 'Regulatory',
  [SubcategoryEnum.RENEWABLES]: 'Renewables',
  [SubcategoryEnum.RENEWABLE_PORTFOLIO_STANDARD]: 'Renewable Portfolio Standard',
  [SubcategoryEnum.RESEARCH_AND_DEVELOPMENT]: 'Research and Development',
  [SubcategoryEnum.RESOURCE_PLANNING]: 'Resource PLanning',
  [SubcategoryEnum.RPS]: 'RPS',
  [SubcategoryEnum.SALES_TAX]: 'Sales Tax',
  [SubcategoryEnum.SBC]: 'SBC',
  [SubcategoryEnum.SECURITIZATION]: 'Securitization',
  [SubcategoryEnum.SEVERANCE_PRODUCTION_TAXES]: 'Severance/Production Taxes',
  [SubcategoryEnum.SEVERANCE_TAXES]: 'Severance Taxes',
  [SubcategoryEnum.SITING]: 'Siting',
  [SubcategoryEnum.SMART_GRID]: 'Smart Grid',
  [SubcategoryEnum.SMART_METERS]: 'Smart Meters',
  [SubcategoryEnum.SOLAR]: 'Solar',
  [SubcategoryEnum.SPLIT_ESTATE]: 'Split Estate',
  [SubcategoryEnum.STATE_PRE_EMPTION]: 'State Pre-emption',
  [SubcategoryEnum.STORAGE]: 'Storage',
  [SubcategoryEnum.STUDIES]: 'Studies',
  [SubcategoryEnum.TAXATION]: 'Taxation',
  [SubcategoryEnum.TAX_CREDITS]: 'Tax Credits',
  [SubcategoryEnum.TAX_INCENTIVES]: 'Tax Incentives',
  [SubcategoryEnum.THIRD_PARTY_LEASING]: 'Third-party Leasing',
  [SubcategoryEnum.TIDAL]: 'Tidal',
  [SubcategoryEnum.TOLL_DISCOUNT]: 'Toll Discount',
  [SubcategoryEnum.TRANSITION]: 'Transition',
  [SubcategoryEnum.TRANSMISSION]: 'Transmission',
  [SubcategoryEnum.TRANSPORTATION]: 'TRANSPORTATION',
  [SubcategoryEnum.UTILITY_PAYMENT_FOR_ELECTRICITY_GENERATION]: 'Utility Payment for Electricity Generation',
  [SubcategoryEnum.WATER]: 'Water',
  [SubcategoryEnum.WIND]: 'Wind',
  [SubcategoryEnum.WIND_HYDROPOWER]: 'Wind/Hydro-power',
  [SubcategoryEnum.ZEV]: 'ZEV',
}
