import {gql} from "@apollo/client";

export const CSV_DOWNLOAD_QUERY = gql`
  query($inYearsFiled: [Int!], $cela: Boolean, $user: Int) {
    bills (
      input: {
        inYearsFiled: $inYearsFiled,
        pageSize: 10000,
        sortColumn: "yearFiled"
        sortDirection: ASC
        cela: $cela
        user: $user
      }
    ) {
      billId,
      state,
      number,
      title,
      summary,
      yearFiled,
      osStatus,
      osStatusId,
      policyCategory,
      subcategory,
      topic,
      osCreatedAt,
      autoTimeOsUpdate
    }
  }
`
