import { gql } from '@apollo/client';

export const CREATE_IMPORT_FILTER = gql`
  mutation ($filterType: FilterType, $phrase: String, $user: String) {
    createBillImportFilter(input: { filterType: $filterType, phrase: $phrase, user: $user }) {
      id
      user
      filterType
      phrase
    }
  }
`;
