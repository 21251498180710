import { FC } from 'react';
import Card from '@mui/material/Card';
import { colors } from 'src/theme/colors';
import greenGrid from 'src/assets/svg/color-grid-green.svg';

export interface IconCardProps {
  text: string;
}

const IconCard: FC<IconCardProps> = ({ text }) => {
  return (
    <Card
      elevation={0}
      sx={{
        backgroundColor: colors.darkGray2,
        padding: '11px 12px 11px 35px',
        color: colors.white,
        display: 'flex',
        borderRadius: '10px',
        fontFamily: 'Lato',
        fontWeight: '300',
        fontSize: '18.5px',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <div>{text}</div>
      <img src={greenGrid} alt="" />
    </Card>
  );
};

export default IconCard;
