import { createTheme } from '@mui/material/styles';
import { colors } from 'src/theme/colors';

const buttonTheme = createTheme({
  palette: {
    primary: {
      main: colors.green3,
      contrastText: colors.white,
    },
    secondary: {
      light: colors.white,
      main: colors.black,
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    button: {
      textTransform: 'none',
      fontFamily: 'Lato',
      fontSize: '16px',
      fontWeight: '300',
    },
  },
  button: {
    '&:focus-visible': {
      outlineStyle: 'double',
    },
  },
});

export default buttonTheme;
