import React, { FC } from 'react';
import { BillSponsors } from '../../types/BillSponsors.interface';
import { isFifthElementAndMoreThanFiveSponsors } from './util/IsFifthElementAndMoreThanFiveSponsors';
import styles from './TopFiveSponsorsList.module.scss'

interface TopFiveSponsorsListProps {
  sponsorsObject?: BillSponsors;
  scrollToSponsors?: () => void;
}

export const TopFiveSponsorsList: FC<TopFiveSponsorsListProps> = ({ sponsorsObject,scrollToSponsors}) => {
  if (!sponsorsObject || sponsorsObject.primary.length === 0) return <span>None</span>;
  return (
    <>
      {sponsorsObject.primary.map((sponsor: any, index: number) => (
        <React.Fragment key={index}>
          {index < 5 && (
            <span className={scrollToSponsors ? styles.sponsorNameBillDetails : ""} onClick={scrollToSponsors ?? undefined} key={index}>
              {sponsor.name} ({sponsor.party[0]})
              {isFifthElementAndMoreThanFiveSponsors(index, sponsorsObject.primary.length) && '...'}
            </span>
          )}
        </React.Fragment>
      ))}
    </>
  );
};
