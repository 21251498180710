import axios from 'axios';
import {toast} from 'react-toastify';
import aelTrackerClientUri from '../aelTrackerClient';
import {UserRole} from 'src/types/UserRole.enum';

export const axiosConfig = {
  headers: {
    'content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  },
  credentials: 'same-origin',
};
axios.defaults.withCredentials = true;

export const Logout = async (setAuthenticated, setUserData) => {
  try {
    const res = await axios.post(`${aelTrackerClientUri}/user/logout`, axiosConfig);
    if (res.status === 200) {
      setAuthenticated(false);
      setUserData({
        id: undefined,
        createdDate: undefined,
        email: '',
        firstName: '',
        lastName: '',
        passwordResetTokenExpirationDate: undefined,
        active: false,
        role: '',
        fullName: '',
        enabled: false,
        credentialsNonExpired: false,
        accountNonExpired: false,
        authorities: [],
        parent: undefined,
      });
    }
  } catch (error) {
    throw error;
  }
};

export const GetUser = async (setAuthenticated, setUserData) => {
  try {
    const res = await axios.get(`${aelTrackerClientUri}/user`, axiosConfig);
    if (res.data) {
      const userData = res.data;
      setAuthenticated(true);
      setUserData({
        id: userData.id,
        createdData: userData.createdDate,
        email: userData.email,
        firstName: userData.firstName,
        lastName: userData.lastName,
        organization: userData.organization,
        organizationType: userData.organizationType,
        active: userData.active,
        role: userData.role,
        fullName: userData.fullName,
        enabled: userData.enabled,
        credentialsNonExpired: userData.credentialsNonExpired,
        accountNonExpired: userData.accountNonExpired,
        authorities: userData.authorities,
        parent: userData.parent,
        trackerFrequency: userData.trackerFrequency,
        trackerActions: userData.trackerActions,
        trackerVotes: userData.trackerVotes,
        trackerVersions: userData.trackerVersions,
        trackerStatus: userData.trackerStatus,
      });
    } else {
      setAuthenticated(false);
    }
  } catch (error) {
    return error;
  }
};

export const Login = async (body, setAuthenticated, setUserData, handleClose) => {
  const res = await axios.post(`${aelTrackerClientUri}/user/login`, body, axiosConfig);
  if (res.status === 200) {
    setAuthenticated(true);
    GetUser(setAuthenticated, setUserData);
    handleClose();
  }
  return res;
};

export const CreateAccountPublic = async (body) => {
  try {
    await axios.post(`${aelTrackerClientUri}/user/create`, body, axiosConfig);
    toast.success(
      'myTracker account creation successful. Check your email to activate your account. Once activated, you may add bills to your account.',
      {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
      },
    );
  } catch (error) {
    toast.error('Unable to create account. Please try again.', {
      position: toast.POSITION.TOP_CENTER,
      autoClose: false,
    });
    throw error;
  }
};

export const CreateAccountAdmin = async (body) => {
  try {
    await axios.post(`${aelTrackerClientUri}/user/create-admin`, body, axiosConfig);
    toast.success(
      `${body.role === UserRole.PUBLIC ? 'myTracker' : 'Admin'
      } account creation successful. Check your email to activate your account. Once activated, you may add bills to your account.`,
      {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
      },
    );
  } catch (error) {
    toast.error('Unable to create account. Please try again.', {
      position: toast.POSITION.TOP_CENTER,
      autoClose: false,
    });
    if (error.response.status === 403) {
      window.location.replace('/');
    }
  }
};

export const UpdateAccountPublic = async (body) => {
  try {
    // eslint-disable-next-line
    return await axios.post(`${aelTrackerClientUri}/user/update`, body, axiosConfig);
  } catch (error) {
    toast.error('Update failed. Please try again.', { position: toast.POSITION.TOP_CENTER });
    if (error.response.status === 403) {
      window.location.replace('/');
    } else {
      throw error
    }
  }
};

export const ActivateAccountPublic = async (body) => {
  try {
    // eslint-disable-next-line
    await axios.post(`${aelTrackerClientUri}/user/activate`, body, axiosConfig);
    return true;
  } catch (error) {
    throw error;
  }
};

export const GetUserByActivationToken = async (activationId, setActivatedUser) => {
  try {
    const res = await axios.get(`${aelTrackerClientUri}/user/activate/${activationId}`, axiosConfig);
    if (res.data.active === false) {
      setActivatedUser({ ...res.data, accountActivationToken: '', active: true });
    }
  } catch (error) {
    throw error;
  }
};

export const UnsubscribeFromAllEmails = async (uuid) => {
  try {
    const res = await axios.get(`${aelTrackerClientUri}/user/unsubscribe/${uuid}`, axiosConfig);
    return res.status === 200;
  } catch (error) {
    throw error;
  }
};

export const GetUserByByPasswordResetToken = async (token, userForReset, setUserForReset) => {
  try {
    const res = await axios.get(`${aelTrackerClientUri}/user/password/reset/${token}`, axiosConfig);
    setUserForReset({ ...userForReset, email: res.data.email });
    return res;
  } catch (error) {
    toast.error('This password reset link has been used or expired. Please request to reset your password again.', {
      position: toast.POSITION.TOP_CENTER,
    });
  }
};

export const SetPasswordResetToken = async (email) => {
  try {
    await axios.post(`${aelTrackerClientUri}/user/password/request?email=${encodeURIComponent(email)}`, axiosConfig);
  } catch (error) {
    throw error;
  }
};

export const ResetPassword = async (body) => {
  try {
    // eslint-disable-next-line
    const res = await axios.post(`${aelTrackerClientUri}/user/password/reset`, body, axiosConfig);
    return res;
  } catch (error) {
    toast.error('Update failed. Please try again.', {
      position: toast.POSITION.TOP_CENTER,
    });
  }
};

export const SubmitContactForm = (body) => {
  return axios.post(`${aelTrackerClientUri}/user/contactForm`, body, axiosConfig);
};

export const DeleteAccount = async (id) => {
  try {
    // eslint-disable-next-line
    return await axios.delete(`${aelTrackerClientUri}/user/delete/${id}`, axiosConfig);
  } catch (error) {
    toast.error('Update failed. Please try again.', {
      position: toast.POSITION.TOP_CENTER,
    });
    throw error
  }
};
