enum Breakpoint {
  XS = 'XS',
  SM = 'SM',
  BASE = 'BASE',
  MD = 'MD',
  LG = 'LG',
  XL = 'XL',
}

export default Breakpoint;
